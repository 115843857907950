import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Button } from '../index'

const TagFooter = () => {

    const navigate = useNavigate()
    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    const tags = [
        { id: 1, label: "Nokia", link: "/" },
        { id: 2, label: "Samsung", link: "/" },
        { id: 3, label: "Blackberry", link: "/" },
        { id: 4, label: "Asus Laptops", link: "/" },
        { id: 5, label: "Nokia", link: "/" },
        { id: 6, label: "Samsung", link: "/" },
        { id: 7, label: "Blackberry", link: "/" },
        { id: 8, label: "Asus Laptops", link: "/" },
        { id: 9, label: "Nokia", link: "/" },
        { id: 10, label: "Samsung", link: "/" },
        { id: 11, label: "Blackberry", link: "/" },
        { id: 12, label: "Asus Laptops", link: "/" },
    ]

    return (
        <>
            <Outlet />
            <div className={`${sectionPad} py-10 bg-white border-b`}>
                <h6 className='pb-6 font-semibold text-lg'>Trending Tags</h6>

                <div className='flex flex-wrap gap-2'>
                    {tags.map((tag) => (
                        <Button
                            key={tag.id}
                            className={"px-4 py-2 text-xs md:text-sm w-fit rounded-md border bg-[#FAFAFB] text-black hover:bg-primary hover:text-white"}
                            label={tag.label}
                            onClick={() => navigate(`${tag.link}`)}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default TagFooter