import React, { Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "fit-content",
                width: "60px",
                transform: "translate(100%, -50%)",
                right: 0,
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-secondary hover:bg-[#AFAFAF] duration-300 block'>
                <BsChevronRight className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className}`}
            style={{
                ...style,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "fit-content",
                width: "60px",
                transform: "translate(-100%, -50%)",
                left: 0,
            }}
            onClick={onClick}
        >
            <div className='group aspect-square rounded-full w-full relative bg-secondary hover:bg-[#AFAFAF] duration-300 block'>
                <BsChevronLeft className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>
    );
}

const ItemsCarousel = ({
    contents = [],
    className,
}) => {

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            <style className='hidden'>
                {`
                .slick-arrow::before {
                    content: none;
                }
                .slick-dots {
                    position: static;
                }
                `}
            </style>

            <Slider {...settings} className={`${className} relative`}>
                {contents.map((content, index) => (
                    <Fragment key={index}>
                        {content}
                    </Fragment>
                ))}


            </Slider>
        </>
    );
};

export default ItemsCarousel;
