import React, { useState } from 'react'

import { Button } from '../../../../components/index'
import { getStarsFromRating } from '../../../../utils/getStarsFromRating'


import { IoIosStar } from "react-icons/io";
import { FaRegThumbsUp } from "react-icons/fa";
import { CiCircleAlert } from "react-icons/ci";


const ReviewComments = ({
    username,
    userImg,
    rating,
    comment,
    helpfulNum,
    reviewDate,
    images
}) => {

    const [moreImages, setMoreImages] = useState(images.length > 4 ? true : false)

    const displayImg = images.slice(0, 4)
    const extraImg = images.length - 4

    return (
        <div className='py-6 '>
            <div className='px-6'>
                <div className='gap-3 flex items-center justify-start'>

                    <div className='w-12 aspect-square rounded-full shadow-inner bg-gray-200'>
                        <img src={userImg} className='rounded-full w-full h-full object-contain object-center' alt="" />
                    </div>
                    <div>
                        <div className='text-base'>{username}</div>
                        <div className='text-xs text-[#474D59]'>Rewiewed on {reviewDate}</div>
                    </div>
                </div>

                <div className='mt-3 flex justify-start items-center gap-2 flex-wrap'>
                    <div className='flex items-center justify-start flex-wrap gap-x-0.5 text-xl'>
                        {getStarsFromRating(rating, <IoIosStar className='text-[#D4CDC5]' />)}
                    </div>
                    <div className='text-base font-medium whitespace-nowrap'>Definitely worth the price</div>
                </div>

                {/* comments */}
                <div className='text-sm text-[#474D59] mt-3 text-left'>
                    {comment}
                </div>

                <div className='mt-4 flex items-center justify-between flex-wrap gap-3'>

                    <div className='flex items-center justify-center gap-3'>

                        <Button
                            className={"flex items-center justify-center gap-2 text-sm text-black border border-primary bg-white rounded-md px-3 py-2"}
                        >
                            <FaRegThumbsUp />
                            <div>Helpful</div>
                        </Button>

                        <div className='text-xs text-[#70747D]'>{helpfulNum} People find this helpful</div>
                    </div>

                    <Button
                        className={"flex items-center justify-center gap-2 text-sm text-red-600 border border-primary bg-white rounded-md px-3 py-2"}
                    >
                        <CiCircleAlert className='text-xl' />
                        <div>Report</div>
                    </Button>
                </div>

                <div className='mt-3 grid grid-cols-[repeat(auto-fill,minmax(130px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-5'>
                    {displayImg?.map((image, index) => (
                        <div
                            key={index}
                            className={`relative border block mx-auto w-full h-full`}
                        >
                            <img src={image} className='w-full h-full object-cover' alt="" />

                            {moreImages && index === 3 && (
                                <>
                                    <div className='z-[5] w-full h-full absolute top-0 left-0 bg-black opacity-50'></div>
                                    <div className='z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl text-white opacity-100'>+{extraImg}</div>
                                </>
                            )}
                        </div>
                    ))}

                </div >
            </div>
        </div>
    )
}

export default ReviewComments