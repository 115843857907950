import React from 'react'

const ConditionForReturns = () => {
    return (
        <div className='w-full lg:w-[32.33%] h-fit flex flex-col items-center justify-center gap-6 lg:gap-x-8'>

            <div className='w-full h-fit border bg-white border-primary rounded-md divide-y'>
                <div className='px-6 py-4 text-base text-black font-medium'>Condition for Returns</div>

                <ol className='py-6 pl-10 pr-6 md:px-12 text-justify md:text-left text-[#474D59] text-sm list-decimal space-y-4'>
                    <li>The product must be unused, unworn, unwashed and without any flaws. Fashion products can be tried on to see if they fit and will still be considered unworn. If a product is returned to us in an inadequate condition, we reserve the right to send it back to you.</li>
                    <li>The product must include the original tags, user manual, warranty cards, freebies and accessories.</li>
                    <li>The product must be returned in the original and undamaged manufacturer packaging / box. If the product was delivered in a second layer of Daraz packaging, it must be returned in the same condition with return shipping label attached. Do not put tape or stickers on the manufacturers box.</li>
                </ol>
            </div>

            <div className='w-full h-fit border bg-white border-primary rounded-md divide-y'>
                <div className='px-6 py-4 text-base text-black font-medium'>Popular Links</div>

                <div className='p-6 text-sm text-black font-medium list-decimal space-y-4'>
                    <div><a href='/' className='hover:text-secondary hover:underline' target="_blank" rel="noreferrer">Frequently Asked Questions</a></div>
                    <div><a href='/' className='hover:text-secondary hover:underline' target="_blank" rel="noreferrer">Warranty Policy</a></div>
                </div>
            </div>
        </div>)
}

export default ConditionForReturns