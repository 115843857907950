import React, { useState } from 'react'
import { Button } from '../index'
import { BsArrowRight } from "react-icons/bs";


const BlogCard = ({
    className,
    title,
    image,
    date,
    desc,
}) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`${className} hover:cursor-pointer bg-white p-4 rounded-md group flex flex-col items-center justify-start gap-y-2 xl:gap-y-5`}>

            <div className='w-full aspect-video rounded-md overflow-hidden'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img className='group-hover:scale-110 duration-300 ease-in-out object-cover object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>
            <div className='flex flex-col items-start flex-grow justify-between gap-2'>
                <div className='text-[#70747D] text-sm'>{date}</div>
                <div className='text-black group-hover:text-secondary line-clamp-2 text-base lg:text-sm xl:text-base font-medium'>{title}</div>
                <div className='text-[#70747D] line-clamp-2 text-sm lg:text-xs xl:text-sm'>{desc}</div>

                <Button
                    className={'mt-2 rounded-md bg-white text-secondary border border-secondary flex justify-center items-center gap-2 py-3 px-5 lg:px-4 lg:py-2 xl:py-3 xl:px-5'}
                >
                    <p className='whitespace-nowrap text-sm xl:text-base'>Read More</p>
                    <BsArrowRight className='text-sm lg:text-base xl:text-xl' />
                </Button>
            </div>

        </div>
    )
}

export default BlogCard