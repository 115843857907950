import React from 'react'

const ReturnAProduct = () => {

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    const returnSteps = [
        { id: 1, title: "Sign in", desc: "Sign in to your Account, select Order History" },
        { id: 2, title: "Select Order", desc: "Select the order you wish to return and click on the Order Details button" },
        { id: 3, title: "Return / Refund", desc: "Click on Request a Return/Refund button " },
        { id: 4, title: "Fill in the Return form", desc: "The available return options (pickup/drop-off) will be displayed in this form" },
        { id: 5, title: "Pack your Parcel", desc: "Pack your parcel securely and secure the Return Label on top of the return package." },
        { id: 6, title: "Package Drop-off", desc: "Head to your nearest drop-off point or wait for collection by our pick-up service." },
    ]


    return (
        <div className={`${sectionPad}`}>

            <h6 className='my-4 text-center lg:text-left font-semibold text-lg text-black'>Please follow the following steps mentioned below if you want to return a product</h6>

            <div className='mb-10 mt-16 grid w-full gap-y-12 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>
                {returnSteps.map((step) => (
                    <div
                        key={step.id}
                        className='relative px-8 py-16 border border-primary rounded-md bg-white'
                    >

                        <div className='absolute shadow-[0_0_10px_0_rgba(0,0,0,0.08)] left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 rounded-full w-16 aspect-square p-2 bg-white'>
                            <h5 className='rounded-full w-full h-full text-xl font-semibold text-secondary border-2 p-3 border-secondary flex items-center justify-center'>
                                {step.id}
                            </h5>
                        </div>

                        <div className='flex flex-col items-center justify-center gap-y-4'>
                            <h6 className='font-semibold text-center text-lg'>{step.title}</h6>
                            <div className='text-[#474D59] text-center text-sm'>{step.desc}</div>
                        </div>

                    </div>
                ))}
            </div>


            {/* Condition for Returns */}
            <div className='mb-10 flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                <div className='w-full lg:w-[67.66%] border bg-white border-primary rounded-md divide-y'>
                    <div className='px-6 py-4 text-base text-black font-medium'>Condition for Returns</div>

                    <ol className='py-6 pl-10 pr-6 md:px-12 text-justify md:text-left text-[#474D59] text-sm list-decimal space-y-4'>
                        <li>The product must be unused, unworn, unwashed and without any flaws. Fashion products can be tried on to see if they fit and will still be considered unworn. If a product is returned to us in an inadequate condition, we reserve the right to send it back to you.</li>
                        <li>The product must include the original tags, user manual, warranty cards, freebies and accessories.</li>
                        <li>The product must be returned in the original and undamaged manufacturer packaging / box. If the product was delivered in a second layer of Daraz packaging, it must be returned in the same condition with return shipping label attached. Do not put tape or stickers on the manufacturers box.</li>
                    </ol>
                </div>

                <div className='w-full lg:w-[32.33%] h-fit border bg-white border-primary rounded-md divide-y'>
                    <div className='px-6 py-4 text-base text-black font-medium'>Popular Links</div>

                    <div className='p-6 text-sm text-black font-medium list-decimal space-y-4'>
                        <div><a href='/' className='hover:text-secondary hover:underline' target="_blank" rel="noreferrer">Frequently Asked Questions</a></div>
                        <div><a href='/' className='hover:text-secondary hover:underline' target="_blank" rel="noreferrer">Warranty Policy</a></div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ReturnAProduct