import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isSidebarClose } from '../../redux/index'
import { Overlay, Button } from '../index'
import { scrollToTop } from '../../utils/scrollToTop'

import { IoMdClose } from "react-icons/io";
import { Logo } from '../../assets/index'



const Sidebar = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const toggleSidebar = useSelector(state => state.sidebarReducer.isSidebarOpen)

    const othersList = [
        { id: 1, link: "/become-a-seller", label: "Become a Seller", },
        { id: 2, link: "/", label: "Customer Service", },
        { id: 3, link: "/", label: "Download App", }
    ]

    const categoriesList = [
        { id: 1, onClick: (e) => console.log('works', e.target), label: "Art & Collection" },
        { id: 2, onClick: (e) => console.log('works', e.target), label: "Automotive" },
        { id: 3, onClick: (e) => console.log('works', e.target), label: "B2B Supplier" },
        { id: 4, onClick: (e) => console.log('works', e.target), label: "Business Services" },
        { id: 5, onClick: (e) => console.log('works', e.target), label: "Buy & Sell" },
        { id: 6, onClick: (e) => console.log('works', e.target), label: "Cars" },
        { id: 7, onClick: (e) => console.log('works', e.target), label: "Community" },
        { id: 8, onClick: (e) => console.log('works', e.target), label: "Toys And Games" }
    ]

    const navMenuList = [
        { id: 1, link: "/", label: "Home" },
        { id: 2, link: "/flashsale", label: "Flash Sale" },
        { id: 3, link: "/", label: "Hot Offers" },
        { id: 4, link: "/help-&-support", label: "Help & Support" }
    ]


    const buttonCss = 'px-4 py-3 text-base rounded-lg text-left text-black whitespace-nowrap w-full bg-white hover:bg-[#F5F6F6]'

    return (
        <>
            <aside
                className={`${toggleSidebar ? "translate-x-0" : "-translate-x-full"} 
                bg-white fixed left-0 top-0 pb-10 divide-y h-screen w-full shadow-2xl transform z-50 ease-in-out duration-500
                md:w-2/3 lg:w-[40%] xl:w-1/4`}
            >

                <div className='h-[12vh] border-b border-primary flex items-center justify-between px-4 py-1'>

                    <div className="w-40 flex justify-center items-center gap-3">
                        <img src={Logo} className='w-full h-full' alt="logo" />
                    </div>

                    <Button
                        onClick={() => dispatch(isSidebarClose())}
                        className={"py-4 px-8 lg:pl-8 lg:pr-2"}
                    >
                        <IoMdClose className='text-xl' />
                    </Button>
                </div>


                <div className='h-[88vh] overflow-x-hidden overflow-y-auto'>

                    <div className='block md:hidden px-4 py-6 border-b border-primary'>

                        <h6 className='font-semibold text-lg mb-2'>Menu</h6>

                        <div className='flex flex-col items-start justify-center my-2'>
                            {navMenuList.map((list) => (

                                <Button
                                    key={list.id}
                                    onClick={() => {
                                        navigate(`${list.link}`);
                                        scrollToTop();
                                        dispatch(isSidebarClose());
                                    }}
                                    className={`${buttonCss} text-sm`}
                                    label={list.label}
                                >
                                </Button>
                            ))}
                        </div>
                    </div>

                    <div className='px-4 py-6 border-b border-primary'>

                        <h6 className='font-semibold text-lg mb-2'>My Account</h6>

                        <Button
                            onClick={() => {
                                navigate(`/auth/login`)
                                dispatch(isSidebarClose())
                                scrollToTop()
                            }}

                            className={`${buttonCss} text-sm`}
                            label={"Login/Register"}
                        >
                        </Button>
                    </div>


                    <div className='px-4 py-6 border-b border-primary'>

                        <h6 className='font-semibold text-lg mb-2'>Categories</h6>

                        <div className='flex flex-col items-start justify-center my-2'>
                            {categoriesList.map((list) => (

                                <Button
                                    key={list.id}
                                    onClick={list.onClick}
                                    className={`${buttonCss} text-sm`}
                                    label={list.label}
                                >
                                </Button>
                            ))}
                        </div>
                    </div>


                    <div className='px-4 py-6 border-b border-primary'>

                        <h6 className='font-semibold text-lg mb-2'>Others</h6>

                        <div className='flex flex-col items-start justify-center my-2'>
                            {othersList.map((list) => (

                                <Button
                                    key={list.id}
                                    onClick={() => {
                                        navigate(`${list.link}`);
                                        scrollToTop();
                                        dispatch(isSidebarClose());
                                    }}
                                    className={`${buttonCss} text-sm`}
                                    label={list.label}
                                >
                                </Button>
                            ))}
                        </div>
                    </div>

                </div>

            </aside>

            {toggleSidebar && (
                <Overlay onClick={() => dispatch(isSidebarClose())} />
            )}
        </>
    )
}

export default Sidebar