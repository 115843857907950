import React, { useState } from 'react';
import { Button } from '../index'

const OfferCard = ({ title, background, salesText, image, err, className }) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (

        <div
            style={{ backgroundColor: background }}
            className={`flex pl-3 pt-3 md:pl-5 md:pt-6 pb-2 rounded-lg ${className}`}
        >

            <div className='flex md:block flex-col justify-between items-start w-1/2 mb-'>
                <div>
                    <h1 className='text-left line-clamp-2 md:line-clamp-none md:whitespace-nowrap text-xs text-black'>{title}</h1>

                    <div className='text-sm md:text-xl whitespace-nowrap font-semibold w-fit text-left' >{salesText}</div>
                </div>

                <Button
                    className={'px-2 py-1 whitespace-nowrap md:py-2 md:px-3 mt-2 md:mt-5 text-xs md:text-sm font-medium bg-white rounded md:rounded-full'}
                    label={"Explore Now"}
                />

            </div>

            <div className='w-1/2'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='px-2 object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img className='px-2 object-contain object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>

        </div>
    )
}

export default OfferCard