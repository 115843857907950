import React, { useState } from 'react'

import {
    Button,
    RadioButton,
    Checkbox,
    Input,
    Overlay
} from '../../../../components/index'
import FilterAccordion from './FilterAccordion'
import { getStarsFromRating } from '../../../../utils/getStarsFromRating'

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { dellBoomiAd } from '../../../../assets/index'
import { IoIosStarOutline, IoIosStar, IoMdClose } from "react-icons/io";

const assignUniqueId = (arr, str) => {
    const formattedArr = arr.map((item) => {
        const newId = String(item.id).concat(str)
        return { ...item, id: newId }
    })
    return formattedArr
}

const FilterSidebar = ({
    toggleFilterSidebar,
    setToggleFilterSidebar

}) => {


    const radioOptions = [
        { id: 1, value: 'Any', label: 'Any' },
        { id: 2, value: 'Refurbished', label: 'Refurbished' },
        { id: 3, value: 'Brand New', label: 'Brand New' },
        { id: 4, value: 'Used', label: 'Used' },
    ];

    const brandCheckbox = [
        { id: 1, checked: false, label: 'Samsung' },
        { id: 2, checked: false, label: 'Apple' },
        { id: 3, checked: false, label: 'Huawei' },
        { id: 4, checked: false, label: 'Poco' },
        { id: 5, checked: false, label: 'Lenovo' },
        { id: 6, checked: false, label: 'One Plus' },
        { id: 7, checked: false, label: 'two Plus' },
        { id: 8, checked: false, label: 'chai Plus' },
    ];

    const featuresCheckbox = [
        { id: 1, checked: false, label: 'Metallic' },
        { id: 2, checked: false, label: 'Plastic Cover' },
        { id: 3, checked: false, label: '8 GB RAM' },
        { id: 4, checked: false, label: 'Super Power' },
        { id: 5, checked: false, label: 'Large Memory' },
        { id: 6, checked: false, label: 'RTX 4090' },
        { id: 7, checked: false, label: 'Uranium' },
    ];

    const ratingCheckbox = [
        { id: 1, checked: false, label: "1 Star" },
        { id: 2, checked: false, label: "2 Star" },
        { id: 3, checked: false, label: "3 Star" },
        { id: 4, checked: false, label: "4 Star" },
        { id: 5, checked: false, label: "5 Star" },
    ];


    const tag = {
        brand: "brand",
        features: "features",
        rating: "rating"
    }

    const [isSeeAllActive, setIsSeeAllActive] = useState({})    //For See All button    
    const [radioSelectedOption, setRadioSelectedOption] = useState(radioOptions[0].value);
    const [brandCheckboxOption, setBrandCheckboxOption] = useState(assignUniqueId(brandCheckbox, tag.brand));
    const [featuresCheckboxOption, setFeaturesCheckboxOption] = useState(assignUniqueId(featuresCheckbox, tag.features));
    const [ratingCheckboxOption, setRatingCheckboxOption] = useState(assignUniqueId(ratingCheckbox.reverse(), tag.rating));
    const [allAcitveFilters, setAllAcitveFilters] = useState([...brandCheckboxOption, ...featuresCheckboxOption, ...ratingCheckboxOption])
    console.log(ratingCheckboxOption)
    const displayFilters = allAcitveFilters?.filter((item) => item.checked)

    const brandSlice = 6
    const featuresSlice = 5
    const [brandSliceVal, setBrandSliceVal] = useState(brandSlice)
    const [FeaturesSliceVal, setFeaturesSliceVal] = useState(featuresSlice)


    // for "See All" button
    const toogleSeeAll = (CheckFor) => {
        setIsSeeAllActive({
            ...isSeeAllActive,
            [CheckFor]: !isSeeAllActive[CheckFor]
        });
    };


    const handleCheckboxChange = (id, checked, optionList, setOptionList) => {
        const updatedData = optionList.map((option) =>
            option.id === id ? { ...option, checked } : option
        );

        const filtersUpdatedData = allAcitveFilters.map((option) =>
            option.id === id ? { ...option, checked } : option
        );

        setAllAcitveFilters(filtersUpdatedData);
        setOptionList(updatedData);
    };


    const handleDeleteFilter = (id) => {
        if (id.includes(tag.brand)) { handleCheckboxChange(id, false, brandCheckboxOption, setBrandCheckboxOption) }
        else if (id.includes(tag.features)) { handleCheckboxChange(id, false, featuresCheckboxOption, setFeaturesCheckboxOption) }
        else if (id.includes(tag.rating)) { handleCheckboxChange(id, false, ratingCheckboxOption, setRatingCheckboxOption) }
    }

    const handleResetOptions = (optionList, setOptionList) => {
        const updatedData = optionList.map((option) => ({ ...option, checked: false }));
        setOptionList(updatedData);
    };

    const handleDeleteAllFilters = () => {
        handleResetOptions(allAcitveFilters, setAllAcitveFilters);
        handleResetOptions(brandCheckboxOption, setBrandCheckboxOption);
        handleResetOptions(featuresCheckboxOption, setFeaturesCheckboxOption);
        handleResetOptions(ratingCheckboxOption, setRatingCheckboxOption);
    };

    // const getStarsFromRating = (rating) => {
    //     const ratingNum = Math.floor(parseInt(rating, 10));

    //     return Array.from({ length: 5 }, (_, index) => {

    //         return index < ratingNum ? (
    //             <IoIosStar key={index} className='text-[#F2C94C]' />
    //         ) : (
    //             <IoIosStarOutline key={index} className='text-[#D4CDC5]' />
    //         );
    //     });
    // };


    const smPad = "pl-5 lg:pl-0"

    return (
        <>
            <aside
                className={`${toggleFilterSidebar ? "translate-x-0" : "-translate-x-full"}
                w-[80%] md:w-[60%] lg:w-1/3 xl:w-1/4 
                fixed lg:static left-0 top-0 h-screen lg:h-fit mb-0 lg:mb-10
                shadow-[2px_3px_5px_rgba(0,0,0,0.3)] lg:shadow-none
                bg-white transform z-30 ease-in-out duration-500`}
            >


                <div className={`${smPad} border border-primary rounded-md pb-10 lg:pb-0`}>


                    <div className='h-[8vh] lg:hidden border-b border-primary flex items-center justify-between px-4 py-1'>
                        <Button
                            onClick={() => setToggleFilterSidebar(false)}
                            className={"ml-auto py-4 px-8"}
                        >
                            <IoMdClose className='text-2xl' />
                        </Button>
                    </div>


                    {/* FILTER */}
                    <div className='h-[90vh] lg:h-fit overflow-y-auto divide-y'>

                        {/* Applied Filters */}
                        <div className='w-full px-2 py-3'>

                            <div className={`flex justify-between items-center`}>
                                <div className="px-2 py-2 text-sm font-medium">Applied Filters</div>

                                <Button
                                    onClick={handleDeleteAllFilters}
                                    className={`${displayFilters.length === 0 ? "hidden" : "block"} py-2 px-2 gap-2 w-fit text-[#C71720] flex justify-start items-center`}
                                >
                                    <div className='text-sm'>Clear all</div>
                                    <IoMdClose />
                                </Button>

                            </div>

                            {displayFilters.length !== 0 &&
                                <div className='py-2 px-2 flex items-start justify-start flex-wrap gap-2'>
                                    {displayFilters?.map((choice) => (
                                        <div
                                            key={`${choice.id}`}
                                            className="pl-2 w-fit rounded-md bg-primary text-white flex justify-start items-center"
                                        >
                                            <div className='text-sm'>{choice.label}</div>


                                            <Button
                                                onClick={() => handleDeleteFilter(choice.id)}
                                                className={"py-2 px-1"}
                                            >
                                                <IoMdClose />
                                            </Button>

                                        </div>

                                    ))}
                                </div>
                            }
                        </div>



                        {/* Brand */}
                        <FilterAccordion
                            title={"Brand"}
                        >

                            <div className=' space-y-3'>
                                {brandCheckboxOption.slice(0, brandSliceVal).map((choice) => (
                                    <label
                                        htmlFor={`${choice.id}brand`}
                                        key={choice.id}
                                        className="flex justify-start items-center gap-3"
                                    >
                                        <Checkbox
                                            id={`${choice.id}brand`}
                                            type={'checkbox'}
                                            value={choice.label}
                                            name={choice.label}
                                            checked={choice.checked}
                                            onChange={() => handleCheckboxChange(choice.id, !choice.checked, brandCheckboxOption, setBrandCheckboxOption)}
                                        />
                                        <div className='text-sm'>{choice.label}</div>
                                    </label>

                                ))}

                                <Button
                                    onClick={() => {
                                        const updatedCheckboxOption = !isSeeAllActive["Brand"] ? brandCheckboxOption.length : brandSlice;
                                        toogleSeeAll("Brand");
                                        setBrandSliceVal(updatedCheckboxOption)
                                    }}
                                    className="text-secondary text-sm mt-3 flex items-center justify-center gap-x-1"
                                    label={isSeeAllActive["Brand"] ? "See less" : "See all"}
                                >
                                    {isSeeAllActive["Brand"] ? (
                                        <IoIosArrowUp className='w-8 h-8 px-2' />
                                    ) : (
                                        <IoIosArrowDown className='w-8 h-8 px-2' />
                                    )}
                                </Button>

                            </div>

                        </FilterAccordion>


                        {/* Features */}
                        <FilterAccordion
                            title={"Features"}
                        >

                            <div className=' space-y-3'>
                                {featuresCheckboxOption?.slice(0, FeaturesSliceVal).map((choice) => (
                                    <label
                                        key={`${choice.id}`}
                                        htmlFor={`${choice.id}feature`}
                                        className="flex justify-start items-center gap-3"
                                    >

                                        <Checkbox
                                            id={`${choice.id}feature`}
                                            type={'checkbox'}
                                            value={choice.label}
                                            name={choice.label}
                                            checked={choice.checked}
                                            onChange={() => handleCheckboxChange(choice.id, !choice.checked, featuresCheckboxOption, setFeaturesCheckboxOption)}
                                        />
                                        <div className='text-sm'>{choice.label}</div>
                                    </label>

                                ))}

                                <Button
                                    onClick={() => {
                                        const updatedCheckboxOption = !isSeeAllActive["Features"] ? featuresCheckboxOption.length : featuresSlice;
                                        toogleSeeAll("Features");
                                        setFeaturesSliceVal(updatedCheckboxOption);
                                    }}
                                    className="text-secondary text-sm mt-3 flex items-center justify-center gap-x-1"
                                    label={isSeeAllActive["Features"] ? "See less" : "See all"}
                                >
                                    {isSeeAllActive["Features"] ? (
                                        <IoIosArrowUp className='w-8 h-8 px-2' />
                                    ) : (
                                        <IoIosArrowDown className='w-8 h-8 px-2' />
                                    )}
                                </Button>

                            </div>

                        </FilterAccordion>


                        {/* Condition */}
                        <FilterAccordion
                            title={"Condition"}
                        >
                            <div className=' space-y-3'>
                                {radioOptions.map((option) => (

                                    <RadioButton
                                        key={option.id}
                                        id={option.id}
                                        name="options"
                                        value={option.value}
                                        label={option.label}
                                        checked={radioSelectedOption === option.value}
                                        onChange={(e) => setRadioSelectedOption(e.target.value)}
                                    />
                                ))}
                            </div>

                        </FilterAccordion>


                        {/* Rating */}
                        <FilterAccordion
                            title={"Rating"}
                        >

                            <div className=' space-y-3'>
                                {ratingCheckboxOption?.map((choice) => (
                                    <label
                                        key={`${choice.id}`}
                                        htmlFor={`${choice.id}rating`}
                                        className="flex justify-start items-center gap-3"
                                    >

                                        <Checkbox
                                            id={`${choice.id}rating`}
                                            type={'checkbox'}
                                            value={choice.label}
                                            name={choice.label}
                                            checked={choice.checked}
                                            onChange={() => handleCheckboxChange(choice.id, !choice.checked, ratingCheckboxOption, setRatingCheckboxOption)}
                                        />
                                        <div className='flex items-center justify-center gap-x-0.5 text-xl'>{getStarsFromRating(choice.label)}
                                            <span className='text-sm ml-1'>& up</span>
                                        </div>
                                    </label>

                                ))}

                            </div>

                        </FilterAccordion>


                        <div className='h-10 block lg:hidden'></div>


                    </div>
                </div>
            </aside >

            {toggleFilterSidebar && (
                <Overlay
                    className={"lg:hidden"}
                    zVal={"z-[25]"}
                    onClick={() => setToggleFilterSidebar(false)}
                />
            )
            }
        </>)
}

export default FilterSidebar