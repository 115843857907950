import React, { useState } from 'react'
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { Button } from '../../components/index'


const ReturnAndRefund = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const activeEndPoint = location.pathname.split("/").pop()

    const buttonLabels = [
        { id: 1, endPoint: "how-to-return-a-product", label: "How to Return a Product", link: "/return-and-refund/how-to-return-a-product" },
        { id: 2, endPoint: "return-policy", label: "Return Policy", link: "/return-and-refund/return-policy" },
        { id: 3, endPoint: "refund-policy", label: "Refund Policy", link: "/return-and-refund/refund-policy" },
    ]

    const activeId = buttonLabels.find((val) => val.endPoint === activeEndPoint)?.id;

    const [activeButton, setActiveButton] = useState(activeId)
    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <>
            <div className={`${sectionPad} border-t md:border-none border-primary`}>

                <div className='w-full overflow-y-auto my-4 mx-0 mr-auto px-0'>
                    <div className='border-b border-primary flex'>
                        {buttonLabels?.map((items) => (
                            <Button
                                key={items.id}
                                onClick={() => {
                                    setActiveButton(items.id)
                                    navigate(`${items.link}`)
                                }}
                                className={`${activeButton === items.id ? "border-secondary text-black" : "border-[#FAFAFA] text-[#70747D]"} 
                                whitespace-nowrap border-b-2 lg:border-b-[3px] py-2 px-4 text-base`}
                                label={items.label}
                            />
                        ))}
                    </div>
                </div>

            </div>
            <Outlet />
        </>
    )
}

export default ReturnAndRefund
