import React from 'react'

const Overlay = ({
    onClick,
    className,
    zVal = "z-[35]"
}) => {
    return (
        <div
            className={`${className} ${zVal} OVERLAY bg-black opacity-75 top-0 left-0 w-full fixed h-screen overflow-hidden`}
            onClick={onClick}
        ></div>)
}

export default Overlay