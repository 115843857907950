import React from 'react'
import {
    creditCard,
    appstore,
    googleplay,
} from '../../assets/index'


const RewardSchemeCard = () => {
    return (
        <div className='flex my-10 flex-col lg:flex-row items-center justify-center gap-3'>

            <div className=' w-full lg:w-1/2 flex justify-between items-center border border-primary bg-white rounded-md'>

                <div className='py-6 w-2/3 h-full flex items-center justify-center'>
                    <div className='w-full h-full flex flex-col items-start justify-center gap-1 lg:gap-2 p-4 xl:pl-12 xl:pr-4'>
                        <h6 className='font-semibold text-base line-clamp-1 xl:text-lg'>Reward Scheme</h6>
                        <div className='text-xs xl:text-sm'>Join our reward scheme to earn 5% cash back every time you shop</div>
                    </div>
                </div>

                <div className='w-1/3 mt-auto h-full '>
                    <img src={creditCard} className='h-full' alt="cards" />
                </div>

            </div>


            <div className='py-6 w-full lg:w-1/2 flex justify-between items-center border border-primary bg-white rounded-md'>

                <div className='w-2/3 h-full flex items-center justify-center'>
                    <div className='w-full h-full flex flex-col items-start justify-center gap-1 xl:gap-2 p-4 xl:pl-12 xl:pr-4'>
                        <h6 className='font-semibold text-base line-clamp-1 xl:text-lg'>ClassiCommerce App</h6>
                        <div className='text-xs xl:text-sm'>Shopping has never been faster with our app. Available for IOS & Android</div>
                    </div>
                </div>


                <div className='w-1/3'>
                    <div className="flex flex-col items-center justify-center gap-4">

                        <a href="https://play.google.com/">
                            <div className="block cursor-pointer w-24 xl:w-36">
                                <img
                                    src={googleplay}
                                    alt="googleplay"
                                    className="object-contain w-full h-full"
                                />
                            </div>
                        </a>

                        <a href="https://play.appstore.com/">
                            <div className="block cursor-pointer w-24 xl:w-36">
                                <img
                                    src={appstore}
                                    alt="appstore"
                                    className="object-contain w-full h-full"
                                />
                            </div>
                        </a>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default RewardSchemeCard