import React, { useState } from 'react'
import { getStarsFromRating } from '../../utils/getStarsFromRating'
import { IoIosStar } from "react-icons/io";
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { Button } from '../index'


const YouMayLikeCard = ({
    id,
    className,
    title,
    review,
    discountCost,
    cost,
    image,
    rating
}) => {

    const [imageLoaded, setImageLoaded] = useState(false);


    return (
        <div className={`${className} border border-primary rounded-md p-2 pb-4 flex items-start justify-center gap-2 hover:cursor-pointer`}>

            <div className='w-1/3 aspect-square rounded-md overflow-hidden'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={"image"} alt='loading' />
                    </>
                ) : (
                    <img className='p-2 md:p-0 xl:p-2 object-contain object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>

            <div className='w-2/3 pt-1 flex flex-col items-start flex-grow justify-between gap-0'>
                <div className='text-black line-clamp-2 text-sm'>{title}</div>

                <div className='flex items-center justify-center gap-x-0.5 text-xl'>
                    {
                        getStarsFromRating(rating,
                            <IoIosStar className='text-[#D4CDC5] text-lg' />,
                            <IoIosStar className='text-[#F2C94C] text-lg' />)
                    }
                    <span className='text-sm ml-1'>({review})</span>
                </div>

                <div className='flex items-center gap-1'>
                    <h5 className=' text-base md:text-sm xl:text-base font-semibold '>${discountCost}</h5>
                    <div className='text-xs text-gray-400 line-through '>${cost}</div>
                </div>

                <Button
                    className='mt-3 py-2 px-3 md:py-1 md:px-2 xl:py-2 xl:px-3 min-w-fit whitespace-nowrap rounded-md text-white bg-primary border border-secondary flex items-center justify-center gap-1'
                >
                    <PiShoppingCartSimpleThin className='text-lg md:text-base xl:text-lg' />
                    <div className='text-sm md:text-xs xl:text-sm font-normal'>Add to Cart</div>
                </Button>
            </div>

        </div>
    )
}

export default YouMayLikeCard