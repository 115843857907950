import React from 'react'
import { Button } from '../../../index'


const WishlistCon = () => {
    return (
        <div className='flex flex-col items-center gap-3 md:gap-5 p-4 md:px-6 md:py-5'>

            <h6 className="w-full font-bold mx-2 md:whitespace-nowrap text-center text-sm lg:text-lg">There are no items in your Wishlist</h6>

            <Button
                className='whitespace-nowrap rounded-md text-primary-hover text-sm w-full px-5 py-2 md:px-10 md:py-3 border border-secondary bg-white'
                label={'Continue Shopping'}
            />
        </div>
    )
}

export default WishlistCon