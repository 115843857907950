
const initialState = {
    isSidebarOpen: false,
}

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {

        case "IS_SIDEBAR_OPEN":
            return {
                ...state,
                isSidebarOpen: true
            }

        case "IS_SIDEBAR_CLOSE":
            return {
                ...state,
                isSidebarOpen: false
            }

        default:
            return state
    }
}

export default sidebarReducer