import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Logo } from '../../../assets/index'
import { FaArrowLeft, FaAngleDown } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { CiHeart } from "react-icons/ci";
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { RxAvatar } from "react-icons/rx";

import { Button, Input, Dropdown, Modal, Overlay } from '../../index'
import AccLogInCon from './ModalContents/AccLogInCon'
import AccLogOutCon from './ModalContents/AccLogOutCon'
import WishlistCon from './ModalContents/WishlistCon'
import ShopCartCon from './ModalContents/ShopCartCon'


const SearchNav = () => {

    const navigate = useNavigate()

    const [qsearch, setQsearch] = useState('')
    const [showFullWidthSearch, setShowFullWidthSearch] = useState(false)
    const [selectCategory, setSelectCategory] = useState('Category')

    const [isCartActive, setIsCartActive] = useState(false)
    const [isWishlistActive, setIsWishlistActive] = useState(false)
    const [isAccountActive, setIsAccountActive] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)


    const categoryOptions = [
        { value: "All", label: "Category" },
        { value: "cat1", label: "cat1" },
    ]

    return (
        <>
            <nav className='flex gap-5 justify-between h-16 md:h-fit md:py-6 px-3 md:px-0 lg:gap-20'>


                <div className={`${showFullWidthSearch ? 'hidden' : 'flex'} 
                items-center flex-shrink-0`}>

                    <Button
                        onClick={() => navigate("/")}
                        className="w-32 md:w-36 lg:w-44 flex justify-center items-center gap-3"
                    >
                        <img src={Logo} className='w-full h-full' alt="logo" />
                    </Button>

                </div>



                <form className={`${showFullWidthSearch ? 'flex' : 'hidden md:flex'} 
                w-fit justify-center items-center gap-4 flex-grow`}>

                    {showFullWidthSearch &&
                        <Button
                            onClick={() => setShowFullWidthSearch(false)}
                            className="pl-2"
                        >
                            <FaArrowLeft className='text-base' />
                        </Button>
                    }


                    <div className='flex flex-grow items-center max-w-[800px] border border-primary rounded-lg overflow-hidden'>

                        <Dropdown
                            onChange={(e) => setSelectCategory(e.target.value)}
                            className={'lg:py-3 bg-white px-1 text-sm lg:px-3 py-1 h-full'}
                            id={'selectCategory'}
                            name={'selectCategory'}
                            value={selectCategory}
                            options={categoryOptions}
                        />

                        <Input
                            placeholder={'Search products'}
                            value={qsearch}
                            onChange={(e) => setQsearch(e.target.value)}
                            className='lg:py-3 bg-white text-sm border-l border-primary shadow-none outline-none w-full h-full px-3 py-2'
                        />

                        <Button
                            className="lg:py-3 py-2 px-3 md:px-4 h-full border-l border-primary bg-primary flex-shrink-0">
                            <IoIosSearch className='text-2xl text-white' />
                        </Button>

                    </div>

                </form>



                <div className={`${showFullWidthSearch ? 'hidden' : 'flex'} 
                items-center flex-shrink-0 py-2 md:py-0 gap-2 md:gap-3 xl:gap-7`}>

                    <Button
                        onClick={() => setShowFullWidthSearch(true)}
                        className="md:hidden"
                    >
                        <IoIosSearch className='text-3xl xl:text-5xl' />
                    </Button>





                    <div className='relative flex items-center justify-center'>

                        <Button
                            onClick={() => {
                                setIsWishlistActive(true)
                            }}
                        >
                            <CiHeart className='text-3xl lg:text-4xl' />
                        </Button>

                        {isWishlistActive &&
                            <Modal
                                title={"My Wishlist"}
                                className={"arrow"}
                                contents={<WishlistCon />}
                            />
                        }
                    </div>




                    <div className='relative flex items-center justify-center'>
                        <Button
                            onClick={() => {
                                setIsCartActive(true)
                            }}
                        >
                            <PiShoppingCartSimpleThin className='text-3xl lg:text-4xl' />
                            {/* ADD LOGIC: IF cart.length > 0 */}
                            {
                                <div className='absolute flex justify-center items-center bg-primary rounded-full text-white right-0 top-0 translate-x-1/3 -translate-y-1/3
                                aspect-square w-4 md:w-5 xl:w-6 text-xs xl:text-sm border border-white'>
                                    2
                                </div>
                            }
                        </Button>

                        {isCartActive &&
                            <Modal
                                title={"Shopping Cart"}
                                className={"arrow"}
                                contents={<ShopCartCon setIsCartActive={setIsCartActive} />}
                                cartLength={2}
                            />
                        }
                    </div>





                    <div className='relative flex items-center justify-center'>
                        <Button
                            onClick={() => {
                                setIsAccountActive(true)
                            }}

                            className="flex items-center justify-center gap-x-1 md:gap-x-2"
                        >
                            <RxAvatar className='text-3xl lg:text-4xl' />
                            <FaAngleDown className='text-sm md:text-base' />
                        </Button>

                        {isAccountActive &&
                            <Modal
                                title={"My Account"}
                                className={"arrow-edge"}
                                contents={!isLoggedIn ? <AccLogOutCon /> : <AccLogInCon />}
                            />
                        }
                    </div>

                </div>
            </nav>


            {isCartActive && (
                <Overlay onClick={() => setIsCartActive(false)} />
            )}
            {isWishlistActive && (
                <Overlay onClick={() => setIsWishlistActive(false)} />
            )}
            {isAccountActive && (
                <Overlay onClick={() => setIsAccountActive(false)} />
            )}

        </>
    )
}

export default SearchNav