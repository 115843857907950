import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop'
import { getStarsFromRating } from '../../utils/getStarsFromRating'

import { IoIosStar } from "react-icons/io";
import { Button } from '../index'
import { IoIosHeartEmpty } from "react-icons/io";

const Listing = ({
    id,
    className,
    discountOffer,
    dealType,
    title,
    review,
    discountCost,
    cost,
    image,
    rating,
    wishHeart
}) => {

    const navigate = useNavigate()

    const [imageLoaded, setImageLoaded] = useState(false);


    let dealColor
    if (dealType === "Deal of the Day") { dealColor = '#C71720' }
    else if (dealType === "Best Deals") { dealColor = '#0072E3' }
    else if (dealType === "Sale") { dealColor = '#219653' }
    else { dealColor = "#4EB4F6" }

    return (

        <div
            onClick={() => {
                navigate(`/category/${id}`)
                scrollToTop()
            }}
            className={`${className} group hover:cursor-pointer hover:shadow md:hover:shadow-xl duration-300 flex flex-col rounded-xl border border-primary bg-white`}
        >

            <div className='relative h-2/3 p-4 border-b border-primary'>

                {discountOffer &&
                    <div className='z-10 p-3 lg:py-3 lg:px-4 absolute top-0 left-0 bg-[#C71720] text-white w-fit rounded-tl-xl rounded-br-xl flex flex-col items-center justify-center'>
                        <p className='text-xs md:text-sm'>{discountOffer}%</p>
                        <p className='text-xs md:text-sm'>OFF</p>
                    </div>
                }

                {dealType &&
                    <div
                        style={{ backgroundColor: dealColor }}
                        className={` z-10 py-2 px-3 lg:px-4 absolute bottom-0 left-0 text-white w-fit rounded-tr-xl`}
                    >
                        <p className='text-xs md:text-sm'>{dealType}</p>
                    </div>
                }

                <Button
                    // onClick={}   //change wishHeart boolean in backend. add a delay/disable after each click to avoid backend overload
                    className={'z-10 p-2 bg-white absolute top-0 right-0 -translate-x-2 translate-y-2 rounded-full border border-primary'}
                >
                    {/* find a heart svg with stroke and fill property */}
                    <IoIosHeartEmpty className='text-black text-lg md:text-xl' />
                </Button>

                <div className='aspect-square'>
                    {!imageLoaded ? (
                        <>
                            <img
                                className='hidden'
                                onLoad={() => setImageLoaded(true)}
                                src={image}
                                alt='items'
                            />
                            <img className='p-8 object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                        </>
                    ) : (
                        <img className='px-3 pt-3 pb-5 md:px-5 group-hover:scale-110 duration-500 ease-in-out object-contain object-center w-full h-full' src={image} alt={'items'} />
                    )}
                </div>
            </div>

            <div className='min-h-1/3 p-2 md:p-4'>
                <h1 className='group-hover:text-primary-hover line-clamp-2 text-left text-xs md:text-sm text-black'>{title}</h1>

                {/* Wrap all stars in a button and keep track of the rating */}
                {/* <div className='mt-1 flex items-center gap-1'>
                    <IoIosStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoIosStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoIosStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoIosStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoIosStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <div className='inline-block text-xs lg:text-sm'>({review})</div>
                </div> */}

                <div className='flex mt-1 items-center justify-start gap-x-0.5 text-xl'>{getStarsFromRating(rating, <IoIosStar className='text-[#D4CDC5]' />)}
                    <span className='text-sm ml-1'>({review})</span>
                </div>

                <div className='mt-2 flex items-end gap-1'>
                    <h5 className=' text-lg md:text-xl font-semibold '>${discountCost}</h5>
                    <div className='text-xs md:text-sm text-gray-400 line-through '>${cost}</div>
                </div>

            </div>

        </div>
    )
}

export default Listing