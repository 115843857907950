import React, { useState } from 'react';
import { Button } from '../index'


const BestDealCard = ({ title, image }) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (

        <div className={`flex border-r border-b border-primary bg-white pl-2 md:pl-5 pt-6 pb-2 hover:cursor-pointer`}>

            <div className='w-1/2 mb-3'>
                <h1 className='text-left line-clamp-3 overflow-visible lg:line-clamp-none lg:whitespace-nowrap text-sm md:text-base  text-black'>{title}</h1>
            </div>


            <div className='w-1/2'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img className='object-contain object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>

        </div>
    )
}

export default BestDealCard