import React from 'react'
import { Link } from 'react-router-dom'
import { scrollToTop } from '../../../utils/scrollToTop'
import { Accordion } from '../../../components/index'
import PhoneAndTablets from './ReturnPolicyCategory/PhoneAndTablets'
import ConditionForReturns from './ConditionForReturns/ConditionForReturns'

const ReturnPolicy = () => {

    const accordionItems = [
        {
            id: 1,
            title: 'Phones and Tablets',
            content: <PhoneAndTablets />,
        },
        {
            id: 2,
            title: 'Beauty & Health',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 3,
            title: "Appliances",
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 4,
            title: 'Computer & Gaming',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 5,
            title: 'Digital Goods',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 6,
            title: 'Sports & Travel',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 7,
            title: 'Home & Living',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 8,
            title: 'Fashion',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 9,
            title: 'Other Categories',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        }
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div className={`${sectionPad}`}>

            {/* Return Policy */}
            <div className='flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                <div className='w-full lg:w-[67.66%] border bg-white border-primary rounded-md divide-y'>
                    <div className='px-6 py-4 text-lg text-black font-semibold'>Return Policy</div>

                    <div className='text-justify md:text-left text-sm text-black'>
                        <li className='px-6 py-4 list-none'>Items shipped from Amazon.com, including Amazon Warehouse, can be returned within 30 days of delivery, with some exceptions:</li>

                        <ol className='py-6 pl-10 pr-6 pt-0 md:px-12 list-decimal space-y-4'>
                            <li>For information about products that can&apos;t be returned, see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Items that Can&apos;t be Returned</Link></span>
                                .
                            </li>

                            <li>To determine if a product sold by Amazon.com has a longer return timeline, locate the product type below. If the product type isn&apos;t listed or doesn&apos;t state another returns time frame, our standard 30-day return policy applies.</li>
                            <li>While most sellers offer a returns policy equivalent to Amazon&apos;s, some seller returns policies may vary. If the seller&apos;s listing is eligible for Amazon Prime, it will be subject to the same return policies as items fulfilled by Amazon.com that are outlined on this page. For more information about returning to third-party sellers, see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Returns to Third-Party Sellers</Link></span>
                                .
                            </li>

                            <li>For products purchased from the Amazon Global Store, see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Amazon Global Store Returns</Link></span>
                                .
                            </li>

                            <li>For more information on refund timing and amounts (including partial refunds and restocking fees), see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Refunds</Link></span>
                                .
                            </li>

                            <li>If you have returned an item to Amazon by mistake or included something not intended for Amazon in a return, see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Mistaken Returns</Link></span>
                                .
                            </li>

                            <li>If you have already received a refund and are expected to return the item, you can create a return request. For more information about how to create a returns request see&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Return Items You Ordered</Link></span>
                                . You will be charged if an item that is expected to be returned is not sent back to us.
                            </li>

                            <li>If the item has already been sent back to us and you have received an email asking you return it or have been charged for item, we will reverse the charge as soon as the return is processed by us. It might take additional time for your financial institution to make funds available in your account.</li>
                        </ol>
                    </div>
                </div>


                {/* Condition for Returns */}
                <ConditionForReturns />


            </div>


            {/* Valid reasons to return an item */}
            <div className='my-6 lg:my-8 w-full lg:w-[66%] border bg-white border-primary rounded-md divide-y'>
                <div className='px-6 py-4 text-lg text-black font-semibold'>Valid reasons to return an item</div>

                <ol className='py-6 pl-10 pr-6 md:px-12 text-justify md:text-left text-black text-sm list-decimal space-y-4'>
                    <li>Delivered product is damaged (i.e. physically destroyed or broken) / defective (e.g. unable to switch on)</li>
                    <li>Delivered product is incomplete (i.e. has missing items and/or accessories)</li>
                    <li>Delivered product is incorrect (i.e. wrong product/size/colour, fake item, or expired)</li>
                    <li>Delivered product is does not match product description or picture (i.e product not as advertised)</li>
                    <li>Delivered product does not fit. (i.e. size is unsuitable)</li>
                </ol>
            </div>


            {/* Returns Policy per Category */}
            <div className='mb-10 lg:mb-16 w-full lg:w-[66%] border bg-white border-primary rounded-md divide-y'>
                <div className='px-6 py-4 text-lg text-black font-semibold'>Returns Policy per Category</div>

                <li className='text-justify md:text-left text-sm text-black px-6 py-4 list-none'>Please note that certain items marked as non-returnable on product page are not eligible for return. For more information view the complete list of&nbsp;
                    <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">non-returnable items</Link></span>
                    .
                </li>

                <div className='border-none w-full px-6 pb-6 mt-2 space-y-3 md:space-y-4'>
                    <Accordion
                        items={accordionItems}
                    />
                </div>

            </div>

        </div>
    )
}


export default ReturnPolicy