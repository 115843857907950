import React from 'react'

import { BsLightningChargeFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import {
    Button,
    MainCarousel,
    Listing,
    CarouselCon,
    NewArrivalCard
} from '../../components/index'

import {
    smartPhone,
    ps5,
    womenCollection,
    speakers,
    perfume,
    watches,
    musicInstrument,
    makeup,
    earphone,
    menCollection,
    childrenCollection,
    laptop
} from '../../assets/index'
import { backgroundNewArrival } from '../../assets/index'


const NewArrival = () => {


    const featuredProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div>
            <MainCarousel
                contents={[
                    <CarouselCon
                        key={1}
                        backgroundIMG={backgroundNewArrival}  //image class in index.css
                        slideText={"NEW ARRIVAL"}
                        slideTextCss={"lg:text-2xl font-[300]"}
                        title={"New Macbook Pro"}
                        ButtonCss={"bg-white text-[#191C1F]"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='relative w-full h-full'>
                                <img src={laptop} className='w-full h-full p-5' alt="" />
                                <div className='border-4 lg:border-8 xl:border-[12px] p-1 md:p-2 lg:p-4 xl:p-8 absolute top-0 translate-y-1/4 md:translate-y-1/3 left-0 rounded-full w-fit aspect-square text-white z-[5] border-[#F3F2FF] bg-[#2DA5F3] flex items-center justify-center font-semibold 
                                text-xs md:text-base xl:text-2xl'>
                                    $1999
                                </div>
                            </div>
                        }
                    />,
                    <CarouselCon
                        key={2}
                        backgroundIMG={backgroundNewArrival}
                        slideText={"NEW ARRIVAL"}
                        slideTextCss={"lg:text-2xl font-[300]"}
                        title={"New Macbook Pro"}
                        ButtonCss={"bg-white text-[#191C1F]"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='relative w-full h-full'>
                                <img src={laptop} className='w-full h-full p-5' alt="" />
                                <div className='border-4 lg:border-8 xl:border-[12px] p-1 md:p-2 lg:p-4 xl:p-8 absolute top-0 translate-y-1/4 md:translate-y-1/3 left-0 rounded-full w-fit aspect-square text-white z-[5] border-[#F3F2FF] bg-[#2DA5F3] flex items-center justify-center font-semibold 
                                text-xs md:text-base xl:text-2xl'>
                                    $1999
                                </div>
                            </div>
                        }
                    />,
                    <CarouselCon
                        key={3}
                        backgroundIMG={backgroundNewArrival}
                        slideText={"NEW ARRIVAL"}
                        slideTextCss={"lg:text-2xl font-[300]"}
                        title={"New Macbook Pro"}
                        ButtonCss={"bg-white text-[#191C1F]"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='relative w-full h-full'>
                                <img src={laptop} className='w-full h-full p-5' alt="" />
                                <div className='border-4 lg:border-8 xl:border-[12px] p-1 md:p-2 lg:p-4 xl:p-8 absolute top-0 translate-y-1/4 md:translate-y-1/3 left-0 rounded-full w-fit aspect-square text-white z-[5] border-[#F3F2FF] bg-[#2DA5F3] flex items-center justify-center font-semibold 
                                text-xs md:text-base xl:text-2xl'>
                                    $1999
                                </div>
                            </div>
                        }
                    />
                ]}
            />

            <div className={`${sectionPad} border-b border-primary py-4 md:py-8`}>

                <div className='my-8 flex gap-2 items-center justify-center'>
                    <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
                    <h1 className='text-primary font-semibold text-xl md:text-3xl'>NEW ARRIVAL</h1>
                    <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
                </div>



                <div className='flex flex-col md:flex-row items-center justify-center gap-3 xl:gap-6'>

                    <NewArrivalCard
                        image={ps5}
                        title={"PlayStation 5"}
                        description={"Black and White version of the PS5 coming out for sale"}
                        className={"w-full md:w-1/2"}
                        titleCss={"text-xl xl:text-3xl"}
                        descCss={"text-sm xl:text-base"}
                    />

                    <div className='w-full md:w-1/2 h-full flex flex-col justify-between items-center gap-3 xl:gap-6'>

                        <NewArrivalCard
                            image={womenCollection}
                            title={"Women's Collections"}
                            description={"Featured women collection available now"}
                            className={"w-full h-1/2"}
                            titleCss={"text-lg xl:text-2xl"}
                            descCss={"text-sm xl:text-base"}
                        />

                        <div className='h-1/2 w-full flex items-center justify-between gap-3 xl:gap-6'>

                            <NewArrivalCard
                                image={speakers}
                                title={"Speakers"}
                                description={"Amazing wireless speaker"}
                                className={"w-1/2"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />


                            <NewArrivalCard
                                image={perfume}
                                title={"Perfume"}
                                description={"Premium Perfume"}
                                className={"w-1/2"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />

                        </div>
                    </div>
                </div>


                <div className='my-7 xl:my-10  flex flex-col gap-3 xl:gap-6'>

                    <div className='flex flex-col md:flex-row gap-3 xl:gap-6'>
                        <div className='w-full md:w-1/2 flex gap-3 xl:gap-6'>
                            <NewArrivalCard
                                image={watches}
                                title={"Watches"}
                                description={"Embrace the moment"}
                                className={"w-full"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />
                            <NewArrivalCard
                                image={musicInstrument}
                                title={"Music Intruments"}
                                description={"Feel the beat"}
                                className={"w-full"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />
                        </div>

                        <div className='w-full md:w-1/2 flex gap-3 xl:gap-6'>
                            <NewArrivalCard
                                image={makeup}
                                title={"Make up"}
                                description={"Unveil your radiance"}
                                className={"w-full"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />
                            <NewArrivalCard
                                image={earphone}
                                title={"Earphones"}
                                description={"Music makes you dance"}
                                className={"w-full"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row gap-3 xl:gap-6'>
                        <div className='w-full md:w-1/2 flex gap-3 xl:gap-6'>

                            <NewArrivalCard
                                image={menCollection}
                                title={"Men’s Collections"}
                                description={"Featured men collection available now "}
                                className={"w-full"}
                                titleCss={"text-lg xl:text-2xl"}
                                descCss={"text-sm xl:text-base"}
                            />
                        </div>

                        <div className='w-full md:w-1/2 flex gap-3 xl:gap-6'>
                            <NewArrivalCard
                                image={childrenCollection}
                                title={"Children’s Collections"}
                                description={"Featured children collection available now "}
                                className={"w-full"}
                                titleCss={"text-lg xl:text-2xl"}
                                descCss={"text-sm xl:text-base"}
                            />
                        </div>
                    </div>
                </div>

            </div>


            <div className={`${sectionPad} border-b border-primary py-4 md:py-8`}>
                <h2 className='my-10 font-semibold text-xl md:text-3xl'>Just For You</h2>

                <div className='grid gap-3 md:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'>
                    {featuredProducts.map((items) => (
                        <Listing
                            key={items.id}
                            {...items}
                            image={items.image}
                            wishHeart={items.wishHeart}
                        />

                    ))}
                </div>

                <div className='w-full flex items-center justify-center'>
                    <Button
                        className='my-7 xl:my-10 whitespace-nowrap rounded-md text-secondary text-sm md:text-base px-14 py-2 md:px-20 lg:px-32 md:py-3 border border-secondary bg-white'
                        label={"Load More"}
                    >
                    </Button>
                </div>
            </div>


        </div>
    )
}

export default NewArrival