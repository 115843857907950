import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from '../../../components/index'
import { scrollToTop } from '../../../utils/scrollToTop'
import ConditionForReturns from './ConditionForReturns/ConditionForReturns'


const RefundPolicy = () => {

    const refuntTimesTable = [
        { id: 1, td1: "Items in original condition past the return window*", td2: "80% of the item price" },
        { id: 2, td1: "Opened CDs, DVDs, VHS tapes, cassette tapes, or vinyl records (taken out of its plastic wrap)", td2: "50% of the item price" },
        { id: 3, td1: "Items with damage, missing parts, not in original condition, or that have obvious signs of use for reasons not due to an Amazon.com or seller error", td2: "Up to 50% of the item price" },
        { id: 4, td1: "Shop with Reward Points", td2: "0% of the item price" },
    ]

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div className={`${sectionPad} mb-10 lg:mb-16`}>

            <div className='flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                <div className='h-fit w-full lg:w-[67.66%] space-y-6 lg:space-y-8'>

                    {/* Return Policy */}
                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-lg text-black font-semibold'>Return Policy</div>

                        <ul className='divide-y px-6 list-none text-justify md:text-left text-sm text-black'>
                            <li className='py-4'>When you return an item, your refund amount and refund method may vary. Check the payment method refunded and the status of your refund in Your Orders.</li>
                            <li className='py-4'>Note: All information on this page only relates to items bought from or Fulfilled by Classicommerce. Don&apos;t return items to Amazon that were bought on Classicommerce Marketplace and shipped to you by the seller.</li>
                        </ul>
                    </div>


                    {/* How Long Do Refunds Take After I Return an Item? */}
                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-lg text-black font-semibold'>How Long Do Refunds Take After I Return an Item?</div>

                        <ul className='px-6 list-none text-justify md:text-left text-sm text-black'>
                            <li className='py-4'>When returning an item, you have the option to choose your preferred refund method in the&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Online Returns Center</Link></span>
                                .
                            </li>

                            <li className='py-4'>It can take up to 30 days for us to receive and process your return. When we complete processing your return, we issue a refund to the selected payment method. Learn how to&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Track Your Return</Link></span>
                                .
                            </li>
                        </ul>
                    </div>


                    {/* Instant Refunds */}
                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-lg text-black font-semibold'>Instant Refunds</div>

                        <ul className='px-6 list-none text-justify md:text-left text-sm text-black'>
                            <li className='py-4'>If you want to use your refund without waiting for us to process your return, you can select the Instant Refund option, if it&apos;s available. Instant refunds are either refunded to your credit card or issued as an Amazon.com Gift Card balance. You&apos;ll still need to return your items within 30 days.</li>
                        </ul>
                    </div>


                    {/* Refund Times */}
                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-lg text-black font-semibold'>Refund Times</div>

                        <div className='px-6 text-sm text-black'>
                            <div className='py-4 text-justify md:text-left'>Once we issue your refund, it takes additional time for your financial institution to make funds available in your account. Refer to following table for more details.</div>

                            <Table
                                headItems={{ th1: "Refund Method", th2: "Refund Time (After Refund Is Processed)" }}
                                bodyItems={refuntTimesTable}
                            />

                            <div className='py-4'>Note: If you no longer have the physical pre-paid credit card, we still store the card within your account. You can use the card toward future purchases. For orders that used the Amazon Currency Converter, we issue the refund in your local currency using the same rate as the original order.</div>
                        </div>
                    </div>


                    {/* Partial Refunds or Restocking Fees */}
                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-lg text-black font-semibold'>Partial Refunds or Restocking Fees</div>

                        <div className='px-6 text-sm text-black'>
                            <div className='py-4 text-justify md:text-left'>Once we issue your refund, it takes additional time for your financial institution to make funds available in your account. Refer to following table for more details.</div>

                            <Table
                                headItems={{ th1: "Item", th2: "Refund" }}
                                bodyItems={refuntTimesTable}
                            />

                            <div className='py-4'>*For most items, the return window is 30 days after delivery. To check the return window for an item you&apos;ve ordered, go to&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">Your Orders</Link></span>
                                &nbsp;and select Return or Replace Items.
                            </div>

                            <div className='py-4'>Note: For customers in CT, MD, NV, PA, VA, WV, and WI, tax on restocking fees may apply to return items shipped and sold by Amazon.com. Some products have additional conditions that may apply. For more information, go to&nbsp;
                                <span className='underline hover:text-secondary'><Link onClick={scrollToTop} to="/">About Our Returns Policies</Link></span>
                                .
                            </div>

                        </div>
                    </div>


                </div>


                {/* Condition for Returns */}
                <ConditionForReturns />


            </div>

        </div>
    )
}

export default RefundPolicy