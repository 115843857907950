import React, { useState } from 'react'

const Avatar = ({ image, name, position }) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className='flex flex-col justify-center items-center gap-y-3'>

            <div className='w-full aspect-[14/9] mt-4 rounded-md overflow-hidden'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img src={image} className='w-full h-full object-contain' alt="" />
                )}
            </div>

            <div className='flex flex-col items-center justify-center gap-y-1'>
                <h6 className='font-semibold text-base lg:text-lg'>{name}</h6>
                <div className='text-customGray text-sm'>{position}</div>
            </div>
        </div>
    )
}

export default Avatar