import React from 'react'
import { Button } from '../../index'
import { useNavigate } from 'react-router-dom';
import { GoArrowRight } from "react-icons/go";
import "./contents.css"

const CarouselCon = ({
    backgroundIMG,
    slideTextCss = "font-[300]",
    slideText,
    title,
    ButtonCss,
    ButtonLabel = "Learn More",
    rightContent
}) => {

    const navigate = useNavigate()

    return (
        <div
            style={{
                backgroundImage: `url("${backgroundIMG}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            className={`${backgroundIMG} py-10 md:py-0 w-full flex items-center justify-center`} >

            <div className='w-1/2 mr-auto'>

                <div className='w-[85%] mx-auto md:ml-auto md:mr-0 h-full flex flex-col items-start justify-center gap-x-1 lg:gap-y-3 xl:gap-y-5'>
                    <div className={`${slideTextCss} text-xs text-[#474D59] tracking-widest font-[300]`}>{slideText}</div>
                    <div className='text-lg md:text-2xl line-clamp-2 lg:text-4xl xl:text-6xl font-semibold'>{title}</div>

                    <Button
                        onClick={() => navigate("/")}
                        className={`${ButtonCss} mt-2 md:mt-4 xl:mt-8 rounded-xl flex justify-center items-center gap-2 py-2 px-4 lg:py-3 lg:px-6`}
                    >
                        <p className='text-xs whitespace-nowrap lg:text-base'>{ButtonLabel}</p>
                        <GoArrowRight className='text-sm lg:text-xl' />
                    </Button>
                </div>
            </div>

            <div className='w-1/2 ml-auto'>
                {rightContent}
            </div>

        </div>
    )
}

export default CarouselCon