import React from 'react'
import { Button } from '../../components/index'
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';
import { bag } from '../../assets/index'


const CartEmpty = () => {
    const navigate = useNavigate()

    return (
        <div className='min-h-screen w-full flex items-center justify-center'>

            <div className='w-full md:w-[70%] lg:w-1/2 mx-auto gap-y-8 p-4 flex flex-col items-center justify-center'>
                <div className='relative mx-auto w-[181px] h-[200px]'>
                    <img src={bag} className='block w-full h-full object-contain' alt="" />

                    <div className='absolute flex justify-center items-center bg-primary rounded-full text-white right-0 bottom-0 -translate-y-2/3 
                    shadow-[0_0_5px_rgba(67,24,191,0.5)] aspect-square w-8 text-xs xl:text-sm border border-secondary'>
                        0
                    </div>
                </div>

                <h2 className='text-center text-3xl font-semibold whitespace-nowrap'>Your Cart is empty</h2>

                <Button
                    onClick={() => {
                        navigate("/")
                        scrollToTop()
                    }}
                    className={"py-3 px-6 w-full md:w-1/2 border whitespace-nowrap border-secondary bg-primary text-white rounded-md"}
                    label={"Continue Shopping"}
                />

            </div>
        </div>
    )
}

export default CartEmpty