import React, { useRef, useState } from "react";


const OTP = () => {

    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const newOtp = [...otp];

        // Allow only numeric input and limit length to 1
        const sanitizedValue = value.replace(/\D/g, '').substring(0, 1);

        // Update the OTP array
        newOtp[index] = sanitizedValue;
        setOtp(newOtp);

        // Move focus forward or backward based on input
        if (sanitizedValue && index < 5) {
            inputRefs[index + 1].current.focus();
        } else if (!sanitizedValue && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && index > 0 && !otp[index]) {
            const newOtp = [...otp];
            newOtp[index - 1] = "";
            setOtp(newOtp);
            inputRefs[index - 1].current.focus();
        }
    };

    const inputCSS = "border-b-2 font-bold text-xl text-center py-2 md:py-4 outline-none w-10 md:w-14 xl:w-16";

    return (
        <>
            <div className="flex items-center gap-x-3 md:gap-x-6 justify-center">
                {otp.map((value, index) => (
                    <input
                        key={index}
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleBackspace(e, index)}
                        className={`${value ? "border-secondary" : "border-primary"} ${inputCSS}`}
                        type="number"
                        inputMode="numeric"
                        required
                        ref={inputRefs[index]}
                    />
                ))}
            </div>
        </>
    );
};

export default OTP;
