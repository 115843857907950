import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    facebook,
    instagram,
    youtube,
    twitter,
    linkedin,

    QRCode,
    appstore,
    googleplay,
    Logo
} from "../../assets/index";
import { Dropdown } from '../index'
import { CiGlobe } from "react-icons/ci";
import { scrollToTop } from '../../utils/scrollToTop'

/**
 * social icon hover color left;
 */

const socail = [
    {
        id: 1,
        name: facebook,
        link: "https://www.facebook.com/",
    },
    {
        id: 2,
        name: instagram,
        link: "https://www.instagram.com/",
    },
    {
        id: 3,
        name: youtube,
        link: "https://www.youtube.com/",
    },
    {
        id: 4,
        name: twitter,
        link: "https://www.twitter.com/",
    },
    {
        id: 5,
        name: linkedin,
        link: "https://www.linkedin.com/",
    },
];

const classiCommerce = [
    {
        id: 1,
        name: "About Classicommerce",
        link: "/about-classicommerce",
    },

    {
        id: 2,
        name: "Contact Us",
        link: "/contact-us",
    },
    {
        id: 3,
        name: "Category",
        link: "/product-listing",
    },
    {
        id: 4,
        name: "Blogs",
        link: "/blogs",
    },
    {
        id: 5,
        name: "Return & Refund",
        link: "/return-and-refund/how-to-return-a-product",
    },
    {
        id: 6,
        name: "Become a Seller",
        link: "/become-a-seller",
    },
];

const topCategories = [
    {
        id: 1,
        name: "Smartphones",
        link: "/smartphones",
    },
    {
        id: 2,
        name: "Laptops",
        link: "/laptops",
    },
    {
        id: 3,
        name: "Smart TV",
        link: "/smart-tv",
    },
    {
        id: 4,
        name: "Camera & Gadgets",
        link: "/gadgets",
    },
    {
        id: 5,
        name: "Earphones",
        link: "/earphones",
    },
    {
        id: 6,
        name: "Fashion",
        link: "/fashion",
    },
];

const legal = [
    {
        id: 1,
        name: "Terms of Use",
        link: "/terms-&-condition",
    },
    {
        id: 2,
        name: "Privacy Policy",
        link: "/privacy-policy",
    },
    {
        id: 3,
        name: "Listing Policy",
        link: "/listing-policy",
    },
    {
        id: 4,
        name: "Cookie Policy",
        link: "/cookie-policy",
    },
];



const Footer = () => {

    const [lang, setLang] = useState('English')

    const langOptions = [
        { value: "English", label: "English" },
        { value: "Nepali", label: "Nepali" },
    ]

    return (
        <>
            <div className="bg-white py-10 w-full">
                <div className="flex flex-wrap gap-y-10 flex-col justify-evenly items-start w-[95%] mx-auto
                xl:w-[85%] md:flex-row lg:justify-between">

                    <div className="px-3">
                        <div className="w-fit flex justify-center items-center gap-3">
                            <img src={Logo} alt="logo" />
                        </div>

                        <div className="mt-7">
                            <p className="text-primary font-semibold text-base">Connect with Us</p>
                            <p className="text-primary text-xs">Connect with us on Social Media</p>
                        </div>

                        <div className="flex gap-2 mt-4">
                            {socail.map((icon) => (
                                // hover color remaining
                                <a href={icon.link} key={icon.id}>
                                    <div className="cursor-pointer w-[32px] h-[32px]">
                                        <img
                                            src={icon.name}
                                            alt="img"
                                            className="object-fill w-full h-full"
                                        />
                                    </div>
                                </a>

                            ))}
                        </div>
                    </div>


                    <div className="flex justify-start flex-wrap gap-10 px-3">

                        {/* ClassiCommerce */}
                        <div>
                            <h6 className="font-semibold text-lg text-primary">ClassiCommerce</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {classiCommerce.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="text-xs text-primary hover:text-primary-hover hover:underline">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>


                        {/* Top Categories */}
                        <div>
                            <h6 className="font-semibold text-lg text-primary">Top Categories</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {topCategories.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="text-xs text-primary hover:text-primary-hover hover:underline">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>


                        {/* Legal */}
                        <div>
                            <h6 className="font-semibold text-lg text-primary">Legal</h6>

                            <div className="mt-3 md:mt-6 flex flex-col gap-2">
                                {legal.map((text) => {
                                    return (
                                        <Link key={text.id} to={text.link} onClick={scrollToTop}>
                                            <p className="text-xs text-primary hover:text-primary-hover hover:underline">
                                                {text.name}
                                            </p>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>


                    {/* Download App */}
                    <div className="px-3">
                        <h6 className="font-semibold text-lg text-primary">Download App</h6>

                        <div className="flex gap-3 mt-4">

                            <div className="block min-w-[88px] w-full">
                                <img
                                    src={QRCode}
                                    alt="QRCode"
                                    className="object-fill w-full h-full"
                                />
                            </div>

                            <div className="flex flex-col gap-4">

                                <a href="https://play.google.com/">
                                    <div className="block cursor-pointer w-[140px]">
                                        <img
                                            src={googleplay}
                                            alt="googleplay"
                                            className="object-fill w-full h-full"
                                        />
                                    </div>
                                </a>

                                <a href="https://play.appstore.com/">
                                    <div className="block cursor-pointer w-[140px]">
                                        <img
                                            src={appstore}
                                            alt="appstore"
                                            className="object-fill w-full h-full"
                                        />
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="w-full py-4 bg-[#EFF2F4]">
                <div className="flex flex-wrap gap-3 justify-around items-center w-[95%] mx-auto px-3
                xl:w-[85%]">

                    <p className="xl:mr-auto text-primary text-sm md:text-base">2023 All Rights Reserved © Classibazzar</p>


                    <div className="xl:ml-auto pl-2 flex items-center justify-center  border border-[#C3C5C9] rounded-md overflow-hidden">
                        <label htmlFor="en-lang"><CiGlobe className="text-black text-base" /></label>

                        <Dropdown
                            onChange={(e) => setLang(e.target.value)}
                            className={' bg-[#EFF2F4] p-2 text-sm md:text-base'}
                            id={'en-lang'}
                            name={'en-lang'}
                            value={lang}
                            options={langOptions}
                        />
                    </div>

                </div>
            </div>
        </>
    );
};

export default Footer;
