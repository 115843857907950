import React from 'react'
import { Overlay, Button, RadioButton } from '../../../components/index'
import { scrollToTop } from '../../../utils/scrollToTop'

import { IoMdClose } from "react-icons/io";


const CategorySidebar = ({
    toggleCategorySidebar,
    setToggleCategorySidebar,
    radioOptions,
    selectedOption,
    setSelectedOption,
}) => {

    return (
        <>
            <aside
                className={`${toggleCategorySidebar ? "translate-x-0" : "-translate-x-full"} block lg:hidden
                bg-white fixed left-0 top-0 pb-10 divide-y h-screen w-[80%] shadow-2xl transform z-50 ease-in-out duration-500
                md:w-2/3 lg:w-[40%] xl:w-1/4`}
            >

                <div className='h-[10vh] border-b border-primary flex items-center justify-between px-4 py-1'>

                    <div className="w-40 flex justify-center items-center gap-3">
                        <img src={"Logo"} className='w-full h-full' alt="logo" />
                    </div>

                    <Button
                        onClick={() => setToggleCategorySidebar(false)}
                        className={"py-4 px-8"}
                    >
                        <IoMdClose className='text-2xl' />
                    </Button>
                </div>


                <div className='h-[90vh] overflow-x-hidden overflow-y-auto'>

                    <div className='mb-6 h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-base text-black font-medium'>Category</div>

                        <div className='px-6 py-4 space-y-4'>
                            {radioOptions.map((option) => (

                                <div key={option.id}>
                                    <RadioButton
                                        key={option.id}
                                        id={option.id}
                                        name="options"
                                        value={option.value}
                                        label={option.label}
                                        checked={selectedOption === option.value}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                    />

                                </div>
                            ))}
                            <div className='text-secondary text-sm'>See all</div>
                            <div className='text-red-500'>Problem with radio button css. actual logic works</div>

                        </div>
                    </div>
                </div>
            </aside>

            {toggleCategorySidebar && (
                <Overlay onClick={() => setToggleCategorySidebar(false)} />
            )}
        </>
    )
}

export default CategorySidebar