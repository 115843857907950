import React, { useState, useEffect } from 'react'

import {
    Button,
    RadioButton,
    BlogCard,
    Input,
    Dropdown,
    Listing,
    ListingList,
} from '../../../components/index'

import {
    blogImg1,
    smartPhone
} from '../../../assets/index'

import useWindowResolution from '../../../hooks/useWindowResolution'
import FilterSidebar from './FilterSidebar/FilterSidebar'

import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { IoGridSharp } from "react-icons/io5";
import { FaThList } from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";

const ProductListing = () => {

    const windowSize = useWindowResolution();

    const isLgScreen = windowSize.width >= 1024 ? true : false

    const [selectCategory, setSelectCategory] = useState('')
    const [toggleFilterSidebar, setToggleFilterSidebar] = useState(false)
    const [gridOverList, setGridOverList] = useState(true)

    const sortOptions = [
        { value: "recent", label: "Most Recent" },
        { value: "helpMe!", label: "Yesterday" },
    ]

    const filteredProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            desc: "MSI CREATOR 17 A10SFS-240AU 17 UHD 4K HDR Thin Bezel Intel 10th Gen i7 10875H - RTX 2070 SUPER MAX Q - 16GB RAM - 1TB SSD NVME - Windows 10 PRO Laptop",
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            desc: "MSI CREATOR 17 A10SFS-240AU 17 UHD 4K HDR Thin Bezel Intel 10th Gen i7 10875H - RTX 2070 SUPER MAX Q - 16GB RAM - 1TB SSD NVME - Windows 10 PRO Laptop",
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            desc: "MSI CREATOR 17 A10SFS-240AU 17 UHD 4K HDR Thin Bezel Intel 10th Gen i7 10875H - RTX 2070 SUPER MAX Q - 16GB RAM - 1TB SSD NVME - Windows 10 PRO Laptop",
            review: '24',
            discountCost: '999.50',
            cost: '1299.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            desc: "MSI CREATOR 17 A10SFS-240AU 17 UHD 4K HDR Thin Bezel Intel 10th Gen i7 10875H - RTX 2070 SUPER MAX Q - 16GB RAM - 1TB SSD NVME - Windows 10 PRO Laptop",
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            desc: "MSI CREATOR 17 A10SFS-240AU 17 UHD 4K HDR Thin Bezel Intel 10th Gen i7 10875H - RTX 2070 SUPER MAX Q - 16GB RAM - 1TB SSD NVME - Windows 10 PRO Laptop",
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    useEffect(() => {

        if (isLgScreen && !toggleFilterSidebar) {
            setToggleFilterSidebar(true);
        }

    }, [isLgScreen, toggleFilterSidebar]);



    return (
        <>
            <div className={`${sectionPad} flex gap-6`}>

                {/* Category */}

                <FilterSidebar
                    toggleFilterSidebar={toggleFilterSidebar}
                    setToggleFilterSidebar={setToggleFilterSidebar}
                />



                {/* Contents */}
                <div className='w-full flex-grow lg:w-2/3 xl:w-2/3'>

                    <div className='mb-6 p-4 gap-4 xl:gap-6 rounded-md border border-primary bg-white flex flex-wrap items-center justify-around'>

                        {/* keep w below 50% */}
                        <div className='w-full xl:w-[48%]'>

                            <div className='font-medium text-center whitespace-nowrap text-sm lg:text-base xl:text-left px-2'>256 Items in mobile accessories</div>

                        </div>


                        {/* Dropdown */}
                        <div className='w-full xl:w-[48%] '>

                            <div className='flex items-center'>

                                <Button
                                    onClick={() => setToggleFilterSidebar(true)}
                                    className='px-2 lg:hidden'
                                >
                                    <BiSolidCategory className='text-2xl' />
                                </Button>

                                <div className=' ml-auto lg:mx-auto xl:ml-auto xl:mr-0 gap-3 flex items-center justify-center'>
                                    <div className="px-4 py-0.5 w-fit flex items-center justify-center border border-primary rounded-full overflow-hidden">

                                        <label htmlFor="sortId123" className='text-xs md:text-sm whitespace-nowrap'>Sort By:</label>

                                        <Dropdown
                                            onChange={(e) => setSelectCategory(e.target.value)}
                                            className={'bg-white text-sm md:text-base py-1 pl-2 h-full hover:cursor-pointer'}
                                            id={"sortId123"}
                                            name={'sortOptions'}
                                            value={selectCategory}
                                            options={sortOptions}
                                        />

                                    </div>

                                    <div className=' flex items-center justify-center'>

                                        <Button
                                            className={`${gridOverList ? "bg-[#F3F2FF] text-secondary" : "bg-transparent text-black"} rounded-md p-2`}
                                            onClick={() => setGridOverList(true)}
                                        >
                                            <IoGridSharp className='text-xl bg-teal-50' />
                                        </Button>

                                        <Button
                                            className={`${!gridOverList ? "bg-[#F3F2FF] text-secondary" : "bg-transparent text-black"} rounded-md p-2`}
                                            onClick={() => setGridOverList(false)}
                                        >
                                            <FaThList className='text-xl bg-teal-50' />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div>

                        {gridOverList ?
                            <div className='grid gap-3 md:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(190px,1fr))]'>
                                {filteredProducts.map((items) => (
                                    <Listing
                                        key={items.id}
                                        {...items}
                                        image={items.image}
                                        wishHeart={items.wishHeart}
                                    />

                                ))}
                            </div>

                            :

                            <div className='grid gap-4 md:gap-6 grid-cols-1'>
                                {filteredProducts.map((items) => (
                                    <ListingList
                                        key={items.id}
                                        {...items}
                                        image={items.image}
                                        wishHeart={items.wishHeart}
                                    />

                                ))}
                            </div>
                        }

                    </div>



                    {/* Page change */}
                    <div className='my-8 p-5 lg:py-6 gap-6 rounded-md border border-primary bg-white flex flex-col flex-wrap md:flex-row justify-center xl:justify-between items-center'>

                        <div className='flex items-center flex-wrap gap-2'>
                            <div className='text-xs md:text-sm'>Results:</div>
                            <div className='text-xs md:text-sm font-medium'>1 - 100 of 9,999</div>
                        </div>

                        <div className='flex gap-3 items-center justify-center'>
                            <Button
                                className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                            >
                                <FaAngleLeft className='text-xs md:text-sm' />
                                <div className='text-sm'>Previous</div>
                            </Button>

                            <div className='text-sm'>extra button from arrayPage</div>

                            <Button
                                className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                            >
                                <div className='text-sm'>Next</div>
                                <FaAngleRight className='text-xs md:text-sm' />
                            </Button>
                        </div>

                    </div>
                </div>

            </div>
        </>)
}

export default ProductListing

/**

Home / :id(category)

pull id = cat in category page;

Home / cat / :id(product id)

 */

/*
    brand data comes from backend in array;
    we show 5 and lock others in see all.
*/