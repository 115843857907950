import React, { useState } from 'react'
import { latestBlogImg2 } from '../../../assets/index'

const LatestBlog = ({ className, date, image, title }) => {

    const [imageLoaded, setImageLoaded] = useState(false);


    return (
        <div className={`${className} flex items-center justify-center gap-3 hover:cursor-pointer`}>

            <div className='w-1/3 aspect-[13/10] rounded-md overflow-hidden'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={latestBlogImg2} alt='loading' />
                    </>
                ) : (
                    <img className='object-cover object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>

            <div className='w-2/3 flex flex-col items-start flex-grow justify-between gap-2'>
                <div className='text-black line-clamp-2 text-sm font-medium'>{title}</div>
                <div className='text-[#70747D] text-sm'>{date}</div>
            </div>

        </div>
    )
}

export default LatestBlog