import React from 'react'

const Modal = ({
    title,
    contents,
    className,
    cartLength = null
}) => {

    let right
    if (className === "arrow") { right = "-right-10" }
    else if (className === "arrow-edge") { right = "right-1" }


    return (
        <div
            className={`${className} ${right} z-40 absolute top-8 mt-4 bg-white text-black border min-w-[200px] lg:min-w-[330px]`}
        >

            <div className='font-medium text-base text-left pl-4 p-3 md:p-5 w-full border-b border-primary'>
                {title} {cartLength && `(${cartLength})`}
            </div>

            <div>
                {contents}
            </div>

        </div>)
}

export default Modal
