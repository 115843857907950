import React from 'react'
import { Button } from '../index'

import { earpodsBlack } from '../../assets/index'
import { BsArrowRight } from "react-icons/bs";


const Recommended = () => {
    return (
        <div className='h-full flex flex-col gap-3 md:gap-6'>

            <div className='max-h-fit py-8 px-4 flex items-center justify-center flex-col gap-y-5 rounded bg-[#F3DE6D]'>

                <div>
                    <img src={earpodsBlack} className='w-full h-full' alt="ad" />
                </div>

                <h4 className='font-semibold text-center text-lg xl:text-xl'>Xiaomi True Wireless Earbuds</h4>

                <div className='text-[#475156] text-center text-xs xl:text-sm '>Escape the noise, It’s time to hear the magic with Xiaomi Earbuds.</div>

                <div className='flex whitespace-nowrap items-center justify-center gap-2'>
                    <div className='text-[#475156] text-xs xl:text-sm'>Only for:</div>
                    <h6 className='bg-[#FA8232] rounded px-3 py-2 xl: text-sm xl:text-base text-white font-semibold'>%299 USD</h6>
                </div>

                <Button
                    className={'mt-2 rounded-xl bg-white flex justify-center items-center gap-2 py-2 w-[80%] xl:py-4 '}
                >
                    <p className='text-[#191C1F] whitespace-nowrap text-base'>Shop Now</p>
                    <BsArrowRight className='text-[#191C1F] text-lg xl:text-xl' />
                </Button>
            </div>


            <div className=' max-h-fit py-8 xl:py-12 px-4 flex items-center justify-center flex-col gap-y-5 rounded bg-[#124261]'>

                <div className='px-4 py-2 text-sm xl:text-base bg-[#2E5974] text-white'>SUMMER SALES</div>
                <h3 className='font-semibold text-white text-center text-lg xl:text-2xl'>37% DISCOUNT</h3>
                <div className='text-center text-white text-base px-2 xl:px-0 xl:w-[80%]'>
                    only for
                    <span className='text-[#EBC80C]'> SmartPhone </span>
                    product.
                </div>

                <Button
                    className={'mt-4 rounded-xl bg-[#2DA5F3] flex justify-center items-center gap-2 py-2 w-[80%] xl:py-4'}
                >
                    <p className='text-white whitespace-nowrap text-base'>Shop Now</p>
                    <BsArrowRight className='text-white text-lg xl:text-xl' />
                </Button>
            </div>

        </div>
    )
}

export default Recommended