import React from 'react'
import {
    chatAnswer,
    chatQuestion
} from '../../../../assets/index'

const ProductQuestions = ({
    question,
    asker,
    askerImg,
    answererImg,
    date,
    answer,
    answerer,
    answerDate
}) => {
    return (
        <div className='px-6 py-5 flex gap-5 flex-col items-start justify-center w-full'>

            <div className='space-y-2'>

                <div className='flex items-center justify-start gap-2'>
                    <div className='w-7 aspect-square'>
                        <img src={chatQuestion} className='w-full h-full object-contain' alt="" />
                    </div>
                    <div className='font-medium text-sm'>Question</div>
                </div>

                <div className='text-sm'>{question}</div>

                <div className='flex items-center justify-start gap-2'>

                    <div className='w-8 aspect-square rounded-full shadow-inner bg-gray-200'>
                        <img src={askerImg} className='rounded-full w-full h-full object-contain object-center' alt="" />
                    </div>
                    <div className='text-[#70747D] text-xs'>{asker} - {date}</div>
                </div>

            </div>

            <div className='space-y-2'>

                <div className='flex items-center justify-start gap-2'>
                    <div className='w-7 aspect-square'>
                        <img src={chatAnswer} className='w-full h-full object-contain' alt="" />
                    </div>
                    <div className='font-medium text-sm'>Answer</div>
                </div>

                <div className='text-sm'>{answer}</div>

                <div className='flex items-center justify-start gap-2'>

                    <div className='w-8 aspect-square rounded-full shadow-inner bg-gray-200'>
                        <img src={answererImg} className='rounded-full w-full h-full object-contain object-center' alt="" />
                    </div>
                    <div className='text-[#70747D] text-xs'>{answerer} - {answerDate}</div>
                </div>

            </div>

        </div>
    )
}

export default ProductQuestions
/*
        {
            "question": "Is it registered on Mdms?",
            "asker": "Alex B.",
            "askerImg":
            "answererImg":
            "date": "21 Apr, 2022",
            "answer": "Yes, all iPhones are registered in mdms.",
            "answerer": "iPhone Store",
            "answerDate": "answered within 1 hour"
        },
*/