import React from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  OtpVerification,
  Home,
  FlashSale,
  NewArrival,
  LatestOffer,
  About,
  HelpAndSupport,
  BecomeASeller,
  ReturnAndRefund,
  RefundPolicy,
  ReturnAProduct,
  ReturnPolicy,
  Blogs,
  ProductDetails,
  ProductListing,
  Cart,
  NotFound
} from './pages/index'

import {
  Footer,
  TagFooter,
  MainHeader,
  Sidebar,
  BreadCrumbs
} from './components/index'

import AuthLayout from './layouts/AuthLayout'

const App = () => {
  return (

    <div className="flex flex-col min-h-screen">

      <Sidebar />

      <Routes>

        <Route path='/auth' element={<AuthLayout />}>
          <Route path='register' element={<Register />} />
          <Route path='login' element={<Login />} />
          <Route path='forgotpassword' element={<ForgotPassword />} />
          <Route path='resetpassword' element={<ResetPassword />} />
          <Route path='otp' element={<OtpVerification />} />
        </Route>

        <Route element={<MainHeader />}>
          <Route element={<TagFooter />}>

            <Route index element={<Home />} />

            <Route element={<BreadCrumbs />}>
              <Route path="flashsale" element={<FlashSale />} />
              <Route path="new-arrival" element={<NewArrival />} />
              <Route path="latest-offer" element={<LatestOffer />} />
              <Route path="help-&-support" element={<HelpAndSupport />} />
              <Route path="about-classicommerce" element={<About />} />
              <Route path="become-a-seller" element={<BecomeASeller />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="my-cart" element={<Cart />} />

              <Route path="/return-and-refund" element={<ReturnAndRefund />}>
                <Route path="how-to-return-a-product" element={<ReturnAProduct />} />
                <Route path="return-policy" element={<ReturnPolicy />} />
                <Route path="refund-policy" element={<RefundPolicy />} />
              </Route>

              <Route path="product-listing" element={<ProductListing />} />
              {/* SET PROPER ROUTE*/}
              <Route path="category/:id" element={<ProductDetails />} />

            </Route>

            <Route path="*" element={<NotFound />} />

          </Route>
        </Route>

      </Routes>

      <div className='mt-auto'>
        <Footer />
      </div>

    </div>
  )
}

export default App


/* <Route path='/' element={<MainHeader />}>

  <Route element={<TagFooter />}>

    <Route index element={<Home />} />

    <Route element={<BreadCrumbs />}>
      <Route path='flashsale' element={<FlashSale />} />
    </Route>
  </Route>

  <Route path='*' element={<Component />} />
</Route> */