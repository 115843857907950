import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop'

import { Button } from '../index'
import { IoStar } from "react-icons/io5";
import { IoIosHeartEmpty } from "react-icons/io";


const ListingList = ({
    id,
    className,
    discountOffer,
    dealType,
    title,
    review,
    discountCost,
    cost,
    image,
    desc,
    wishHeart
}) => {

    const navigate = useNavigate()

    const [imageLoaded, setImageLoaded] = useState(false);


    let dealColor
    if (dealType === "Deal of the Day") { dealColor = '#C71720' }
    else if (dealType === "Best Deals") { dealColor = '#0072E3' }
    else if (dealType === "Sale") { dealColor = '#219653' }
    else { dealColor = "#4EB4F6" }

    return (

        <div
            onClick={() => {
                navigate(`/${id}`)
                scrollToTop()
            }}
            className={`${className} relative py-2 group hover:cursor-pointer hover:shadow md:hover:shadow-xl duration-300 flex rounded-xl border border-primary bg-white`}
        >

            {discountOffer &&
                <div className='z-10 p-3 lg:py-3 lg:px-4 absolute top-0 left-0 bg-[#DB2736] text-white w-fit rounded-tl-xl rounded-br-xl flex flex-col items-center justify-center'>
                    <p className='text-xs md:text-sm'>{discountOffer}%</p>
                    <p className='text-xs md:text-sm'>OFF</p>
                </div>
            }

            {dealType &&
                <div
                    style={{ backgroundColor: dealColor }}
                    className={` z-10 py-2 px-3 lg:px-4 absolute bottom-0 right-0 text-white w-fit rounded-tl-xl rounded-br-xl flex flex-col items-center justify-center`}
                >
                    <p className='text-xs md:text-sm'>{dealType}</p>
                </div>
            }

            <Button
                // onClick={}   //change wishHeart boolean in backend. add a delay/disable after each click to avoid backend overload
                className={'z-10 p-2 bg-white absolute top-0 right-0 -translate-x-2 translate-y-2 rounded-full border border-primary'}
            >
                {/* find a heart svg with stroke and fill property */}
                <IoIosHeartEmpty className='text-black text-lg md:text-xl' />
            </Button>

            <div className='w-1/3 lg:w-[30%] border-b border-primary'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='p-8 object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img className='p-2 md:px-6 md:py-4 lg:px-8 lg:py-6 group-hover:scale-105 duration-500 ease-in-out object-contain object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>

            <div className='w-2/3 lg:w-[70%] px-2 py-6 lg:py-8 space-y-1 lg:space-y-2'>
                {/* w to avoid overlapping with wishHeart */}
                <div className='w-[85%] md:w-[90%] group-hover:text-primary-hover line-clamp-2 text-left text-sm md:text-base text-black'>{title}</div>

                <div className='text-[#70747D] line-clamp-2 text-left text-xs md:text-sm'>{desc}</div>

                <div className='mt-1 flex items-center gap-1'>
                    {/* Wrap all stars in a button and keep track of the rating */}
                    <IoStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <IoStar className='text-xs md:text-lg text-gray-300 hover:cursor-pointer' />
                    <div className='inline-block text-xs lg:text-sm'>({review})</div>
                </div>

                <div className='mt-2 flex items-end gap-1'>
                    <h5 className=' text-xl md:text-2xl font-semibold '>${discountCost}</h5>
                    <div className='text-sm md:text-base text-gray-400 line-through '>${cost}</div>
                </div>


            </div>

        </div>
    )
}

export default ListingList