import React from 'react'
import { Button } from '../../../index'
import { CiLocationOn } from "react-icons/ci";
import { FaAngleRight } from "react-icons/fa6";

const LocationCon = () => {

    const langData = { id: 1, location: "Sydney, Australia" }

    return (
        <div className='flex flex-col items-start justify-center gap-1 p-4 md:px-6 md:py-5'>

            <div className='flex items-center gap-x-1 md:gap-x-3'>
                <CiLocationOn className='text-secondary text-2xl' />
                <div className='whitespace-nowrap text-sm'>{langData.location}</div>
            </div>

            <Button
                // onClick={}
                className={"text-secondary flex gap-2 items-center justify-center"}
            >
                <p className='ml-7 md:ml-12 text-sm'>Change</p>
                <FaAngleRight className='text-xs' />
            </Button>

        </div>)
}

export default LocationCon