import React from "react";
import { ErrorMessage, Field } from "formik";
import { TextError } from "../index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordField = ({
    label,
    placeholder,
    name,
    setShowPassword,
    showPassword,
    ...rest
}) => {
    return (
        <div className="text-sm">
            <label
                htmlFor={name}
                className="pb-2 text-primary flex text-sm items-center justify-between"
            >
                {label} <ErrorMessage name={name} component={TextError} />
            </label>

            <div className="flex justify-between items-center relative">
                <Field
                    id={name}
                    name={name}
                    autoComplete={'current-password'}
                    {...rest}
                    className="border border-gray-300 w-full px-3 py-3 text-sm rounded-lg outline-gray-300"
                    placeholder={placeholder}
                />
                <div
                    className="absolute right-4 opacity-40"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? (
                        <AiFillEyeInvisible size={20} />
                    ) : (
                        <AiFillEye size={20} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PasswordField;
