import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { logInValidationSchema } from '../ValidationSchema'
import NoAccount from '../NoAccount'
import { useNavigate, Link } from 'react-router-dom'
import {
    Button,
    PasswordField,
    TextField
} from '../../../components/index'


const Login = () => {

    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: "",
        password: ""
    }

    const onFormSubmit = (val, action) => {
        console.log(val)
        action.resetForm()
    }


    return (
        <div>

            <div className='flex flex-col shadow-sm w-[95%] my-12 mx-auto bg-white border border-primary rounded-xl
            md:flex-row lg:w-[75%]'>

                <div className='w-full p-5 pb-10 lg:p-10 md:w-full:'>

                    <div className='flex items-center justify-start gap-3 flex-wrap'>
                        <h3 className='text-xl md:text-2xl font-semibold'>Log into you account</h3>
                        <div className='hidden md:block border-l border-primary h-10'></div>
                        <div className='text-sm'>RequiredFields *</div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={onFormSubmit}
                        validationSchema={logInValidationSchema}
                    >
                        {() => (
                            <Form>

                                <div className='mt-12'>
                                    <TextField
                                        label="Email Address"
                                        name="email"
                                        placeholder="Enter your email"
                                        type="email"
                                        autoComplete={'username'}
                                    />
                                </div>

                                <div className='mt-5'>
                                    <PasswordField
                                        label="Password"
                                        name="password"
                                        placeholder="Enter Your Password"
                                        type={`${showPassword ? "text" : "password"}`}
                                        setShowPassword={setShowPassword}
                                        showPassword={showPassword}
                                    />
                                </div>




                                <Link to={"/auth/forgotpassword"} className='w-fit block'>
                                    <p className="text-sm font-semibold my-4 w-fit">
                                        Forgot Password?
                                    </p>
                                </Link>

                                <p className='mt-8 text-sm text-[#70747D]'>By proceding, you agree to our</p>





                                <div className='flex flex-wrap mt-2 gap-3 justify-start items-center md:gap-x-6 '>
                                    <Link>
                                        <p className="inline-block whitespace-nowrap text-sm font-semibold text-primary hover:text-primary-hover hover:underline">
                                            Terms of Service
                                        </p>
                                    </Link>

                                    <div className='border-l border-primary h-8'></div>

                                    <Link>
                                        <p className="inline-block whitespace-nowrap text-sm font-semibold text-primary hover:text-primary-hover hover:underline">
                                            Privacy Policies
                                        </p>
                                    </Link>

                                    <div className='border-l border-primary h-8'></div>

                                    <Link>
                                        <p className="inline-block whitespace-nowrap text-sm font-semibold text-primary hover:text-primary-hover hover:underline">
                                            Content Policies
                                        </p>
                                    </Link>
                                </div>

                                <Button
                                    className='mt-8 rounded-lg text-white w-full px-10 py-3 bg-primary'
                                    type='submit'
                                    label={'Sign in'}
                                />
                            </Form>
                        )}

                    </Formik>

                </div>

                <NoAccount
                    label={'Register'}
                    account={`Don't have an account?`}
                    navLink={"register"}
                />

            </div>

        </div>
    )
}

export default Login