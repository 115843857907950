import React, { useState } from 'react'
import { IoMdClose } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";

import { Button } from '../../index'

const BlackFriday = () => {

    const [isClose, setIsClose] = useState(false)

    return (
        <section className={` ${isClose ? "hidden" : "flex"} bg-[#191C1F] w-full flex-col items-center justify-between gap-x-3 p-2 `}>

            <div className='w-full flex justify-between items-center p-2'>
                <div className='w-full flex justify-between items-center lg:px-20 md:px-10'>

                    <div className='flex gap-1 items-center md:gap-3'>
                        <div className='bg-[#F3DE6D] -rotate-3 py-1 px-2 md:py-2 md:px-4'>
                            <h3 className='text-[#191C1F] font-semibold text-sm lg:text-xl '>
                                Black
                            </h3>
                        </div>
                        <h3 className='text-white font-semibold text-sm lg:text-xl '>
                            Friday
                        </h3>
                    </div>


                    <div className='hidden md:flex items-center text-white font-semibold gap-1 text-base lg:text-xl md:gap-2'>
                        Up to <span className='text-[#EBC80C] font-semibold text-2xl lg:text-4xl'>59%</span> OFF
                    </div>


                    <Button
                        className={'hidden rounded bg-white py-1 px-2 md:flex justify-center items-center gap-2 lg:py-2 lg:px-4'}
                    >
                        <p className='text-[#191C1F] text-sm whitespace-nowrap'>Shop Now</p>
                        <FaArrowRight className='text-[#191C1F] text-sm' />
                    </Button>
                </div>




                <div>
                    <Button
                        onClick={() => setIsClose(true)}
                        className={'rounded bg-[#303639] p-1 lg:p-2'}
                    >
                        <IoMdClose className='text-white text-base' />
                    </Button>
                </div>

            </div>

            <div className='w-full md:hidden flex items-center justify-between gap-3 p-2 md:p-6'>

                <div className=' md:hidden flex items-center text-white font-semibold gap-1 text-base'>
                    Up to <span className='text-[#EBC80C] font-semibold text-xl'>59%</span> OFF
                </div>


                <Button
                    className={' rounded bg-white py-1 px-2 md:hidden flex justify-center items-center gap-2'}
                >
                    <p className='text-[#191C1F] px-2 py-1 text-sm whitespace-nowrap '>Shop Now</p>
                    <FaArrowRight className='text-[#191C1F] text-sm' />
                </Button>
            </div>

        </section>
    )
}

export default BlackFriday