import React, { useState } from 'react'

const LatestOfferCard = ({ className, title, image }) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`${className} group flex flex-col items-center justify-start gap-y-2 xl:gap-y-5`}>

            <div className='w-full aspect-[14/9] rounded-md overflow-hidden'>
                {!imageLoaded ? (
                    <>
                        <img
                            className='hidden'
                            onLoad={() => setImageLoaded(true)}
                            src={image}
                            alt='items'
                        />
                        <img className='object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                    </>
                ) : (
                    <img className='group-hover:scale-110 duration-300 ease-in-out object-cover object-center w-full h-full' src={image} alt={'items'} />
                )}
            </div>
            <div className='flex flex-col items-start flex-grow justify-between gap-1 md:gap-3'>
                <div className='font-medium line-clamp-2 text-sm'>{title}</div>
                <div className='text-xs text-[#70747D]'>Limited time offer. Check it out.</div>
            </div>

        </div>
    )
}

export default LatestOfferCard