import React from 'react'
import { Formik, Form } from 'formik'
import { forgotPasswordValidationSchema } from '../ValidationSchema'
import { Link, useNavigate } from 'react-router-dom'
import {
    Button,
    Otp
} from '../../../components/index'


const OtpVerification = () => {

    const navigate = useNavigate()

    const initialValues = {
        otp: ""
    }

    const onFormSubmit = (val, action) => {
        console.log(val)
        action.resetForm()
    }


    return (
        <div>

            <div className='flex flex-col items-center justify-center shadow-sm w-[95%] my-12 py-12 px-4 mx-auto bg-white border border-primary rounded-xl
            md:px-14 md:w-[75%] lg:w-[55%] xl:w-[45%]'>


                <h3 className='text-2xl mx-auto md:text-2xl font-bold'>OTP Verification!</h3>
                <p className='mt-2 text-center text-sm text-[#474D59] md:text-base'>
                    We have sent you a text message with 6-digit verification code to
                    <span className='font-semibold'> johndoe@gmail.com</span>
                </p>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onFormSubmit}
                    validationSchema={forgotPasswordValidationSchema}
                >
                    {() => (
                        <Form className='w-full'>

                            <div className='mt-12'>
                                <Otp />
                            </div>


                            <Button
                                onClick={() => {
                                    //AFTER CORRECT VERIFICATION
                                    navigate("/auth/resetpassword")
                                }}

                                className='mt-12 text-base rounded-lg text-white w-full px-10 py-3 bg-primary'
                                type='submit'
                                label={'Verify Code'}
                            />

                            <Button
                                className='mt-2 text-base rounded-lg text-secondary w-full px-10 py-3 bg-white'
                                type='submit'
                                label={'Resend OTP'}
                            />
                        </Form>
                    )}
                </Formik>

                <div>
                    <p className='mt-6 text-center text-base text-[#70747D]'>
                        Didn&#8217;t receive the code?
                    </p>

                    <Link to={'/'}>
                        <p className='mt-2 text-center font-semibold text-sm text-secondary hover:text-[#276E3E] hover:underline'>
                            Use another email address
                        </p>
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default OtpVerification