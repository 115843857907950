import React from 'react'
import { Button } from '../../../index'
import { useNavigate } from 'react-router-dom';
import { MdArrowRightAlt } from "react-icons/md";
import { IoMdClose } from "react-icons/io";


const ShopCartCon = ({ setIsCartActive }) => {

    const navigate = useNavigate()

    const cartData = [
        {
            "id": 1,
            "title": "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
            "price": 109.95,
            "description": "Your perfect pack for everyday use and walks in the forest. Stash your laptop (up to 15 inches) in the padded sleeve, your everyday",
            "category": "men's clothing",
            "image": "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
            "quantity": 2,
            "rating": {
                "rate": 3.9,
                "count": 120
            }
        },
        {
            "id": 2,
            "title": "Mens Casual Premium Slim Fit T-Shirts ",
            "price": 22.3,
            "description": "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
            "category": "men's clothing",
            "image": "https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg",
            "quantity": 1,
            "rating": {
                "rate": 4.1,
                "count": 259
            }
        }
    ]

    return (
        <div className='md:gap-5'>

            <div className='w-full flex flex-col justify-center items-start gap-3 p-4 md:px-6 md:py-5 border-b border-primary'>

                {cartData?.map((items) => (

                    <div
                        key={items.id}
                        className='flex items-start justify-between gap-3'
                    >

                        <div className='p-2 w-16 md:w-20 aspect-square border border-primary flex-shrink-0'>
                            <img src={items.image} className='block aspect-square object-contain w-full h-full' alt="" />
                        </div>

                        <div className='flex flex-col gap-1 md:gap-3 items-start justify-start'>
                            <p className='text-sm line-clamp-2 min-w-[150px] md:min-w-[200px]'>{items.title}</p>

                            <div className='flex items-center justify-start gap-x-1'>
                                <p className='text-xs md:text-sm text-[#70747D]'>{items.quantity} X</p>
                                <p className='font-medium text-sm md:text-base'>${items.price}</p>
                            </div>
                        </div>


                        <Button
                            className='ml-auto px-1 md:px-2'
                        >
                            <IoMdClose className='text-lg md:text-2xl text-[#9B9EA4]' />
                        </Button>
                    </div>
                ))}

            </div>

            <div className='w-full p-4 md:px-6 flex items-center justify-between gap-1'>
                <div className='text-[#475156] text-xs md:text-sm whitespace-nowrap'>Sub Total</div>
                <div className='font-[500] text-sm md:text-base whitespace-nowrap'>
                    $9,999.99 AUD
                    {/* TOTAL COST */}
                </div>
            </div>

            <div className='w-full flex flex-col items-center justify-center gap-y-4 p-4 md:px-6 md:py-5 border-t border-primary'>
                <Button
                    className='flex items-center justify-center gap-2 whitespace-nowrap rounded-lg text-white text-sm w-full px-5 py-2 md:px-10 md:py-4 bg-primary border border-secondary'
                    label={'Checkout Now'}
                >
                    <MdArrowRightAlt className='text-lg' />
                </Button>

                <Button
                    onClick={() => {
                        navigate("my-cart")
                        setIsCartActive(false)
                    }}

                    className='whitespace-nowrap rounded-md text-primary-hover text-sm w-full px-5 py-2 md:px-10 md:py-3 border border-secondary bg-white'
                    label={'View Cart'}
                />
            </div>
        </div>
    )
}

export default ShopCartCon