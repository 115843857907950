import React from 'react'

import {
    Button,
    Timer,
    Listing
} from '../../components/index'

import { smartPhone } from '../../assets/index'
import { BsLightningChargeFill } from "react-icons/bs";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";



const FlashSale = () => {

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    const featuredProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
    ];

    return (
        <div className={`${sectionPad} mt-5 mb-10 md:mt-10`}>

            <div className='my-4 md:my-8 flex gap-2 items-center justify-center'>
                <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
                <h1 className='text-primary font-semibold text-xl md:text-3xl'>FLASH SALES</h1>
                <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
            </div>

            <div className='mb-8 flex flex-col items-center text-xl gap-2'>
                <div className='text-sm md:text-lg'>Deals ends in</div>
                <Timer className={"text-base lg:text-lg"} />
            </div>

            <div className='grid md:gap-6 gap-3  grid-cols-[repeat(auto-fill,minmax(165px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'>
                {featuredProducts.map((items) => (
                    <Listing
                        key={items.id}
                        {...items}
                        image={items.image}
                        wishHeart={items.wishHeart}
                    />

                ))}
            </div>

            <div className='my-8 p-5 lg:py-6 gap-4 rounded-md border border-primary bg-white flex flex-col flex-wrap md:flex-row justify-center xl:justify-between items-center'>

                <div className='flex items-center flex-wrap gap-2'>
                    <div className='text-xs md:text-base'>Displaying results:</div>
                    <div className='text-xs md:text-base font-medium'>1 - DispalyArr.length of totalCount</div>
                </div>

                <div className='flex gap-3 items-center justify-center'>
                    <Button
                        className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                    >
                        <FaAngleLeft className='text-xs md:text-base' />
                        <div className='text-sm md:text-sm'>Previous</div>
                    </Button>

                    <div className='text-xs'>extra button from arrayPage</div>

                    <Button
                        className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                    >
                        <div className='text-sm md:text-base'>Next</div>
                        <FaAngleRight className='text-xs md:text-sm' />
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default FlashSale
