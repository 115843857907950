import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CiLocationOn } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";
import { BsPhone } from "react-icons/bs";

import { isSidebarOpen } from '../../../redux/index'
import { Button, Modal, Overlay } from '../../index';
import LocationCon from './ModalContents/LocationCon'


const MainNav = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isLocationMenuActive, setIsLocationMenuActive] = useState(false)

    const langData = { id: 1, location: "Sydney, Australia", label: "AUS" }

    const navMenuList = [
        { id: 1, link: "/", label: "HOME" },
        { id: 2, link: "/flashsale", label: "FLASH SALE" },
        { id: 3, link: "/", label: "HOT OFFERS" },
        { id: 4, link: "/become-a-seller", label: "BECOME A SELLER" },
        { id: 5, link: "/help-&-support", label: "HELP & SUPPORT" }
    ]


    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <>
            <section className='bg-primary'>

                <div className={`${sectionPad} px-5 md:px-0 py-2 md:py-3 flex flex-wrap gap-y-2 items-center justify-between md:justify-around lg:justify-between`}>

                    <div className='flex justify-center items-center text-white w-fit'>

                        <Button
                            onClick={() => dispatch(isSidebarOpen())}
                            className={""}
                        >
                            <RxHamburgerMenu className='text-xl xl:text-2xl' />
                        </Button>

                        <div className='mx-3 flex justify-center items-center gap-x-6'>
                            {navMenuList.map((list) => (

                                <Button
                                    key={list.id}
                                    onClick={() => navigate(`${list.link}`)}
                                    className={"hidden md:block whitespace-nowrap text-sm "}
                                    label={list.label}
                                />
                            ))}

                        </div>

                    </div>



                    <div className='flex flex-wrap items-center justify-between gap-3 text-white w-fit'>

                        <div className='flex items-center justify-end gap-x-2'>
                            <BsPhone className='text-white text-base md:text-lg' />

                            {/* DOWNLOAD LINK OR BUTTON? */}
                            <p className='text-sm'>DOWNLOAD THE APP</p>
                        </div>

                        <div className='border-l border-primary h-6'></div>


                        <div className='relative text-white'>

                            <Button
                                onClick={() => setIsLocationMenuActive(true)}
                                className={"rounded-md py-2 flex justify-center items-center gap-x-1 md:gap-x-3"}
                            >
                                <CiLocationOn className='text-white text-base md:text-lg' />
                                <div className='text-sm'>{langData.label}</div>
                                <FaAngleDown className='text-sm' />
                            </Button>

                            {isLocationMenuActive &&
                                <Modal
                                    title={"Location"}
                                    className={"arrow-edge"}
                                    contents={<LocationCon />}
                                />
                            }

                        </div>

                    </div>

                </div>

            </section>

            {isLocationMenuActive && (
                <Overlay onClick={() => setIsLocationMenuActive(false)} />
            )}

        </>
    )
}

export default MainNav