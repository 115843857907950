import React from 'react'
import { useNavigate } from 'react-router-dom';

import {
    Button,
    MainCarousel,
    CarouselCon,
    LatestOfferCard,
    RewardSchemeCard
} from '../../components/index'

import {
    carouselSpeaker,
    latestOfferimg1,
    latestOfferimg2,
    latestOfferimg3,
    latestOfferimg4,
    creditCard,
    appstore,
    googleplay
} from '../../assets/index'

const LatestOffer = () => {

    const latestOfferProducts = [
        {
            id: 1,
            title: 'Save upto $90 on Wireless Airbuds Pro 3 (True Wireless)',
            offer: 'Save upto $90',
            image: latestOfferimg1,
        },
        {
            id: 2,
            title: 'The future of smart health is on your wrist.',
            image: latestOfferimg2,
        },
        {
            id: 3,
            title: 'Get Stunning shots wherever you go with Dji Mini 4',
            image: latestOfferimg3,
        },
        {
            id: 4,
            title: 'AMD Robo S1 Intelligent Education Robot',
            image: latestOfferimg4,
        },
        {
            id: 5,
            title: 'The future of smart health is on your wrist.',
            image: latestOfferimg2,
        },
        {
            id: 6,
            title: 'Save upto $90 on Wireless Airbuds Pro 3 (True Wireless)',
            offer: 'Save upto $90',
            image: latestOfferimg1,
        },
        {
            id: 7,
            title: 'AMD Robo S1 Intelligent Education Robot',
            image: latestOfferimg4,
        },
        {
            id: 8,
            title: 'Get Stunning shots wherever you go with Dji Mini 4',
            image: latestOfferimg3,
        },
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div>

            <MainCarousel
                inactiveButton="text-[#07090B]"
                contents={[
                    <CarouselCon
                        key={1}
                        backgroundIMG={"bg-black text-white"}
                        slideText={"LATEST OFFER"}
                        slideTextCss={"text-white lg:text-xl font-[100]"}
                        title={"Enhance Your Music Experience"}
                        ButtonCss={"bg-primary text-white"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='w-full h-full'>
                                <img src={carouselSpeaker} className='carousel-radial w-full h-full md:p-20' alt="" />
                            </div>
                        }
                    />,
                    <CarouselCon
                        key={2}
                        backgroundIMG={"bg-black text-white"}
                        slideText={"LATEST OFFER"}
                        slideTextCss={"text-white xl:text-2xl font-[100]"}
                        title={"Enhance Your Music Experience"}
                        ButtonCss={"bg-primary text-white"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='w-full h-full'>
                                <img src={carouselSpeaker} className='carousel-radial w-full h-full md:p-20' alt="" />
                            </div>
                        } />,
                    <CarouselCon
                        key={3}
                        backgroundIMG={"bg-black text-white"}
                        slideText={"LATEST OFFER"}
                        slideTextCss={"text-white xl:text-2xl font-[100]"}
                        title={"Enhance Your Music Experience"}
                        ButtonCss={"bg-primary text-white"}
                        ButtonLabel={"Learn More"}
                        rightContent={
                            <div className='w-full h-full'>
                                <img src={carouselSpeaker} className='carousel-radial w-full h-full md:p-20' alt="" />
                            </div>
                        }
                    />
                ]}
            />


            <div className={`${sectionPad} border-b border-primary py-4 md:py-8`}>
                <h2 className='my-10 font-semibold text-xl md:text-3xl'>Latest Offers</h2>

                <div className='grid gap-3 md:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'>

                    {latestOfferProducts.map((items) => (
                        <LatestOfferCard
                            key={items.id}
                            title={items.title}
                            image={items.image}
                        />

                    ))}
                </div>

                <div className='w-full flex items-center justify-center'>
                    <Button
                        className='mt-7 xl:mt-10 whitespace-nowrap rounded-md text-secondary text-sm md:text-base px-14 py-2 md:px-20 lg:px-32 md:py-3 border border-secondary bg-white'
                        label={"Load More"}
                    >
                    </Button>
                </div>



                {/* Reward Scheme */}

                <RewardSchemeCard />

            </div>

        </div>
    )
}

export default LatestOffer