import React from 'react'

const PhoneAndTablets = () => {
    return (
        <ol className='text-justify md:text-left text-black text-sm list-none space-y-4'>
            <li>Phones, Tablets, Batteries & Chargers, Earphones & Headsets, Mobile & Tablet Accessories</li>
            <li>Change of Mind is not applicable for return and refund.</li>
            <li>If the item received is damaged, defective, incorrect, or incomplete, a refund will be issued based on Daraz's assessment.</li>
            <li>Note: We do not accept returns for any used items. For mobile phones, you may raise a return request directly with Daraz if the device is dead on arrival (i.e. does not switch on completely). If the mobile phone has already been activated*, please contact the seller or brand warranty provider directly for information regarding the manufacturer's warranty.</li>
            <li>*Mobile phone is considered activated once SIM card has been inserted or when the phone has been connected to the internet via Wi-Fi.</li>
            <li>Daraz Like New Phones</li>
            <li>Change of mind is not applicable for return and refund.</li>
            <li>If the item received does not work at all after being delivered, is damaged, defective, incorrect, or incomplete; refund will be issued based on the assessment conducted by Daraz.</li>
            <li>All Daraz Like New items are covered under 3 to 6 Months Warranty as per the details mentioned in the Product Page.</li>
            <li>Any item that costs less than NPR 45,000 will have 3 Months Warranty.</li>
            <li>Any item that costs NPR 45,000 or more will have 6 Months Warranty.</li>
            <li>Please contact Daraz Customer Service for returns and refunds via Live Chat (App or Web).</li>
        </ol>)
}

export default PhoneAndTablets