import React from 'react'
import { Button } from '../../../index'


const AccLogOutCon = () => {
    return (
        <div className='flex flex-col items-center gap-3 md:gap-5 p-4 md:w-[360px] md:px-6 md:py-5'>

            <Button
                className='rounded-lg text-white text-sm w-full px-5 py-2 md:px-10 md:py-3 bg-primary'
                label={'Sign in'}
            />

            <div className=" flex justify-center items-center w-full">
                <hr className="w-full border" />
                <p className="w-full mx-2 whitespace-nowrap text-center text-sm text-[#70747D]">New to Classicommerce</p>
                <hr className="w-full border" />
            </div>

            <Button
                className='whitespace-nowrap rounded-md text-primary-hover text-sm w-full px-5 py-2 md:px-10 md:py-3 border border-secondary bg-white'
                label={'Create Account'}
            />
        </div>
    )
}

export default AccLogOutCon