import React, { useState } from 'react'
import {
    ItemsCarousel,
    MainCarousel,
    OfferCard,
    BestDealCard,
    Timer,
    Listing,
    NewArrivalCard,
    LatestOfferCard,
    RewardSchemeCard,
    Button,
    FeatureAndRecommend,
    Featured,
    Recommended,
    AdCard,
    BlogCard,
    SubscribeToNews
} from '../../components/index'

import {
    headphone,
    bestDeal,
    smartPhone,
    ps5,
    womenCollection,
    speakers,
    perfume,
    latestOfferimg1,
    latestOfferimg2,
    latestOfferimg3,
    latestOfferimg4,
    blogImg1,
    blogImg2,
    blogImg3,
    blogImg4,
    laptop,
    apple,
    xiaomi
} from '../../assets/index'

import { BsLightningChargeFill } from "react-icons/bs";
import { GoArrowUpRight } from "react-icons/go";
// import { FaArrowRight } from "react-icons/fa6";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';


const Home = () => {

    const navigate = useNavigate()

    const recommendButtonLabels = ["All Products", "Smartphone", "Laptop", "Headphone", "TV"]
    const [recommendActive, setRecommendActive] = useState(recommendButtonLabels[0])
    const [featuredActive, setFeaturedActive] = useState(recommendButtonLabels[0])

    const mainCarouselContent = []

    const category1 = [
        { id: 1, onClick: (e) => console.log('works', e.target), label: "Art & Collection" },
        { id: 2, onClick: (e) => console.log('works', e.target), label: "Automotive" },
        { id: 3, onClick: (e) => console.log('works', e.target), label: "B2B Supplier" },
        { id: 4, onClick: (e) => console.log('works', e.target), label: "Business Services" },
        { id: 5, onClick: (e) => console.log('works', e.target), label: "Buy & Sell" },
        { id: 6, onClick: (e) => console.log('works', e.target), label: "Cars" },
        { id: 7, onClick: (e) => console.log('works', e.target), label: "Community" },
        { id: 8, onClick: (e) => console.log('works', e.target), label: "Toys And Games" }
    ];

    const offerCard = [
        { id: 1, title: 'Electronics', salesText: '30% Sale', image: headphone, background: "#56CCF2" },
        { id: 2, title: 'Toys and Games', salesText: 'Save 30%', image: headphone, background: "#56CCF2" },
        { id: 3, title: 'Women Accessories', salesText: 'New Arrival', image: headphone, background: "#56CCF2" },
        { id: 4, title: 'Furnitures', salesText: '30% Sale', image: headphone, background: "#56CCF2" }
    ];

    const bestDealCategory = [
        { id: 1, image: headphone, title: 'Luxury Chairs' },
        { id: 2, image: headphone, title: 'Smartphones' },
        { id: 3, image: headphone, title: 'Gaming' },
        { id: 4, image: headphone, title: 'Smart Watches' },
        { id: 5, image: headphone, title: 'Kitchen Mixer' },
        { id: 6, image: headphone, title: 'Soft Chairs' },
        { id: 7, image: headphone, title: 'Sofa & Chair' },
        { id: 8, image: headphone, title: 'Kitchen Dishes' },
        { id: 9, image: headphone, title: 'Fashion Bags' },
        { id: 10, image: headphone, title: 'Purses' }
    ];


    const featuredProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
    ];

    const blogPosts = [
        {
            id: 1,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg1,
        },
        {
            id: 2,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 3,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 4,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg4,
        },
        {
            id: 5,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 6,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg3,
        },
        {
            id: 7,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg1,
        },
        {
            id: 8,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg4,
        },
        {
            id: 9,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg3,
        },
        {
            id: 10,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
    ];

    const latestOfferProducts = [
        {
            id: 1,
            title: 'Save upto $90 on Wireless Airbuds Pro 3 (True Wireless)',
            offer: 'Save upto $90',
            image: latestOfferimg1,
        },
        {
            id: 2,
            title: 'The future of smart health is on your wrist.',
            image: latestOfferimg2,
        },
        {
            id: 3,
            title: 'Get Stunning shots wherever you go with Dji Mini 4',
            image: latestOfferimg3,
        },
        {
            id: 4,
            title: 'AMD Robo S1 Intelligent Education Robot',
            image: latestOfferimg4,
        },
        {
            id: 5,
            title: 'The future of smart health is on your wrist.',
            image: latestOfferimg2,
        },
        {
            id: 6,
            title: 'Save upto $90 on Wireless Airbuds Pro 3 (True Wireless)',
            offer: 'Save upto $90',
            image: latestOfferimg1,
        },
        {
            id: 7,
            title: 'AMD Robo S1 Intelligent Education Robot',
            image: latestOfferimg4,
        },
        {
            id: 8,
            title: 'Get Stunning shots wherever you go with Dji Mini 4',
            image: latestOfferimg3,
        },
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div>

            <section className={`${sectionPad} `}>
                <div className='bg-white'>
                    {/* <div className="w-full flex p-2">

                    <div>
                        navigation
                    </div>

                    <MainCarousel
                        images={carouselImage}
                    />
                </div> */}
                </div>


                <div className='grid mt-10 gap-x-2 gap-y-3 lg:gap-x-6 grid-cols-[repeat(auto-fill,minmax(150px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(240px,1fr))] '>
                    {offerCard.map((offer) => (

                        <OfferCard
                            key={offer.id}
                            title={offer.title}
                            salesText={offer.salesText}
                            image={offer.image}
                            background={"#56CCF2"}
                        />
                    ))}
                </div>

            </section>



            {/* Best Deal For You */}
            <section className={`${sectionPad} mt-20`}>

                <div className='rounded-2xl divide-x divide-y overflow-hidden'>
                    <div className='relative'>
                        <img className='opacity-60 object-cover object-center w-full h-24 md:h-full' src={bestDeal} alt="Best Deal" />
                        <h2 className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg md:text-3xl font-semibold'>Best Deals For You</h2>
                    </div>


                    <div className='grid grid-cols-[repeat(auto-fill,minmax(170px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
                        {bestDealCategory.map((offer) => (
                            <BestDealCard
                                key={offer.id}
                                title={offer.title}
                                image={offer.image}
                            />
                        ))}
                    </div>
                </div>

            </section>



            <div className=' my-8 md:my-16 w-full border-t border-primary' ></div>



            {/* Flash Sales */}
            <section className={`${sectionPad}`}>

                <div className='my-8 flex gap-2 items-center justify-center'>
                    <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
                    <h1 className='text-primary font-semibold text-xl md:text-3xl'>FLASH SALES</h1>
                    <BsLightningChargeFill className='text-[#F2C94C] text-2xl md:text-4xl' />
                </div>



                <div className='flex flex-wrap items-center justify-center gap-y-3 md:gap-y-5 md:justify-between'>
                    <div className='flex flex-wrap items-center text-xl gap-2'>
                        <div className='text-sm md:text-base'>Deals ends in</div>
                        <Timer className={"text-sm md:text-base"} />
                    </div>

                    <Link to={'/flashsale'} onClick={scrollToTop}>
                        <div className='flex items-center text-base gap-2'>
                            <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>Browse all Deals</div>
                            <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                        </div>
                    </Link>
                </div>



                <div className='mt-8 md:mt-12 '>
                    <ItemsCarousel
                        contents={featuredProducts.map((items) => (
                            <Listing
                                key={items.id}
                                className={"mx-3 my-6"}
                                {...items}
                                image={items.image}
                                wishHeart={items.wishHeart}
                            />
                        ))}
                    />
                </div>

            </section>



            <div className='my-10 md:my-16 w-full border-t border-primary' ></div>


            {/* New Arrival */}
            <section className={`${sectionPad}`}>

                <div className='flex pb-12 items-center justify-between'>

                    <div className='font-semibold text-xl md:text-3xl'>New Arrival</div>

                    <Link to={'/new-arrival'} onClick={scrollToTop}>
                        <div className='flex items-center text-base gap-2'>
                            <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>See all Deals</div>
                            <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                        </div>
                    </Link>
                </div>

                <div className='flex flex-col md:flex-row items-center justify-center gap-3 xl:gap-6'>

                    <NewArrivalCard
                        image={ps5}
                        title={"PlayStation 5"}
                        description={"Black and White version of the PS5 coming out for sale"}
                        className={"w-full md:w-1/2"}
                        titleCss={"text-xl xl:text-3xl"}
                        descCss={"text-sm xl:text-base"}
                    />

                    <div className='w-full md:w-1/2 h-full flex flex-col justify-between items-center gap-3 xl:gap-6'>

                        <NewArrivalCard
                            image={womenCollection}
                            title={"Women's Collections"}
                            description={"Featured women collection available now"}
                            className={"w-full h-1/2"}
                            titleCss={"text-lg xl:text-2xl"}
                            descCss={"text-sm xl:text-base"}
                        />

                        <div className='h-1/2 w-full flex items-center justify-between gap-3 xl:gap-6'>

                            <NewArrivalCard
                                image={speakers}
                                title={"Speakers"}
                                description={"Amazing wireless speaker"}
                                className={"w-1/2"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />


                            <NewArrivalCard
                                image={perfume}
                                title={"Perfume"}
                                description={"Premium Perfume"}
                                className={"w-1/2"}
                                titleCss={"text-base xl:text-xl"}
                                descCss={"text-xs xl:text-sm"}
                            />

                        </div>
                    </div>
                </div>

            </section>



            <div className='mt-10 md:mt-16 w-full border-t border-primary' ></div>


            {/* Featured Products */}
            <section className={`${sectionPad}`}>

                <FeatureAndRecommend
                    title={"Featured Products"}
                    buttonLabels={recommendButtonLabels}
                    productArray={featuredProducts}
                    activeButton={featuredActive}
                    setActiveButton={setFeaturedActive}
                    productCss={"order-2"}
                    adContentCss={"order-1"}
                    adContents={<Featured />}
                    linkContent={
                        <Link to={'/new-arrival'} onClick={scrollToTop}>
                            <div className='flex items-center text-base gap-2'>
                                <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>Browse all Deals</div>
                                <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                            </div>
                        </Link>
                    }
                />

            </section>


            {/* Ad card */}
            <section className={`${sectionPad} w-full mt-8 flex flex-col lg:flex-row gap-3 xl:gap-6 `}>

                <AdCard
                    className={"bg-[#191C1F]"}
                    announcement={"INTRODUCING NEW"}
                    announcementCss={"bg-[#EFD33D] text-black"}
                    title={"Xiaomi Mi 11 Ultra 12GB+256GB"}
                    titleCss={"text-white"}
                    description={"*Data provided by internal laboratories. Industry measurment."}
                    descriptionCss={"text-[#ADB7BC]"}
                    buttonCss={"bg-white text-black"}
                    imageContent={
                        <div className='absolute bottom-0'>
                            <div className='relative w-full h-full'>
                                <div className='max-w-[12rem] lg:max-w-[15rem]'>
                                    <img src={xiaomi} className='w-full h-full' alt="ad" />
                                </div>
                                <div className='p-2 xl:p-4 absolute top-0 right-0 -translate-x-2 rounded-full w-fit aspect-square text-white z-[5] bg-[#2DA5F3] flex items-center justify-center font-semibold 
                                    text-xs md:text-sm xl:text-xl'>
                                    $590
                                </div>
                            </div>
                        </div>
                    }
                />

                <AdCard
                    className={"bg-[#F2F4F5]"}
                    announcement={"INTRODUCING"}
                    announcementCss={"bg-[#2DA5F3] text-white"}
                    title={"New Apple Homepod Mini"}
                    titleCss={"text-black"}
                    description={"Jam-packed with innovation, HomePod mini delivers unexpectedly."}
                    descriptionCss={"text-[#475156]"}
                    buttonCss={"bg-primary text-white"}
                    imageContent={
                        <div className='absolute top-1/2 -translate-y-1/2'>
                            <div className='max-w-[12rem] lg:max-w-[15rem]'>
                                <img src={apple} className='w-full h-full' alt="ad" />
                            </div>
                        </div>
                    }
                />

            </section>


            <div className='mt-10 md:mt-16 w-full border-t border-primary' ></div>


            {/* Recommended for you */}
            <section className={`${sectionPad}`}>

                <FeatureAndRecommend
                    title={"Recommended for you"}
                    buttonLabels={recommendButtonLabels}
                    productArray={featuredProducts}
                    activeButton={recommendActive}
                    setActiveButton={setRecommendActive}
                    productCss={"order-1"}
                    adContentCss={"order-2"}
                    adContents={<Recommended />}
                    linkContent={
                        <Link to={'/new-arrival'} onClick={scrollToTop}>
                            <div className='flex items-center text-base gap-2'>
                                <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>Browse all Deals</div>
                                <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                            </div>
                        </Link>
                    }
                />

            </section>




            {/* Mac book pro */}
            <section className={`${sectionPad}`}>

                <div className={`bg-[#EEF7FF] py-5 w-full flex items-center justify-center`} >

                    <div className='w-1/2 mr-auto'>

                        <div className='w-[85%] mx-auto md:ml-auto md:mr-0 h-full flex flex-col items-start justify-center gap-y-1 md:gap-y-2 lg:gap-y-3 xl:gap-y-5'>
                            <div className={`text-xs lg:text-sm xl:text-base bg-[#2DA5F3] text-white p-1 md:px-3 md:py-2 font-medium`}>SAVE UP TO $200.00</div>
                            <h1 className='text-base lg:text-3xl line-clamp-2 font-semibold'>Macbook Pro</h1>
                            <div className='text-xs line-clamp-2 md:text-sm lg:text-base xl:text-xl'>Apple M1 Max Chip. 32GB Unified Memory, 1TB SSD Storage</div>

                            <Button
                                className={'mt-3 rounded-lg bg-[#2DA5F3] text-white flex justify-center items-center gap-2 py-2 px-3 lg:px-7 lg:py-3 xl:px-10 xl:py-4 '}
                            >
                                <p className='whitespace-nowrap text-xs lg:text-base'>Shop Now</p>
                                <BsArrowRight className=' text-base lg:text-xl' />
                            </Button>
                        </div>
                    </div>

                    <div className='w-1/2 ml-auto'>
                        <div className='relative w-full h-full'>
                            <img src={laptop} className='w-full h-full' alt="" />
                            <div className='border-4 lg:border-8 p-1 md:p-2 lg:p-4 xl:p-6 absolute top-0 translate-y-1/4 md:translate-y-1/3 left-0 rounded-full w-fit aspect-square text-white z-[5] border-[#F3F2FF] bg-[#2DA5F3] flex items-center justify-center font-semibold 
                            text-xs md:text-base xl:text-xl'>
                                $1999
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            <div className='my-10 md:my-16 w-full border-t border-primary' ></div>


            {/* Latest Offer */}
            <section className={`${sectionPad}`}>

                <div className='flex pb-5 md:pb-6 items-center justify-between'>

                    <div className='font-semibold text-xl md:text-3xl'>Latest Offer</div>

                    <Link to={'/latest-offer'} onClick={scrollToTop}>
                        <div className='flex items-center text-base gap-2'>
                            <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>See all Offers</div>
                            <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                        </div>
                    </Link>
                </div>


                <div>
                    <ItemsCarousel
                        contents={latestOfferProducts.map((items) => (
                            <LatestOfferCard
                                key={items.id}
                                className={"mx-3 my-6"}
                                title={items.title}
                                image={items.image}
                            />

                        ))}
                    />
                </div>


                {/* Reward Scheme */}
                <RewardSchemeCard />


            </section>


            <div className='my-10 md:my-16 w-full border-t border-primary' ></div>


            {/* Latest News */}
            <section className={`${sectionPad} mb-16`}>

                <div className='flex pb-5 md:pb-6 items-center justify-between'>

                    <div className='font-semibold text-xl md:text-3xl'>Latest News</div>

                    <Link to={'/blogs'} onClick={scrollToTop}>
                        <div className='flex items-center text-base gap-2'>
                            <div className='text-sm md:text-base text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>See all Offers</div>
                            <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                        </div>
                    </Link>
                </div>


                <div className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>
                    {blogPosts.slice(0, 3).map((blog) => (

                        <BlogCard
                            key={blog.id}
                            date={blog.date}
                            title={blog.title}
                            desc={blog.desc}
                            image={blog.image}
                        />
                    ))}

                </div>


            </section>


            {/* Suscribe to News letter */}
            <SubscribeToNews />

        </div>
    )
}

export default Home