import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='overflow-hidden'>
            <div className='relative h-[80vh] w-screen'>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-2'>
                    <h1 className='font-semibold text-lg'>Page Not found</h1>
                    <Link to={"/"}>
                        <h2 className='hover:text-secondary hover:underline'>Retun to Home</h2>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound