import React, { useState } from 'react'

import {
    Button,
    FeatureAndRecommend,
    Avatar,
    Recommended,
    SubscribeToNews
} from '../../components/index'

import {
    aboutMain,
    face1,
    face2,
    face3,
    trustedShop,
    smartPhone,
} from '../../assets/index'

import { LuCheckCheck } from "react-icons/lu";
import { RiPlayCircleFill } from "react-icons/ri";


const About = () => {

    const recommendButtonLabels = ["All Products", "Smartphone", "Laptop", "Headphone", "TV"]
    const [recommendActive, setRecommendActive] = useState(recommendButtonLabels[0])

    const ourTeam = [
        { id: 1, image: face1, name: "Floyd Miles", position: "Chief Executive Officer" },
        { id: 2, image: face2, name: "Jerome Bell", position: "Assistant of CEO" },
        { id: 3, image: face3, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 4, image: face1, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 5, image: face2, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 6, image: face1, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 7, image: face1, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 8, image: face3, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 9, image: face1, name: "Kathryn Murphy", position: "Head of Designer" },
        { id: 10, image: face3, name: "Kathryn Murphy", position: "Head of Designer" },
    ]

    const featuredProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            wishHeart: true
        },
    ];


    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"


    return (
        <div>

            <div className='bg-white flex border-b border-primary'>

                <div className={`w-full lg:w-[60%] px-2 md:px-16 lg:px-24 xl:px-36 py-16 md:py-10 xl:py-16 bg-white flex flex-col items-start justify-center gap-y-6 lg:gap-y-4`}>

                    <div className='mx-auto lg:mx-0 font-medium py-2 px-4 rounded-sm bg-primary text-xs lg:text-sm text-white'>WHO WE ARE</div>

                    <h1 className='text-center lg:text-left font-semibold text-xl md:text-3xl md:leading-normal w-fit text-black leading-normal'>
                        Classicommerce - Online eCommerce Platform
                    </h1>

                    <p className='text-justify leading-relaxed lg:leading-normal lg:text-left text-[#474D59] text-sm'>Lorem ipsum dolor sit amet consectetur. Tellus pulvinar augue erat urna eget lectus sollicitudin nulla. Viverra etiam ut neque tincidunt vitae pretium nunc magnis. Erat ultrices consequat felis elit adipiscing volutpat in tellus eu. Mauris placerat at vestibulum sapien. Dui aliquam sagittis sed ante non semper gravida.</p>


                    <div className='ml-4 lg:ml-0 flex items-center justify-start gap-x-3'>
                        <LuCheckCheck className='text-[#2DB224] text-sm md:text-xl' />
                        <div className='text-sm md:text-base text-black'>Great 24/7 customer services.</div>
                    </div>
                    <div className='ml-4 lg:ml-0 flex items-center justify-start gap-x-3'>
                        <LuCheckCheck className='text-[#2DB224] text-sm md:text-xl' />
                        <div className='text-sm md:text-base text-black'>600+ Dedicated employe.</div>
                    </div>
                    <div className='ml-4 lg:ml-0 flex items-center justify-start gap-x-3'>
                        <LuCheckCheck className='text-[#2DB224] text-sm md:text-xl' />
                        <div className='text-sm md:text-base text-black'>50+ Branches all over the world.</div>
                    </div>
                    <div className='ml-4 lg:ml-0 flex items-center justify-start gap-x-3'>
                        <LuCheckCheck className='text-[#2DB224] text-sm md:text-xl' />
                        <div className='text-sm md:text-base text-black'>Over 1 Million Electronics Products</div>
                    </div>

                </div>


                <div className='w-[40%] hidden lg:block'>
                    <img src={aboutMain} className='w-full h-full' alt="about" />
                </div>

            </div>


            {/* Our Team */}
            <section className={`${sectionPad} py-10 pb-20 bg-white`}>
                <h1 className='font-semibold text-center text-xl md:text-3xl mb-10 md:mb-16'>Our Team</h1>

                <div className='grid gap-3 md:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'>
                    {ourTeam.map((team) => (
                        <Avatar
                            key={team.id}
                            image={team.image}
                            name={team.name}
                            position={team.position}
                        />
                    ))}
                </div>
            </section>


            {/* trusted online shop */}
            <div
                style={{
                    backgroundImage: `url("${trustedShop}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                className='relative'
            >

                <div className='z-[5] w-full h-full absolute bg-gradient-to-t from-customBlack from from-10% to-transparent'></div>

                <div className='w-full px-2 md:px-0 mx-auto md:w-3/5 flex flex-col items-center justify-center py-32 lg:py-40 gap-4 lg:gap-5 xl:gap-7'>
                    <div className='z-10 text-white text-center font-semibold text-2xl md:text-3xl xl:text-4xl '>Your trusted online shop</div>
                    <div className='z-10 text-white text-center text-sm xl:text-lg'>Praesent sed semper metus. Nunc aliquet dolor mauris, et fringilla elit gravida eget. Nunc consequat auctor urna a placerat.</div>

                    <Button
                        // onClick={ }
                        className={'z-10 rounded-full bg-white flex justify-center items-center gap-2 py-2 pl-2 pr-4 '}
                    >
                        <RiPlayCircleFill className='text-secondary text-3xl lg:text-4xl' />
                        <div className='font-medium text-secondary text-sm lg:text-base'>Watch the Video</div>
                    </Button>
                </div>
            </div>

            <section className={`${sectionPad}`}>

                <FeatureAndRecommend
                    title={"Recommended For You"}
                    buttonLabels={recommendButtonLabels}
                    productArray={featuredProducts}
                    activeButton={recommendActive}
                    setActiveButton={setRecommendActive}
                    productCss={"order-1"}
                    adContentCss={"order-2"}
                    adContents={<Recommended />}
                />

            </section>


            {/* Suscribe to News letter */}
            <SubscribeToNews />

        </div>
    )
}

export default About