import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop';

import {
    ItemsCarousel,
    YouMayLikeCard,
    MainCarousel,
    OfferCard,
    BestDealCard,
    Timer,
    Listing,
    Checkbox,
    Button,
    Input,
    FeatureAndRecommend,
    Featured,
    Recommended,
    AdCard,
    BlogCard,
    SubscribeToNews
} from '../../components/index'
import CartEmpty from './CartEmpty'

import {
    smartPhone,
    productImg4,
    productImg5,
    removeGiantImg,
    saveChangesImg,
    updateCartImg,
    starTick
} from '../../assets/index'

import { BsLightningChargeFill } from "react-icons/bs";
import { GoArrowLeft, GoArrowUpRight, GoArrowRight } from "react-icons/go";
import { LuMinus } from "react-icons/lu";
import { IoAdd } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";


const CartLoggedIn = () => {

    const youMayLikeProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg5,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg4,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
    ];

    const cartItemsData = [
        {
            id: 1,
            productName: "Apple MacBook Air Laptop M1 chip 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage, Backlit Keyboard, FaceTime HD Camera, Touch ID. Works with iPhone/iPad; Silver",
            productImage: productImg5,
            color: "Silver",
            size: "256GB",
            currentPrice: 1599.99,
            originalPrice: 1999.99,
            discount: "21% OFF",
            options: {
                color: "Silver",
                size: "256 GB",
            },
            quantity: 3,
            status: "In Stock",
            totalQuantity: 12
        },
        {
            id: 2,
            productName: "Apple iPhone 13",
            productImage: productImg4,
            color: "Silver",
            size: "128GB",
            currentPrice: 1599.99,
            originalPrice: 1999.99,
            discount: "21% OFF",
            options: {
                color: "Blue",
                size: "128 GB",
            },
            quantity: 2,
            status: "In Stock",
            totalQuantity: 5
        },
    ];


    const carouselProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg5,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg4,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
    ];



    const [activeUpdateCart, setActiveUpdateCart] = useState(false)
    const [cartItems, setCartItems] = useState(cartItemsData.map(product => ({ ...product, isChecked: false })));
    const [debounceTimeout, setDebounceTimeout] = useState({});
    const [isSelectAll, setIsSelectAll] = useState(false)

    const totalCost = cartItems.reduce((acc, item) => (acc + item.currentPrice * item.quantity), 0).toFixed(2)

    const handleCheckboxChange = (productId) => {
        const updatedCart = cartItems.map(item =>
            item.id === productId ? { ...item, isChecked: !item.isChecked } : item
        );
        setCartItems(updatedCart);
    };

    const handleDelete = () => {
        const updatedCart = cartItems.filter(item => !item.isChecked);
        setCartItems(updatedCart);
    };

    const selectAll = () => {

        const updatedCart = cartItems.filter(item => {
            if (isSelectAll) { return item.isChecked = true }
            else { return item.isChecked = false }
        })

        setCartItems(updatedCart);
    }


    const debounceAction = (id, updateFunc) => {
        if (debounceTimeout[id]) {
            clearTimeout(debounceTimeout[id]);
        }
        const timeout = setTimeout(() => {
            updateFunc();
            setDebounceTimeout({ ...debounceTimeout, [id]: null });
        }, 500);

        setDebounceTimeout({ ...debounceTimeout, [id]: timeout });
    };

    const isButtonDisabled = (id) => {
        return !!debounceTimeout[id];
    };

    const decreaseQuantity = (id) => {
        debounceAction(id, () => {
            const updatedCartData = cartItems.map((item) => {
                if (item.id === id && item.quantity > 1) {
                    return { ...item, quantity: item.quantity - 1 };
                }
                return item;
            });

            setCartItems(updatedCartData);
        });
    };


    const increaseQuantity = (id) => {
        debounceAction(id, () => {
            const updatedCartData = cartItems.map((item) => {
                if (item.id === id) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            });

            setCartItems(updatedCartData);
        });
    };


    const handleQuantityChange = (event, id) => {
        const newQuantity = parseInt(event.target.value, 10); // Parse to integer
        if (!isNaN(newQuantity)) {
            const updatedCartData = cartItems.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: newQuantity,
                    };
                }
                return item;
            });
            setCartItems(updatedCartData);
        }
    };

    const handleQuantityBlur = (id, newQuantity) => {
        if (!isNaN(newQuantity)) {
            const updatedCartData = cartItems.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        quantity: Math.min(Math.max(newQuantity, 1), item.totalQuantity),
                    };
                }
                return item;
            });
            setCartItems(updatedCartData);
        }
    };


    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <>
            {cartItems.length > 0 ?
                <>
                    <div className={`${sectionPad}`}>

                        <div className='flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                            <div className='h-fit w-full lg:w-2/3 bg-white rounded-md border border-primary divide-y'>

                                <div className='px-6 py-4 flex flex-wrap gap-y-2 items-center justify-around xl:justify-between'>

                                    <div className='flex items-center justify-center gap-2'>

                                        {activeUpdateCart &&
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setIsSelectAll(!isSelectAll)
                                                        selectAll()
                                                    }}
                                                    className={`${isSelectAll ? "bg-primary" : "bg-white"} border rounded w-[22px] aspect-square`}
                                                />
                                            </>
                                        }

                                        <div className='md:mr-auto text-lg text-black font-semibold'>Shopping Cart</div>
                                    </div>

                                    {!activeUpdateCart ? (
                                        <Button
                                            onClick={() => setActiveUpdateCart(true)}
                                            className={"py-2 px-3 text-sm flex items-center justify-center gap-2"}
                                        >
                                            <div className='w-4'>
                                                <img src={updateCartImg} className='w-full h-full object-contain' alt="" />
                                            </div>
                                            <div className='whitespace-nowrap'>Update Cart</div>
                                        </Button>

                                    ) : (
                                        <Button
                                            onClick={() => setActiveUpdateCart(false)}
                                            className={"py-2 px-3 text-sm flex items-center justify-center gap-2"}
                                        >
                                            <div className='w-4'>
                                                <img src={saveChangesImg} className='w-full h-full object-contain' alt="" />
                                            </div>
                                            <div className='whitespace-nowrap'>Save Changes</div>
                                        </Button>
                                    )}
                                </div>

                                <div className='divide-y'>
                                    {cartItems?.map((items) => (

                                        <label
                                            key={`${items.id}`}
                                            htmlFor={`${items.id}cart`}
                                            className="px-2 md:px-6 flex justify-start items-start gap-1 md:gap-3 py-4"
                                        >

                                            <div className='w-1/4'>
                                                <div className='relative max-w-[10.5625rem] aspect-square'>
                                                    <img src={items.productImage} className='w-full h-full object-contain md:p-1' alt="" />

                                                    {activeUpdateCart &&
                                                        <Checkbox
                                                            id={`${items.id}cart`}
                                                            className={"absolute top-0 left-0"}
                                                            type={'checkbox'}
                                                            value={items.label}
                                                            name={items.label}
                                                            checked={items.isChecked}
                                                            onChange={() => handleCheckboxChange(items.id)}
                                                        />
                                                    }
                                                </div>
                                            </div>


                                            <div className='w-3/4'>

                                                <div className='space-y-2 relative'>

                                                    <div className='text-sm'>{items.productName}</div>

                                                    <div className='flex items-center flex-wrap justify-start gap-4 pr-2'>

                                                        <div className='flex items-end gap-1'>
                                                            <h5 className=' text-lg font-semibold '>${items.currentPrice}</h5>
                                                            <div className='text-sm text-gray-400 line-through '>${items.originalPrice}</div>
                                                        </div>

                                                        {items.discount &&
                                                            <div className='py-1 px-2 bg-[#C71720] text-white w-fit rounded'>
                                                                <p className='text-xs'>{items.discount}</p>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className='space-y-1'>
                                                        <div className='text-[#70747D] text-xs'>Color:&nbsp;
                                                            <span className='text-sm text-black font-medium'>{items.color}</span>
                                                        </div>

                                                        <div className='text-[#70747D] text-xs'>Size:&nbsp;
                                                            <span className='text-sm text-black font-medium'>{items.size}</span>
                                                        </div>
                                                    </div>

                                                    <div className='flex items-center justify-start gap-2'>
                                                        <div className=' flex justify-start items-center gap-2'>
                                                            <div className='w-4 aspect-square'>
                                                                <img src={starTick} className='w-full h-full' alt="" />
                                                            </div>
                                                            <div className='whitespace-nowrap text-[#219653] text-sm'>In Stock</div>
                                                        </div>

                                                        {activeUpdateCart && (
                                                            <>
                                                                <div className='border-l border-primary h-5'></div>

                                                                <Button
                                                                    className={"gap-1 flex items-center justify-center w-fit"}
                                                                >
                                                                    <CiCircleRemove className='text-red-600 text-xl' />
                                                                    <div className='text-sm'>Remove</div>
                                                                </Button>

                                                            </>
                                                        )}
                                                    </div>

                                                    <div className='static md:absolute bottom-0 right-0 w-fit flex items-center justify-center border rounded-md'>

                                                        <Button
                                                            disabled={items.quantity <= 1 || isButtonDisabled(items.id)}
                                                            className={"p-2"}
                                                            onClick={() => decreaseQuantity(items.id)}
                                                        >
                                                            <LuMinus />
                                                        </Button>

                                                        <Input
                                                            min={1}
                                                            max={items.totalQuantity}
                                                            value={items.quantity}
                                                            onChange={(e) => handleQuantityChange(e, items.id)}
                                                            onBlur={(e) => handleQuantityBlur(items.id, parseInt(e.target.value, 10))}
                                                            onKeyPress={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleQuantityBlur(items.id, parseInt(e.target.value, 10));
                                                                }
                                                            }}
                                                            className={`w-8 pl-0 text-center`}
                                                            type="number"
                                                            inputMode="numeric"
                                                        />

                                                        <Button
                                                            disabled={items.quantity >= items.totalQuantity || isButtonDisabled(items.id)} className={"p-2"}
                                                            onClick={() => increaseQuantity(items.id)}                                                        >
                                                            <IoAdd />
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>

                                        </label>

                                    ))}

                                    <div className='px-2 md:px-6 py-4 flex flex-col items-end justify-center gap-2'>
                                        <div className='text-base whitespace-nowrap'>Sub Total ({cartItems.length} Items) :&nbsp;
                                            <span className='font-semibold text-xl'>${totalCost}</span>
                                        </div>
                                        <div className='text-[#70747D] text-sm'>Taxes and shipping calculated at checkout</div>
                                    </div>

                                    <div className='px-2 md:px-6 py-4 flex justify-between items-center gap-2'>

                                        {activeUpdateCart ?
                                            <>
                                                <Button
                                                    onClick={() => setActiveUpdateCart(false)}
                                                    className={"flex items-center justify-center w-fit gap-2"}
                                                >
                                                    <div className='w-5 aspect-square'>
                                                        <img src={removeGiantImg} alt="" />
                                                    </div>
                                                    <div className='text-sm'>Cancel Changes</div>
                                                </Button>

                                                <Button
                                                    onClick={handleDelete}
                                                    className={"flex text-white items-center justify-center w-fit gap-2 py-3 px-4 bg-[#C71720] rounded-md"}
                                                >
                                                    <FaRegTrashAlt className='text-white' />
                                                    <div className='text-sm'>Remove Item</div>
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button
                                                    // onClick={""}
                                                    className={"flex text-black items-center justify-center w-fit gap-2"}
                                                >
                                                    <GoArrowLeft />
                                                    <div className='text-sm'>Continue Shopping</div>
                                                </Button>

                                                <Button
                                                    // onClick={}
                                                    className={"flex text-white items-center justify-center w-fit gap-2 py-3 px-4 bg-primary rounded-md"}
                                                >
                                                    <div className='text-sm'>Proceed to Checkout</div>
                                                    <GoArrowRight />
                                                </Button>
                                            </>
                                        }
                                    </div>
                                </div>

                            </div>





                            <div className='w-full lg:w-1/3'>

                                {/* You may also like */}
                                <div className='w-full border bg-white border-primary rounded-md divide-y'>
                                    <div className='px-6 py-4 text-lg text-black font-semibold'>You may also Like</div>

                                    <div className='px-4 py-6 md:p-6 lg:px-3 lg:py-4 xl:p-6 gap-6 grid grid-cols-1 md:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-1'>
                                        {youMayLikeProducts.map((prod) => (

                                            <YouMayLikeCard
                                                key={prod.id}
                                                title={prod.title}
                                                image={prod.image}
                                                {...prod}
                                            />
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>




                    <div className='my-10 md:my-16 w-full border-t border-primary' ></div>

                    <section className={`${sectionPad} mb-10`}>

                        <div className='flex pb-5 md:pb-6 flex-wrap items-center justify-center md:justify-start gap-6'>

                            <div className='font-semibold text-base md:text-xl'>People who viewed this item also viewed</div>

                            <Link to={'/latest-offer'} onClick={scrollToTop}>
                                <div className='flex items-center text-base gap-2'>
                                    <div className='text-xs md:text-sm text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>See More</div>
                                    <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                                </div>
                            </Link>
                        </div>


                        <div>
                            <ItemsCarousel
                                contents={carouselProducts.map((items) => (
                                    <Listing
                                        key={items.id}
                                        className={"mx-3 my-6"}
                                        {...items}
                                        image={items.image}
                                        wishHeart={items.wishHeart}
                                    />
                                ))}
                            />
                        </div>

                    </section>
                </>

                : <CartEmpty />
            }
        </>
    )
}

export default CartLoggedIn