import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'

import {
    ItemsCarousel,
    Listing,
    Button,
    Input,
    Table,
    YouMayLikeCard,
    Dropdown
} from '../../../components/index'
import { getStarsFromRating } from '../../../utils/getStarsFromRating'
import { scrollToTop } from '../../../utils/scrollToTop';
import ProductQuestions from './ProductQuestions/ProductQuestions'
import ReviewComments from './ReviewComments/ReviewComments'

import {
    smartPhone,
    productImg1,
    productImg2,
    productImg3,
    productImg4,
    productImg5,
    starTick,
    starTickWhite,
    truckFast,
    medalStar,
    location,
    messages,
    serviceCreditCard,
    serviceHandshake,
    serviceHeadphones,
    serviceMedal,
    serviceTruck,
    commentImg1,
    commentImg2
} from '../../../assets/index'

import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import { IoIosStar } from "react-icons/io";
import { LuMinus } from "react-icons/lu";
import { IoAdd } from "react-icons/io5";
import { GoShareAndroid } from "react-icons/go";
import { CiHeart } from "react-icons/ci";
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { FaRegThumbsUp } from "react-icons/fa";
import { CiCircleAlert } from "react-icons/ci";

const ProductDetails = () => {

    const { id } = useParams()

    //Api call to get the details of the specific product
    const specificProductDetails = {
        "product": {
            "name": "Apple iPhone 14 Pro",
            "brand": "Apple",
            "model": "iPhone 14 Pro",
            "color": "Graphite",
            "storage": "256GB",
            "display": {
                "size": "6.7 inches",
                "type": "Super Retina XDR display",
                "resolution": "2778 x 1284 pixels"
            },
            "camera": {
                "main": {
                    "lens": "Triple",
                    "specs": ["12 MP, f/1.5, 26mm (wide)", "12 MP, f/2.0, 65mm (telephoto)", "12 MP, f/1.8, 120˚ (ultrawide)"],
                    "features": ["Night mode", "Deep Fusion", "Smart HDR 4"]
                },
                "front": {
                    "specs": "12 MP, f/2.2",
                    "features": ["Night mode", "Deep Fusion", "HDR"]
                }
            },
            "processor": "A16 Bionic",
            "battery": "Built-in rechargeable lithium-ion battery",
            "connectivity": ["5G capable", "Wi‑Fi 6 (802.11ax) with 2x2 MIMO", "Bluetooth 5.2"],
            "dimensions": {
                "height": "160.8 mm",
                "width": "78.1 mm",
                "depth": "7.4 mm"
            },
            "weight": "189 grams",
            "operatingSystem": "iOS 16",
            "price": 1299.99,
            "availability": "In stock",
            "seller": {
                "name": "ElectroTech",
                "rating": 4.8,
                "reviews": 2356
            },
            "images": [
                productImg1,
                productImg2,
                productImg3,
                productImg4,
                productImg5,
                smartPhone
            ]
        }
    }


    const carouselProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg5,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg4,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
    ];


    const youMayLikeProducts = [
        {
            id: 1,
            discountOffer: '50',
            dealType: 'Deal of the Day',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg5,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 2,
            discountOffer: '50',
            dealType: 'Best Deals',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: productImg4,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 3,
            discountOffer: '50',
            dealType: 'Sale',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: false
        },
        {
            id: 4,
            // discountOffer: '50',
            // dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
        {
            id: 5,
            discountOffer: '10',
            dealType: 'Best Deal',
            title: 'GoPro HERO6 4K Action Camera - Black',
            review: '24',
            discountCost: '99.50',
            cost: '110.99',
            image: smartPhone,
            rating: 4.8,
            wishHeart: true
        },
    ];

    const specificationTable = [
        { td1: "Display", td2: "6.1-inch (15.5 cm diagonal) Super Retina XDR display with True Tone" },
        { td1: "Width", td2: "2.82 inches (71.5 mm)" },
        { td1: "Depth", td2: "0.3 inch (7.65 mm)" },
        { td1: "Weight", td2: "6.14 ounces (174 grams)" },
        { td1: "Capacity", td2: "64GB, 128GB, 256GB" },
        { td1: "Splash, Water, and Dust Resistant", td2: "All-glass and surgical-grade stainless steel design, water and dust resistant (rated IP68)" },
        { td1: "Camera and Video", td2: "Dual 12MP cameras with Portrait mode, Depth Control, Portrait Lighting, Smart HDR, and 4K Dolby Vision HDR video up to 30 fps" },
        { td1: "Front Camera", td2: "12MP TrueDepth front camera with Portrait mode, Depth Control, Portrait Lighting, and Smart HDR 4" },
        { td1: "Height", td2: "5.78 inches (146.7 mm)" },
        { td1: "Power and Battery", td2: "Video playback:Up to 19 hours, Video playback (streamed):Up to 15 hours, Audio playback:Up to 75 hours, 20W adapter or higher (sold separately), Fast-charge capable: Up to 50% charge in around 30 minutes with 20W adapter or higher" },
        { td1: "In the Box", td2: "iPhone with iOS 15, USB-C to Lightning Cable, Documentation" },
        { td1: "Warranty", td2: "Apple-branded hardware product and accessories contained in the original packaging (“Apple Product”) come with a One-Year Limited Warranty. See apple.com/in/legal/warranty for more information." },
    ]

    const shippingInfoTable = [
        { td1: "Courier", td2: "2 - 4 days, free shipping" },
        { td1: "Local Shipping", td2: "Up to one week, $19.00" },
        { td1: "UPS Ground Shipping", td2: "4 - 6 days, $29.00" },
        { td1: "Unishop Global Export", td2: "3 - 4 days, $39.00" }
    ];

    const productQuestions = [
        {
            "question": "Is it registered on Mdms?",
            "asker": "Alex B.",
            "date": "21 Apr, 2022",
            "answer": "Yes, all iPhones are registered in mdms.",
            "answerer": "iPhone Store",
            "answerDate": "answered within 1 hour",
            "askerImg": productImg4,
            "answererImg": productImg5
        },
        {
            "question": "Is it registered on Mdms?",
            "asker": "Alex B.",
            "date": "21 Apr, 2022",
            "answer": "Yes, all iPhones are registered in mdms.",
            "answerer": "iPhone Store",
            "answerDate": "answered within 1 hour",
            "askerImg": productImg4,
            "answererImg": productImg5
        },
        {
            "question": "Is it registered on Mdms?",
            "asker": "Alex B.",
            "date": "21 Apr, 2022",
            "answer": "Yes, all iPhones are registered in mdms.",
            "answerer": "iPhone Store",
            "answerDate": "answered within 1 hour",
            "askerImg": productImg4,
            "answererImg": productImg5
        }
    ]

    const commentsData = [
        {
            username: 'Terry Franci',
            userImg: productImg1,
            rating: 4,
            comment:
                'After researching about the dealer and reading most of the reviews I decided to buy that n I took this phone to Apple Store they says it’s original one so please guys u can buy this one while selling one of ur kidney 🥲 but it took long time to be delivered n the packing can be improved',
            helpfulNum: 456,
            reviewDate: '15 May, 2023',
            images: [commentImg1, commentImg2, commentImg1, commentImg2, commentImg1, commentImg1, commentImg1, commentImg1]
        },
        {
            username: 'Terry Franci',
            userImg: productImg2,
            rating: 2,
            comment:
                'Ordered this product and it arrived faulty. Had to go through a lengthy return process.',
            helpfulNum: 35,
            reviewDate: '10 June, 2023'
        },
        {
            username: 'Chai Legend',
            userImg: productImg4,
            rating: 2,
            comment:
                'Ordered this product and it arrived faulty. Had to go through a lengthy return process.',
            helpfulNum: 69,
            reviewDate: '10 June, 2023'
        },
    ];


    const delivery = {
        freeDelivery: "Monday, 4 September",
        location: "Bagmati, Kathmandu Metro 22 - Newroad Area, Newroad",
        soldBy: "Iphone Store PVT LTD"
    }

    const service = [
        { type: "warrenty", label: "Free 1 Year Warranty", },
        { type: "shipping", label: "Free Shipping & Fastest Delivery", },
        { type: "moneyBack", label: "100% Money-back guarantee", },
        { type: "customerSupport", label: "24/7 Customer support", },
        { type: "paymentMethod", label: "Secure payment method" }
    ]

    const starRatingsArr = [
        { stars: '5 Star', count: 121 },
        { stars: '4 Star', count: 185 },
        { stars: '3 Star', count: 166 },
        { stars: '2 Star', count: 85 },
        { stars: '1 Star', count: 25 }
    ];

    const highestCount = Math.max(...starRatingsArr.map(data => data.count));

    const getServiceImg = (type) => {
        // Temporary logic for choosing service image on right side content
        switch (type) {
            case "warrenty":
                return serviceMedal;

            case "shipping":
                return serviceTruck;

            case "moneyBack":
                return serviceHandshake;

            case "customerSupport":
                return serviceHeadphones;

            case "paymentMethod":
                return serviceCreditCard;

            default:
                return null;
        }
    }


    const reviewSortOptions = [
        { value: "recent", label: "Most Recent" },
        { value: "helpMe!", label: "Yesterday" },
    ]


    const totalAvailable = 10
    const productTitle = specificProductDetails.product.name
    const rating = 4.1
    const reviewNum = 244
    const questionAnswered = 12
    const discountCost = 999.99
    const cost = 1299.99
    const discountOffer = 21
    const colorFamily = [
        { "color": "Graphite", "image": productImg1 },
        { "color": "Deep Blue", "image": productImg2 },
        { "color": "Apple Red", "image": productImg3 }
    ]

    const productDesc = [
        "15 cm (6.1-inch) Super Retina XDR display",
        "Cinematic mode adds shallow depth of field and shifts focus automatically in your videos",
        "Advanced dual-camera system with 12MP Wide and Ultra Wide cameras; Photographic Styles, Smart HDR 4, Night mode, 4K Dolby Vision HDR recording",
        "12MP TrueDepth front camera with Night mode, 4K Dolby Vision HDR recording",
        "A15 Bionic chip for lightning-fast performance"
    ]

    // Deal Type and color
    const dealType = 'Deal of the Day'

    let dealColor
    if (dealType) {
        if (dealType === "Deal of the Day") { dealColor = '#C71720' }
        else if (dealType === "Best Deals") { dealColor = '#0072E3' }
        else if (dealType === "Sale") { dealColor = '#219653' }
    }


    // Extract all product's image
    const imageArr = specificProductDetails.product.images
    const [activeImage, setActiveImage] = useState(imageArr[4])
    const [activeColorFamily, setActiveColorFamily] = useState(colorFamily[0])

    const [sortReview, setSortReview] = useState('')
    const [quantity, setQuantity] = useState(1)


    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    const handleQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value);

        if (!isNaN(newQuantity)) {  // checks if newQuality is a valid number
            setQuantity(Math.min(Math.max(newQuantity, 1), totalAvailable));
        }
    };


    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div >

            {/* Proudct display section */}
            <section className={`${sectionPad} py-10 bg-white`}>

                <div className='flex flex-col md:flex-row gap-y-8'>

                    {/* images */}
                    <div className='w-full md:max-w-[600px] md:w-1/2'>

                        <div className='w-full mx-auto border rounded-md aspect-square'>
                            <img src={activeImage} className='p-8 w-full h-full object-contain' alt="" />
                        </div>

                        <div className='mt-4 pb-2 flex items-center justify-start mx-auto gap-3 overflow-x-auto'>
                            {specificProductDetails?.product?.images.map((image, index) => (

                                <div
                                    key={index}
                                    onMouseEnter={() => setActiveImage(image)}
                                    className={`${activeImage === image ? "border-secondary" : "border-primary"} block min-w-[6rem] max-w-[6rem] aspect-square border rounded-md`}
                                >
                                    <img src={image} className='p-2 w-full h-full object-contain' alt="" />
                                </div>

                            ))}
                        </div>

                    </div>



                    {/* Product Details */}
                    <div className='w-full px-2 lg:px-6 md:w-1/2 flex flex-col gap-y-3'>

                        {dealType &&
                            <div
                                style={{ backgroundColor: dealColor }}
                                className={` z-10 py-2 px-3 lg:px-4 text-white w-fit rounded`}
                            >
                                <p className='text-xs md:text-sm'>{dealType}</p>
                            </div>
                        }

                        <h4 className='font-semibold text-xl'>{productTitle}</h4>


                        <div className='flex flex-wrap justify-start items-center gap-3'>
                            <div className='flex items-center justify-center gap-x-0.5 text-2xl'>{getStarsFromRating(rating, <IoIosStar className='text-[#D4CDC5]' />)}
                                <span className='text-base ml-1 font-medium'>{rating}</span>
                            </div>

                            <div className='border-l border-primary h-8'></div>

                            <div className='text-sm'>{reviewNum} Reviews</div>

                            <div className='border-l border-primary h-8'></div>

                            <div className='text-sm'>{questionAnswered} Questions Answered</div>
                        </div>


                        <div className='flex flex-wrap justify-start items-center gap-3'>

                            {/* put in condition if it exists in api array */}
                            <div className='relative py-1 px-2 bg-[#0F50E6] rounded-l-md text-white text-sm'>
                                <div>#1 Best Seller</div>
                                <div className="absolute top-0 right-0 translate-x-[99%] border-t-[#0F50E6] border-b-[#0F50E6] border-r-transparent border-t-[14.18px] border-r-[16px] border-b-[14.19px] border-l-0 w-0 h-0 "></div>
                            </div>

                            {/* iphone is a replcement */}
                            <div className='ml-4 text-sm font-medium'>in Iphone Products</div>
                        </div>


                        <div className='my-3 w-full border-t border-primary' ></div>


                        <div className='flex gap-y-2 flex-wrap items-center justify-between'>
                            <div className='flex items-center justify-start gap-4 pr-2'>

                                <div className='flex items-end gap-1'>
                                    <h5 className=' text-2xl md:text-3xl font-semibold '>${discountCost}</h5>
                                    <div className='text-base md:text-lg text-gray-400 line-through '>${cost}</div>

                                </div>

                                {discountOffer &&
                                    <div className='py-1 px-2 bg-[#C71720] text-white w-fit rounded-md'>
                                        <p className='text-xs md:text-sm'>{discountOffer}% OFF</p>
                                    </div>
                                }
                            </div>

                            <div className=' flex justify-center items-center gap-2'>
                                <img src={starTick} className='w-full h-full' alt="" />
                                <div className='whitespace-nowrap text-[#219653] text-sm'>In Stock</div>
                            </div>
                        </div>


                        {/* How many items left? */}
                        <div className='space-y-2'>
                            <div className='text-[#C71720] text-sm whitespace-nowrap'>Hurry! Only {totalAvailable} items left</div>

                            <div className='text-sm whitespace-nowrap'>Inclusive of all taxes</div>
                        </div>


                        <div className='my-3 w-full border-t border-primary' ></div>


                        <div className='flex flex-wrap items-center justify-between'>
                            <div className='text-sm whitespace-nowrap text-[#70747D]'>Color Family:
                                <span className='font-medium text-black'> {activeColorFamily.color}</span>
                            </div>

                            <div className='flex items-center justify-center gap-2'>
                                {colorFamily?.map((color, index) => (
                                    <div
                                        key={index}
                                        onClick={() => setActiveColorFamily(color)}
                                        className={`${activeColorFamily.color === color.color ? "border-secondary" : "border-primary"} block w-10 aspect-square border rounded-md`}
                                    >
                                        <img src={color.image} className='w-full h-full object-contain p-1' alt="" />
                                    </div>
                                ))}
                            </div>
                        </div>


                        <div className='my-3 w-full border-t border-primary' ></div>


                        <div>
                            <div className='text-sm font-medium'>Quantity</div>

                            <div className='mt-2 w-fit flex items-center justify-center border rounded-md'>

                                <Button
                                    disabled={quantity <= 1}
                                    className={"p-2"}
                                    onClick={decreaseQuantity}
                                >
                                    <LuMinus />
                                </Button>

                                <Input
                                    min={1}
                                    max={totalAvailable}
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                    className={`w-8 pl-0 text-center`}
                                    type="number"
                                    inputMode="numeric"
                                />

                                <Button
                                    disabled={quantity >= totalAvailable}
                                    className={"p-2"}
                                    onClick={increaseQuantity}
                                >
                                    <IoAdd />
                                </Button>
                            </div>
                        </div>


                        <div className='mt-3 flex flex-wrap justify-center items-center gap-3'>

                            <div className='flex flex-grow justify-center items-center gap-2'>
                                <Button
                                    className='py-2 px-4 min-w-fit w-full flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-primary-hover border border-secondary bg-white'

                                >
                                    <PiShoppingCartSimpleThin className='text-2xl' />
                                    <div>Add to Cart</div>
                                </Button>

                                <Button
                                    className='py-2 px-4 min-w-fit w-full whitespace-nowrap rounded-md text-white bg-primary border border-secondary'
                                    label={"Buy Now"}
                                />
                            </div>

                            <div className='flex justify-center items-center gap-2'>
                                <Button
                                    className={"p-2 border border-primary rounded"}
                                >
                                    <CiHeart className='text-2xl' />
                                </Button>

                                <Button
                                    className={"p-2 border border-primary rounded"}
                                >
                                    <GoShareAndroid className='text-2xl' />
                                </Button>
                            </div>

                        </div>

                    </div>
                </div>

            </section>




            {/* Description */}
            <section className={`${sectionPad} my-10`}>

                <div className='flex flex-col lg:flex-row gap-6 lg:gap-x-8'>

                    <div className='h-fit w-full lg:w-2/3 space-y-6 lg:space-y-8'>

                        {/* Return Policy */}
                        <div className='w-full border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 text-lg text-black font-semibold'>Description</div>

                            <ul className='py-6 pl-10 pr-6 md:px-12 text-justify md:text-left text-black text-sm list-disc space-y-2'>
                                {productDesc?.map((desc, i) => (
                                    <li key={i}>{desc}</li>
                                ))}
                            </ul>
                        </div>



                        {/* Specifications */}
                        <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 w py-4 text-lg text-black font-semibold'>Specifications</div>

                            <Table
                                bodyItems={specificationTable}
                                bodyCss={"text-sm"}
                                colWidth={["w-[30%], w-[70%]"]}
                                mainCss={""}
                            />

                        </div>


                        {/* Shipping Information */}
                        <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 w py-4 text-lg text-black font-semibold'>Shipping Information</div>

                            <Table
                                bodyItems={shippingInfoTable}
                                bodyCss={"text-sm"}
                                colWidth={["w-[30%], w-[70%]"]}
                                mainCss={""}
                            />

                        </div>


                        {/* Reviews */}
                        <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 flex flex-wrap gap-y-2 items-center justify-around xl:justify-between'>
                                <div className='md:mr-auto text-lg text-black font-semibold'>Reviews</div>

                                <div className="px-4 py-0.5 w-fit flex items-center justify-center border border-primary rounded-full overflow-hidden">
                                    <label htmlFor="sortId123" className='text-sm whitespace-nowrap'>Sort By:</label>

                                    <Dropdown
                                        onChange={(e) => setSortReview(e.target.value)}
                                        className={'bg-white text-sm py-1 pl-2 h-full hover:cursor-pointer'}
                                        id={"sortId123"}
                                        name={'sortOptions'}
                                        value={sortReview}
                                        options={reviewSortOptions}
                                    />
                                </div>
                            </div>

                            <div className='divide-y'>

                                {/* Stars!!! */}
                                <div className='p-3 md:p-6 gap-y-5 flex flex-col items-center md:flex-row md:items-start justify-center'>

                                    {/* Giant number rating */}
                                    <div className='w-full md:w-1/4'>

                                        <div className='flex flex-col items-center justify-center md:items-start'>

                                            <div className='text-4xl font-medium text-center md:text-left'>{rating}
                                                <span className='text-[#70747D] text-2xl'> /5</span>
                                            </div>

                                            <div className='mt-1 flex items-center justify-start gap-x-0.5 text-3xl'>
                                                {getStarsFromRating(rating, <IoIosStar className='text-[#D4CDC5]' />)}
                                            </div>

                                            <div className='mt-3 text-xs whitespace-nowrap'>{reviewNum} Total Reviews</div>
                                        </div>
                                    </div>

                                    {/* Star bars */}
                                    <div className='w-full md:w-3/4'>

                                        <div className='md:pl-6 flex flex-col gap-3 items-center justify-center w-full'>
                                            {starRatingsArr?.map((data, index) => (

                                                <div key={index} className='w-full flex items-center justify-center gap-6'>
                                                    <div className='w-1/12 text-sm whitespace-nowrap'>{data.stars}</div>
                                                    <div className='relative flex-grow bg-[#ECEDEE] p-2 rounded'>
                                                        <div
                                                            className='w-10/12 absolute top-0 left-0 z-10 bg-[#F2C94C] h-full rounded'
                                                            style={{ width: `${(data.count / highestCount) * 100}%` }}
                                                        ></div>
                                                    </div>
                                                    <div className='w-1/12 text-sm'>{data.count}</div>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                </div>

                                <div className='mt-2 divide-y space-y-'>
                                    {commentsData?.map((data, index) => (

                                        <ReviewComments
                                            key={index}
                                            username={data.username}
                                            userImg={data.userImg}
                                            rating={data.rating}
                                            comment={data.comment}
                                            helpfulNum={data.helpfulNum}
                                            reviewDate={data.reviewDate}
                                            images={data.images ? data.images : []}
                                        />
                                    ))}


                                    <div className='px-6 py-4 bg-white'>

                                        <div className='mx-auto md:ml-auto md:mr-0 w-fit flex gap-3 items-center justify-center'>
                                            <Button
                                                className={"p-3 border rounded-lg flex items-center justify-center bg-white text-black hover:bg-[#ECEDEE]"}
                                            >
                                                <FaAngleLeft className='text-xs md:text-base' />
                                            </Button>

                                            <div className='text-xs'>extra button from arrayPage</div>

                                            <Button
                                                className={"p-3 border rounded-lg flex items-center justify-center bg-white text-black hover:bg-[#ECEDEE]"}
                                            >
                                                <FaAngleRight className='text-xs md:text-sm' />
                                            </Button>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>


                        {/* Questions about the Product */}
                        <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 flex flex-wrap gap-y-2 items-center justify-around xl:justify-between'>
                                <div className='md:mr-auto text-lg text-black font-semibold'>Questions about the Product</div>

                                <div className='text-sm text-black'>
                                    <span className='text-secondary'><Link onClick={scrollToTop} to="/auth/login">Login or Register</Link></span>
                                    &nbsp;to ask question
                                </div>
                            </div>

                            <div className='grid grid-cols-1 divide-y'>
                                {productQuestions.map((data, index) => (
                                    <ProductQuestions
                                        key={index}
                                        {...data}
                                    />
                                ))}
                            </div>

                            <div className='px-6 py-4 bg-white'>

                                <div className='mx-auto md:ml-auto md:mr-0 w-fit flex gap-3 items-center justify-center'>
                                    <Button
                                        className={"p-3 border rounded-lg flex items-center justify-center bg-white text-black hover:bg-[#ECEDEE]"}
                                    >
                                        <FaAngleLeft className='text-xs md:text-base' />
                                    </Button>

                                    <div className='text-xs'>extra button from arrayPage</div>

                                    <Button
                                        className={"p-3 border rounded-lg flex items-center justify-center bg-white text-black hover:bg-[#ECEDEE]"}
                                    >
                                        <FaAngleRight className='text-xs md:text-sm' />
                                    </Button>
                                </div>

                            </div>

                        </div>
                    </div>





                    {/* Right side contents */}
                    <div className='w-full lg:w-1/3 space-y-6 lg:space-y-8'>

                        {/* Delivery */}
                        <div className='w-full border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 text-lg text-black font-semibold'>Delivery</div>

                            <div className='grid grid-cols-1 divide-y'>

                                <div className='py-4 px-6 gap-3 flex items-start justify-start'>
                                    <div className='w-6 aspect-square'>
                                        <img src={truckFast} className='w-full h-full object-contain' alt="" />
                                    </div>

                                    <div>
                                        <div className='text-base font-medium'>FREE DELIVERY</div>
                                        <div className='text-sm'>{delivery.freeDelivery}</div>
                                        <div className='mt-3 flex items-center justify-start gap-2'>
                                            <Link to={"/"} className='text-secondary text-sm'>See Detais</Link>
                                            <FaAngleRight className='text-sm text-secondary' />
                                        </div>
                                    </div>
                                </div>


                                <div className='py-4 px-6 gap-3 flex items-start justify-start'>
                                    <div className='w-8 aspect-square'>
                                        <img src={location} className='w-full h-full object-contain' alt="" />
                                    </div>

                                    <div>
                                        <div className='text-base font-medium'>Location</div>
                                        <div className='text-sm'>{delivery.location}</div>
                                        <div className='mt-3 flex items-center justify-start gap-2'>
                                            <Link to={"/"} className='text-secondary text-sm'>Change</Link>
                                            <FaAngleRight className='text-sm text-secondary' />
                                        </div>
                                    </div>
                                </div>


                                <div className='py-4 px-6 flex flex-col items-start justify-center gap-6'>

                                    <div className=' gap-3 flex items-start justify-start'>
                                        <div className='w-8 aspect-square'>
                                            <img src={medalStar} className='w-full h-full object-contain' alt="" />
                                        </div>

                                        <div className='w-full'>
                                            <div className='text-base font-medium'>Sold By</div>
                                            <div className='text-sm'>{delivery.soldBy}</div>

                                            <div className='pl-1.5 pr-2 py-1 mt-1 bg-[#219653] rounded-full flex items-center justify-start gap-1'>
                                                <div className='w-[1.125rem] aspect-square'>
                                                    <img src={starTickWhite} className='w-full h-full' alt="" />
                                                </div>
                                                <div className='text-white text-sm'>Certified Store</div>
                                            </div>

                                            <div className='mt-3 flex items-center justify-start gap-2'>
                                                <Link to={"/"} className='text-secondary text-sm'>Visit Store</Link>
                                                <FaAngleRight className='text-sm text-secondary' />
                                            </div>

                                        </div>
                                    </div>

                                    <Button
                                        className={"rounded gap-1 py-3 px-5 w-full border border-secondary text-secondary bg-white flex justify-center items-center"}
                                    >
                                        <div className='w-5 aspect-square'>
                                            <img src={messages} className='w-full h-full object-contain' alt="" />
                                        </div>
                                        <div>Chat</div>
                                    </Button>
                                </div>

                            </div>
                        </div>


                        {/* Services */}
                        <div className='w-full border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 text-lg text-black font-semibold'>Services</div>

                            <div className='py-6 px-6 text-justify md:text-left text-black text-sm list-disc space-y-4'>
                                {service?.map((item, i) => (
                                    <div
                                        key={i}
                                        className='flex items-center justify-start gap-3'
                                    >
                                        <div className='w-6'>
                                            <img src={getServiceImg(item.type)} className='w-full h-full object-contain' alt="" />
                                        </div>
                                        <div className='text-sm'>{item.label}</div>
                                    </div>
                                ))}
                            </div>
                        </div>


                        {/* You may also like */}
                        <div className='w-full border bg-white border-primary rounded-md divide-y'>
                            <div className='px-6 py-4 text-lg text-black font-semibold'>You may also Like</div>

                            <div className='px-4 py-6 md:p-6 lg:px-3 lg:py-4 xl:p-6 gap-6 grid grid-cols-1 md:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-1'>
                                {youMayLikeProducts.map((prod) => (

                                    <YouMayLikeCard
                                        key={prod.id}
                                        title={prod.title}
                                        image={prod.image}
                                        {...prod}
                                    />
                                ))}
                            </div>
                        </div>

                    </div>
                </div>

            </section>




            <div className='my-10 md:my-16 w-full border-t border-primary' ></div>

            <section className={`${sectionPad} mb-10`}>

                <div className='flex pb-5 md:pb-6 flex-wrap items-center justify-center md:justify-start gap-6'>

                    <div className='font-semibold text-base md:text-xl'>People who viewed this item also viewed</div>

                    <Link to={'/latest-offer'} onClick={scrollToTop}>
                        <div className='flex items-center text-base gap-2'>
                            <div className='text-xs md:text-sm text-primary-hover hover:underline font-medium whitespace-nowrap hover:-translate-x-1 duration-500'>See More</div>
                            <GoArrowUpRight className='bg-primary text-white rounded-full w-5 h-5 p-0.5 text-lg' />
                        </div>
                    </Link>
                </div>


                <div>
                    <ItemsCarousel
                        contents={carouselProducts.map((items) => (
                            <Listing
                                key={items.id}
                                className={"mx-3 my-6"}
                                {...items}
                                image={items.image}
                                wishHeart={items.wishHeart}
                            />
                        ))}
                    />
                </div>

            </section>
        </div>
    )
}

export default ProductDetails


/*
Table data comes from api. and is not static data like in policy page


page important missing logics:

let users add review.
let user ask question.
*/