import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { helpMessageSchema } from './helpMessageSchema'

import {
  Button,
  TextField,
  Accordion,
  Input
} from '../../components/index'

import { supporter } from '../../assets/index'

import { CiSearch } from "react-icons/ci";
import { PiPhoneCallLight, PiChatCenteredDots } from "react-icons/pi";
import { BsArrowRight } from "react-icons/bs";


const HelpAndSupport = () => {

  const [qsearch, setQsearch] = useState('')

  const initialValues = {
    email: "",
    subject: "",
    message: "",
  }

  const assistItems = [
    { id: 1, label: "Track Order" },
    { id: 2, label: "Reset Password" },
    { id: 3, label: "Payment Option" },
    { id: 4, label: "User & Account" },
    { id: 5, label: "Wishlist" },
    { id: 6, label: "Shipping & Billing" },
    { id: 7, label: "Shopping Cart" },
    { id: 8, label: "Sell on Classicommerce" },
  ]

  const [assistActive, setAssistActive] = useState(assistItems[0].id)


  const accordionItems = [
    {
      id: 1,
      title: 'How do I return my item?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 2,
      title: 'How to cancel order?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 3,
      title: "What is 'Classicommerce Campaign 2022'?",
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 4,
      title: 'How long is the refund process?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 5,
      title: 'How to change my shop name?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 6,
      title: "What are the 'Delivery Timelines'?",
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 7,
      title: 'What is Classicommerce Returns Policy?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 8,
      title: 'Ask the Digital and Device Community',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
    {
      id: 9,
      title: 'What is the Voucher & Gift Offer in this Campaign?',
      content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
    },
  ];

  const onFormSubmit = (val, action) => {
    console.log(val)
    action.resetForm()
  }

  const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

  return (
    <div>

      <div className='bg-white '>

        <div className='flex'>

          <div className={`w-full lg:w-[60%] px-2 md:px-16 lg:px-24 xl:px-36 py-16 md:py-10 xl:py-16 bg-white flex flex-col items-start justify-center gap-y-6 lg:gap-y-4 xl:gap-y-6`}>

            <div className='mx-auto lg:mx-0 font-medium py-2 px-4 rounded-sm bg-[#EFD33D] text-xs lg:text-sm text-black'>HELP CENTER</div>

            <h1 className='w-full text-center lg:text-left font-semibold text-xl lg:text-3xl text-black leading-normal'>
              How can we help you!
            </h1>



            <div className='mt-4 h-fit w-full flex items-center justify-center border border-primary rounded-lg overflow-hidden'>

              <div>
                <CiSearch className='text-3xl ml-4 text-secondary' />
              </div>

              <Input
                placeholder={'Search products'}
                value={qsearch}
                onChange={(e) => setQsearch(e.target.value)}
                className='bg-white text-sm outline-none w-full px-2 py-4'
              />

              <Button
                className="bg-primary text-xs md:text-sm px-4 py-4 h-full text-white rounded-r-md"
                label={"Search"}
              />

            </div>

          </div>



          <div className='w-[40%] hidden lg:block'>
            <img src={supporter} className='w-full h-full' alt="about" />
          </div>

        </div>


        <section className={`${sectionPad}`}>

          <h3 className='my-10 md:my-16 w-full text-center font-semibold text-lg lg:text-2xl text-black leading-normal'>
            How can we assist you with today?
          </h3>

          <div className=' grid w-full gap-3 lg:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]'>
            {assistItems.map((item) => (
              <Button
                key={item.id}
                onClick={() => setAssistActive(item.id)}
                className={`${assistActive === item.id ? "border-secondary shadow-lg font-medium" : "font-normal border-primary"} 
                px-3 py-2 lg:px-6 lg:py-5 border rounded hover:border-secondary hover:shadow-lg hover:font-medium text-sm lg:text-base whitespace-nowrap`}
                label={item.label}
              />
            ))}

          </div>

        </section>

        <div className='my-10 md:my-16 w-full border-t border-primary' ></div>

        <section className={`${sectionPad} pb-10 md:pb-16`}>


          <h3 className='my-10 md:my-16 w-full text-center font-semibold text-lg lg:text-2xl text-black leading-normal'>
            Frequently Asked Questions
          </h3>


          <div className='flex flex-col gap-y-10 lg:gap-y-0 lg:gap-x-4 lg:flex-row justify-center items-start'>

            <div className='w-full lg:w-[60%] space-y-2 md:space-y-4'>
              <Accordion
                items={accordionItems}
              />
            </div>


            <div className='w-full space-y-4 rounded p-4 lg:p-8 lg:w-[40%] bg-[#F3F2FF]'>
              <h5 className='text-start font-semibold text-base lg:text-xl text-black'>
                Don’t find your answer, Ask for support.
              </h5>

              <div className='text-start text-sm text-[#70747D]'>Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed molestie accumsan dui, non iaculis primis in faucibu raesent eget sem purus.</div>


              <Formik
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                validationSchema={helpMessageSchema}
              >
                {() => (
                  <Form>

                    <div>
                      <TextField
                        name="email"
                        placeholder="Email Address"
                        type="text"
                      />
                    </div>

                    <div className='mt-3'>
                      <TextField
                        name="subject"
                        placeholder="Subject"
                        type="text"
                      />
                    </div>

                    <div className='mt-5'>
                      <Field
                        className="border border-gray-300 w-full p-3 h-40 min-h-39 max-h-40 text-sm rounded-lg outline-gray-300"
                        as="textarea"
                        name={"message"}
                        placeholder={"Message (Optional)"}
                      />
                    </div>

                    <Button
                      className={'mt-2 lg:mt-4 rounded-md bg-primary text-white flex justify-center items-center gap-2 py-3 px-5'}
                    >
                      <p className='whitespace-nowrap text-sm lg:text-base'>Send Message</p>
                      <BsArrowRight className='text-base lg:text-lg' />
                    </Button>

                  </Form>
                )}

              </Formik>
            </div>

          </div>

        </section>
      </div>





      <div className={`${sectionPad} pt-5`}>

        <div className='my-6 w-fit mx-auto font-medium py-2 px-4 rounded-sm bg-[#2DA5F3] text-sm text-white'>CONTACT US</div>

        <h3 className='my-6 px-4 md:px-0 w-fit md:w-[30%] mx-auto text-center font-semibold text-lg lg:text-2xl text-black leading-normal'>
          Don’t find your answer. Contact with us
        </h3>


        <div className='mt-10 mb-16 w-full flex flex-col lg:flex-row gap-3 xl:gap-7 '>

          <div className='w-full lg:w-1/2 border border-primary rounded-lg bg-white py-6 xl:py-10 flex items-start justify-center gap-2'>

            <div className='w-1/5 h-full mx-auto px-1 mb-auto'>
              <div className='bg-[#F3F2FF] w-fit mx-auto p-3 lg:p-4 flex items-center justify-center aspect-square rounded-full'>
                <PiPhoneCallLight className='text-3xl lg:text-4xl text-secondary ' />
              </div>
            </div>

            <div className='w-4/5 pr-5 flex flex-col justify-center items-start gap-y-2'>
              <h5 className='font-semibold text-base lg:text-xl text-black'>Call us now</h5>
              <div className='text-[#70747D] text-xs lg:text-sm'>we are available online from 9:00 AM to 5:00 PM (GMT 5:45) Talk with use now</div>
              <h6 className=' lg:mt-2 font-semibold text-base lg:text-xl text-black'>(+61) 0508 651 014</h6>

              <Button
                className={'mt-2 lg:mt-4 rounded-md bg-primary text-white flex justify-center items-center gap-2 py-2 px-4 lg:px-5 lg:py-3 '}
              >
                <p className='whitespace-nowrap text-sm lg:text-base'>Call Now</p>
                <BsArrowRight className='text-base lg:text-lg' />
              </Button>
            </div>

          </div>

          <div className='w-full lg:w-1/2 border border-primary rounded-lg bg-white py-6 xl:py-10 flex items-start justify-center gap-2'>

            <div className='w-1/5 h-full mx-auto px-1 mb-auto'>
              <div className='bg-[#EEF7FF] w-fit mx-auto p-3 lg:p-4 flex items-center justify-center aspect-square rounded-full'>
                <PiChatCenteredDots className='text-3xl lg:text-4xl text-customBlue ' />
              </div>
            </div>

            <div className='w-4/5 pr-5 flex flex-col justify-center items-start gap-y-2'>
              <h5 className='font-semibold text-base lg:text-xl text-black'>Chat with us</h5>
              <div className='text-[#70747D] text-xs lg:text-sm'>we are available online from 9:00 AM to 5:00 PM (GMT 5:45) Talk with use now</div>
              <h6 className=' lg:mt-2 font-semibold text-base lg:text-xl text-black'>Support@classicommerce.com</h6>

              <Button
                className={'mt-2 lg:mt-4 rounded-md bg-[#0F50E6] text-white flex justify-center items-center gap-2 py-2 px-4 lg:px-5 lg:py-3 '}
              >
                <p className='whitespace-nowrap text-sm lg:text-base'>Contact Us</p>
                <BsArrowRight className='text-base lg:text-lg' />
              </Button>
            </div>

          </div>


        </div>


      </div>
    </div>
  )
}

export default HelpAndSupport