import React, { useState } from 'react'

import {
    Button,
    RadioButton,
    BlogCard,
    Input,
    Dropdown
} from '../../components/index'

import {
    blogImg1,
    blogImg2,
    blogImg3,
    blogImg4,
    latestBlogImg1,
    latestBlogImg2,
    latestBlogImg3,
} from '../../assets/index'
import LatestBlog from './LatestBlog/LatestBlog'
import CategorySidebar from './CategorySidebar/CategorySidebar'

import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { BiSolidCategory } from "react-icons/bi";


const Blogs = () => {

    const [qsearch, setQsearch] = useState('')
    const [selectCategory, setSelectCategory] = useState('Category')
    const [toggleCategorySidebar, setToggleCategorySidebar] = useState(false)

    const radioOptions = [
        { id: 1, value: 'All', label: 'All' },
        { id: 2, value: 'Electronics Devices', label: 'Electronics Devices' },
        { id: 3, value: 'Computer & Laptop', label: 'Computer & Laptop' },
        { id: 4, value: 'Computer Accessories', label: 'Computer Accessories' },
        { id: 5, value: 'SmartPhone', label: 'SmartPhone' },
        { id: 6, value: 'Headphone', label: 'Headphone' },
        { id: 7, value: 'Mobile Accessories', label: 'Mobile Accessories' },
        { id: 8, value: 'Gaming Console', label: 'Gaming Console' },
        { id: 9, value: 'Camera & Photo', label: 'Camera & Photo' },
    ];

    const [selectedOption, setSelectedOption] = useState(radioOptions[0].value);

    const sortOptions = [
        { value: "recent", label: "Most Recent" },
        { value: "helpMe!", label: "Yesterday" },
    ]


    const blogPosts = [
        {
            id: 1,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg1,
        },
        {
            id: 2,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 3,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 4,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg4,
        },
        {
            id: 5,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
        {
            id: 6,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg3,
        },
        {
            id: 7,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg1,
        },
        {
            id: 8,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg4,
        },
        {
            id: 9,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg3,
        },
        {
            id: 10,
            date: "21 Jun, 2022",
            title: 'Butterfly effects: the fusion of art, technology, design and the natural world',
            desc: "Our Scottish Fold Kittens Ready to go now. Come with first vaccination, micro chipped, vet checked and wormed to date. kittens are being raised in a household with other dogs and children both young and older.",
            image: blogImg2,
        },
    ];

    const latestBlog = [
        { id: 1, image: latestBlogImg1, date: "21 Jun, 2022", title: "Designing for Mindfulness with Wearable Technology" },
        { id: 2, image: latestBlogImg2, date: "21 Jun, 2022", title: "Microsoft open sources its in-memory GraphEngine system" },
        { id: 3, image: latestBlogImg3, date: "21 Jun, 2022", title: "Butterfly effects: the fusion of art, technology, design and the natural world" },
        { id: 4, image: latestBlogImg2, date: "21 Jun, 2022", title: "A collection of daily UI challenges for your inspiration." },
        { id: 5, image: latestBlogImg3, date: "21 Jun, 2022", title: "We love useful stuff, and we love quality writing, that's why we send out an editorial email newslet" },
    ]

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <>

            <CategorySidebar
                toggleCategorySidebar={toggleCategorySidebar}
                setToggleCategorySidebar={setToggleCategorySidebar}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                radioOptions={radioOptions}
            />

            <div className={`${sectionPad} flex gap-6`}>

                {/* Category */}
                <div className='w-full hidden lg:block lg:w-1/3'>


                    <div className='mb-6 h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-base text-black font-medium'>Category</div>

                        <div className='px-6 py-4 space-y-4 hidden lg:block'>
                            {radioOptions.map((option) => (
                                <RadioButton
                                    key={option.id}
                                    id={option.id}
                                    name="options"
                                    value={option.value}
                                    label={option.label}
                                    checked={selectedOption === option.value}
                                    onChange={(e) => setSelectedOption(e.target.value)}
                                />
                            ))}
                            <div className='text-secondary text-sm'>See all</div>
                            <div className='text-red-500'>MAKE IT'S LOGIC</div>
                        </div>
                    </div>


                    <div className='h-fit border bg-white border-primary rounded-md divide-y'>
                        <div className='px-6 py-4 text-base text-black font-medium'>Latest Blog</div>

                        <div className='px-6 py-4 gap-6 grid grid-cols-1'>
                            {latestBlog.map((blog) => (

                                <LatestBlog
                                    key={blog.id}
                                    date={blog.date}
                                    image={blog.image}
                                    title={blog.title}
                                />
                            ))}
                        </div>
                    </div>

                </div>




                {/* Contents */}
                <div className='w-full lg:w-2/3'>

                    <div className='mb-6 p-4 gap-4 xl:gap-6 rounded-md border border-primary bg-white flex flex-wrap items-center justify-around'>

                        {/* Search */}
                        <div className='w-full lg:w-[48%]'>

                            <div className='h-fit w-full flex items-center justify-center border border-primary rounded-md overflow-hidden'>

                                <Input
                                    placeholder={'Search...'}
                                    value={qsearch}
                                    onChange={(e) => setQsearch(e.target.value)}
                                    className='bg-white text-sm outline-none w-full px-3 py-3'
                                />

                                <div className='border-l border-primary h-6'></div>

                                <div>
                                    <CiSearch className='text-2xl mx-3 text-black' />
                                </div>

                            </div>
                        </div>


                        {/* Dropdown */}
                        <div className='w-full lg:w-[48%] '>

                            <div className='flex items-center'>

                                <Button
                                    onClick={() => setToggleCategorySidebar(true)}
                                    className='px-2 lg:hidden'
                                >
                                    <BiSolidCategory className='text-2xl' />
                                </Button>

                                <div className="px-4 py-1 ml-auto w-fit flex items-center justify-center border border-primary rounded-full overflow-hidden">

                                    <label htmlFor="sortId1" className='text-xs md:text-sm whitespace-nowrap'>Sort By:</label>

                                    <Dropdown
                                        onChange={(e) => setSelectCategory(e.target.value)}
                                        className={'bg-white text-sm md:text-base py-1 pl-2 h-full hover:cursor-pointer'}
                                        id={"sortId1"}
                                        name={'sortOptions'}
                                        value={selectCategory}
                                        options={sortOptions}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>





                    {/* Blog */}
                    <div className='grid gap-6 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]'>
                        {blogPosts.map((blog) => (

                            <BlogCard
                                key={blog.id}
                                date={blog.date}
                                title={blog.title}
                                desc={blog.desc}
                                image={blog.image}
                            />
                        ))}

                    </div>


                    {/* Page change */}
                    <div className='my-8 p-5 lg:py-6 gap-6 rounded-md border border-primary bg-white flex flex-col flex-wrap md:flex-row justify-center xl:justify-between items-center'>

                        <div className='flex items-center flex-wrap gap-2'>
                            <div className='text-xs md:text-sm'>Results:</div>
                            <div className='text-xs md:text-sm font-medium'>1 - 100 of 9,999</div>
                        </div>

                        <div className='flex gap-3 items-center justify-center'>
                            <Button
                                className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                            >
                                <FaAngleLeft className='text-xs md:text-sm' />
                                <div className='text-sm'>Previous</div>
                            </Button>

                            <div className='text-sm'>extra button from arrayPage</div>

                            <Button
                                className={"px-3 md:px-4 py-2 border rounded-lg flex items-center justify-center gap-x-2 md:gap-x-4 bg-white text-black hover:bg-[#ECEDEE]"}
                            >
                                <div className='text-sm'>Next</div>
                                <FaAngleRight className='text-xs md:text-sm' />
                            </Button>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}


export default Blogs