import React from 'react'

const RadioButton = ({
    id,
    name,
    value,
    label,
    checked,
    onChange,
    className
}) => {

    return (
        <div className='space-x-3 flex items-center'>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className={`${className} bg-white w-5 h-5`}
            />
            <label className='text-sm' htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioButton