import React from 'react'
import { Input, Button } from '../index'

const SubscribeToNews = () => {

    return (
        <section className='bg-[#5F2FF8]'>

            <div className='w-full px-6 md:px-0 md:w-[75%] lg:w-1/2 xl:w-[35%] py-24 mx-auto text-white text-center flex flex-col items-center justify-center gap-y-4 xl:gap-y-6'>

                <div className='font-medium text-base md:text-'>SUBSCRIBE FOR FREE</div>
                <div className='text-xl lg:text-2xl xl:text-3xl xl:leading-relaxed font-semibold'>Subscribe to our newsletter & get the latest updates</div>


                <div className="mt-4 flex w-full justify-between items-center relative">
                    <Input
                        id={"suscribe"}
                        type='email'
                        name={"suscribe"}
                        className="text-black w-full px-5 py-4 xl:py-5 text-sm rounded-2xl"
                        placeholder={"Enter your email address"}
                    />
                    <Button
                        // onClick={()}
                        className="absolute right-0 top-1/2 -translate-x-[5%] -translate-y-1/2 bg-[#39169C] px-4 py-2 xl:px-6 xl:py-3 text-sm md:text-base xl:text- text-white rounded-xl"
                        label={"Subscribe Now"}
                    />
                </div>

                <div className='text-xs'>Your email is secure and won’t send you any spam</div>
            </div>

        </section>)
}

export default SubscribeToNews