import { useState } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const FilterAccordion = ({ title, children }) => {

    const [activeIndex, setActiveIndex] = useState(true);

    return (
        <>
            <div className='w-full px-2 py-3'>
                <div
                    className={`transition-height transform ease-in-out duration-300 flex justify-between items-center cursor-pointer`}
                    onClick={() => setActiveIndex(!activeIndex)}
                >
                    <h3 className="px-2 text-base font-medium">{title}</h3>

                    {activeIndex ?
                        <IoIosArrowUp className='w-8 h-8 px-2' />
                        : <IoIosArrowDown className='w-8 h-8 px-2' />
                    }

                </div>

                <div className={` ${activeIndex ? "h-fit opacity-100 py-3" : "opacity-0 h-0"} px-2 overflow-hidden transition-height transform ease-in-out duration-300`}>
                    {children}
                </div>
            </div >
        </>
    );
};

export default FilterAccordion;
