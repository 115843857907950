import React from "react";
import { ErrorMessage, Field } from "formik";
import { TextError } from "../index";

const InputField = ({ label, placeholder, optional, name, flag = false, ...rest }) => {
    return (
        <div className="w-full">
            <label
                htmlFor={name}
                className="pb-2 text-primary flex text-sm items-center justify-between"
            >
                <div className="flex whitespace-nowrap items-center gap-x-3">
                    {label}
                </div>
                <ErrorMessage name={name} component={TextError} />
            </label>

            {flag ? (
                <div className="w-full flex border border-gray-300 items-center rounded-lg">
                    <Field
                        as="select"
                        name="callingCode"
                        className="rounded-l-lg rounded-r-none border-r text-sm bg-white border-gray-300 w-fit h-full px-3 py-3 outline-none"
                    >
                        <option value="977"> +977</option>
                        <option value="61"> +61</option>
                    </Field>

                    <Field
                        id={name}
                        name={name}
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        {...rest}
                        className="rounded-r-lg rounded-l-none w-full h-full px-3 py-3 text-sm outline-none"
                        placeholder={placeholder}
                    />
                </div>
            ) : (
                <Field
                    id={name}
                    name={name}
                    {...rest}
                    className="border border-gray-300 w-full px-3 py-3 text-sm rounded-lg outline-gray-300"
                    placeholder={placeholder}
                />
            )}
        </div>
    );
};

export default InputField;
