import React from 'react'
import { Button } from '../index'
import { BsArrowRight } from "react-icons/bs";


const AdCard = ({
    className,
    announcement,
    announcementCss,
    title,
    titleCss,
    description,
    descriptionCss,
    buttonCss,
    imageContent
}) => {
    return (
        <div className={`${className} w-full relative flex justify-center items-center rounded-md`}>

            <div className='w-[55%] h-full'>

                <div className='h-full px-4 py-8 pr-0 lg:px-8 lg:pr-0 xl:p-12 xl:pr-0 gap-y-3 flex-col flex items-start justify-start'>

                    <div className={`${announcementCss} whitespace-nowrap font-medium w-fit px-3 py-2 text-xs lg:text-sm`}>{announcement}</div>
                    <div className={`${titleCss} font-semibold text-base lg:text-xl xl:text-2xl`}>{title}</div>
                    <div className={`${descriptionCss} text-xs xl:text-sm`}>{description}</div>

                    <Button
                        className={`${buttonCss} rounded-md flex justify-center items-center gap-2 py-2 px-3 lg:px-4 lg:py-3`}
                    >
                        <p className='whitespace-nowrap text-sm xl:text-base'>SHOP NOW</p>
                        <BsArrowRight className='text-lg' />
                    </Button>

                </div>

            </div>
            <div className='w-[45%] h-full'>
                {imageContent}
            </div>

        </div>
    )
}

export default AdCard