import React from 'react'

const Table = ({
    mainCss = "rounded-md border",
    headItems,
    bodyItems,
    headCss,
    bodyCss,
    colWidth = ["w-1/2, w-1/2"]
}) => {
    return (
        <div className={`${mainCss} overflow-y-auto`}>
            <table className="w-full divide-y min-w-[500px]">

                {headItems &&
                    <thead className={`${headCss} bg-[#ECEDEE]`}>
                        <tr className="divide-x text-left">
                            <th className="py-3 px-4 font-normal">{headItems.th1}</th>
                            <th className="py-3 px-4 font-normal">{headItems.th2}</th>
                        </tr>
                    </thead>
                }

                {bodyItems &&
                    <tbody className={`${bodyCss} divide-y`}>
                        {bodyItems.map((item, index) => (
                            <tr key={index} className="divide-x">
                                <td className={`${colWidth[1]} py-3 px-4 bg-[#FAFAFB]`}>{item.td1}</td>
                                <td className={`${colWidth[0]} py-3 px-4`}>{item.td2}</td>
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
        </div>)
}

export default Table