import React, { useState } from 'react'
import { FaCheck } from "react-icons/fa6";
import { australia, european, usa } from '../../../../assets/index'
import { Button } from '../../../index'

const LangCon = ({ setIsLangMenuActive, lang, setLang }) => {

    const langData = [
        { id: 1, flag: australia, label: "Dollar (AUD)", currency: "AUD" },
        { id: 2, flag: european, label: "Dollar (USD)", currency: "USD" },
        { id: 3, flag: usa, label: "Dollar (EUR)", currency: "EUR" },
    ]

    const [isfakeActive, setIsfakeActive] = useState(lang.currency)
    console.log(isfakeActive)
    // Data has to either come form backend or stored in redux for sync

    return (
        <div className='p-2 md:px-6 md:py-5'>

            {langData.map((lang) => (

                <Button
                    onClick={() => {
                        setIsfakeActive(lang.currency)
                        setIsLangMenuActive(false)
                        setLang(lang)
                    }}

                    key={lang.id}
                    className='flex items-center justify-between py-2 px-1 w-full'
                >
                    <div className='flex items-center justify-center gap-4 px-2 md:gap-7 md:px-6 lg:px-0'>
                        <div className='rounded-md w-5'>
                            <img src={lang.flag} className='w-full h-full' alt="flag" />
                        </div>
                        <div className='text-sm md:text-base whitespace-nowrap'>{lang.label}</div>
                    </div>

                    <div className='flex items-center justify-center px-2 md:px-6 lg:px-0'>
                        <FaCheck className={`${isfakeActive === lang.currency ? "text-base" : "hidden"} text-primary`} />
                    </div>

                </Button>

            ))}

        </div>
    )
}

export default LangCon