import React from 'react'
import { Button, Listing } from '../index'

const FeatureAndRecommend = ({
    title,
    productArray,
    buttonLabels,
    activeButton,
    setActiveButton,
    adContents,
    productCss = "order-1",
    adContentCss = "order-2",
    linkContent
}) => {

    return (
        <div className='py-10 md:py-16'>

            <h2 className='mb-10 font-semibold text-xl md:text-3xl'>{title}</h2>

            <div className='my-7 mr-auto gap-6 flex flex-col lg:flex-row items-center justify-around'>

                <div className='w-full overflow-y-auto mx-0 px-0'>
                    <div className='border-b border-primary flex mr-auto'>
                        {buttonLabels?.map((label, index) => (
                            <Button
                                key={index}
                                onClick={() => setActiveButton(label)}
                                className={`${activeButton === label ? "border-secondary text-black" : "border-[#FAFAFA] text-[#70747D]"} 
                            whitespace-nowrap border-b-2 lg:border-b-[3px] py-2 px-4 lg:px-6 text-sm lg:text-base`}
                                label={label}
                            />
                        ))}
                    </div>
                </div>

                {linkContent}

            </div>

            <div className='flex gap-3 md:gap-6'>

                <div className={`${productCss} w-full lg:w-9/12`}>
                    <div className='grid gap-3 md:gap-6 grid-cols-[repeat(auto-fill,minmax(165px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(190px,1fr))]'>
                        {productArray.map((items) => (
                            <Listing
                                key={items.id}
                                {...items}
                                image={items.image}
                                wishHeart={items.wishHeart}
                            />

                        ))}
                    </div>
                </div>

                <div className={`${adContentCss} hidden lg:block md:w-3/12`}>

                    {adContents}

                </div>
            </div>

        </div>
    )
}

export default FeatureAndRecommend