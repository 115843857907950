import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';

import { FaAngleDown } from "react-icons/fa6";
import { FaTwitter, FaYoutube } from "react-icons/fa";
import { MdFacebook } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { australia, european, usa } from '../../../assets/index'

import {
    Button,
    Modal,
    Overlay
} from '../../index'

import LangCon from './ModalContents/LangCon'
import SearchNav from './SearchNav'
import BlackFriday from './BlackFriday'
import MainNav from './MainNav'


const MainHeader = () => {


    const langData = [
        { id: 1, flag: australia, label: "Dollar (AUD)", currency: "AUD" },
        { id: 2, flag: european, label: "Dollar (USD)", currency: "USD" },
        { id: 3, flag: usa, label: "Dollar (EUR)", currency: "EUR" },
    ]

    const [lang, setLang] = useState(langData[0])

    const [isLangMenuActive, setIsLangMenuActive] = useState(false)

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"


    return (
        <>
            <header>

                <BlackFriday />

                <section className='bg-white'>
                    <div className=' border border-primary'>

                        <div className={`${sectionPad} py-3 flex flex-wrap flex-col items-center justify-center gap-x-6 gap-y-3 xl:justify-between md:flex-row`}>

                            <div className='text-sm font-[500] text-center'>Welcome to Classicommerce Online eCommerce Store</div>

                            <div className='flex flex-wrap items-center justify-center sm:justify-between gap-3'>

                                <p className='text-sm text-[#70747D]'>Follow us:</p>

                                <div className='flex gap-x-2 md:gap-x-3'>
                                    <a target='_blank' rel="noreferrer" href="https://twitter.com/home?lang=en">
                                        <FaTwitter className='text-black text-xl' />
                                    </a>
                                    <a target='_blank' rel="noreferrer" href="https://twitter.com/home?lang=en">
                                        <MdFacebook className='text-black text-xl' />
                                    </a>
                                    <a target='_blank' rel="noreferrer" href="https://twitter.com/home?lang=en">
                                        <FaYoutube className='text-black text-xl' />
                                    </a>
                                    <a target='_blank' rel="noreferrer" href="https://twitter.com/home?lang=en">
                                        <RiInstagramFill className='text-black text-xl' />
                                    </a>
                                </div>


                                <div className='border-l border-primary h-8'></div>

                                <div className='relative'>

                                    <Button
                                        onClick={() => setIsLangMenuActive(true)}
                                        className={"rounded-md p-2 flex justify-center items-center gap-x-3"}
                                    >
                                        <div className='rounded-md'>
                                            <img className='w-full h-full' src={lang.flag} alt="aus" />
                                        </div>
                                        <div className='text-sm'>{lang.currency}</div>
                                        <FaAngleDown />
                                    </Button>

                                    {isLangMenuActive &&
                                        <Modal
                                            title={"Language"}
                                            className={"arrow"}
                                            contents={
                                                <LangCon
                                                    lang={lang}
                                                    setLang={setLang}
                                                    setIsLangMenuActive={setIsLangMenuActive}
                                                />
                                            }
                                        />
                                    }

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${sectionPad}`}>
                        <SearchNav />
                    </div>

                </section>

                <MainNav />

            </header>


            {isLangMenuActive && (
                <Overlay onClick={() => setIsLangMenuActive(false)} />
            )}


            <Outlet />
        </>
    )
}

export default MainHeader