import React from 'react'
import { Button } from '../index'

import { gadgets } from '../../assets/index'
import { BsArrowRight } from "react-icons/bs";


const Featured = () => {
    return (
        <div className='h-full flex flex-col'>

            <div className='h-fit py-14 px-4 flex items-center justify-center flex-col gap-y-5 rounded-t bg-[#F3DE6D]'>

                <div className='font-medium text-[#BE4646] text-sm text-center'>COMPUTER & ACCESSORIES</div>
                <h4 className='font-semibold text-center text-xl xl:text-3xl'>32% Discount</h4>

                <div className='text-[#475156] text-center text-sm xl:text-base '>For all ellectronics products</div>

                <div className='flex flex-col items-center justify-center gap-2'>
                    <div className='text-black text-sm xl:text-base'>Offers ends in:</div>
                    <h6 className='bg-[#FA8232] rounded px-4 py-2 text-sm xl:text-base text-center whitespace-nowrap text-white'>ENDS OF CHRISTMAS</h6>
                </div>

                <Button
                    className={'mt-8 rounded-md bg-white flex justify-center items-center gap-2 py-2 lg:px-4 '}
                >
                    <p className='text-[#191C1F] whitespace-nowrap text-base'>Shop Now</p>
                    <BsArrowRight className='text-[#191C1F] text-lg xl:text-xl' />
                </Button>
            </div>


            <div className='h-fit rounded-b'>

                <img src={gadgets} className='w-full h-full' alt="" />
            </div>

        </div>
    )
}

export default Featured