import React, { Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} group`}
            style={{
                ...style,
                display: "block",
                height: "20%",
                width: "60px",
                transform: "translate(0, -50%)",
                right: 0,
                zIndex: 999
            }}
            onClick={onClick}
        >
            <div className='h-full w-full relative hover:bg-[#8B8C8D] opacity-75 duration-300 block'>
                <BsChevronRight className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>

    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} group`}
            style={{
                ...style,
                display: "block",
                height: "20%",
                width: "60px",
                transform: "translate(0, -50%)",
                left: 0,
                zIndex: 999
            }}
            onClick={onClick}
        >
            <div className='h-full w-full relative hover:bg-[#8B8C8D] opacity-75 duration-300 block'>
                <BsChevronLeft className='group-hover:text-white text-black duration-150 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl' />
            </div>
        </div>
    );
}

const MainCarousel = ({
    contents = [],
    className,
    inactiveButton = "text-[#D1D4D7]"
}) => {

    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div>
                <ul className='absolute flex w-full h-fit bottom-0 -translate-y-1/2 md:-translate-y-full lg:-translate-y-[150%] items-center justify-center'> {dots} </ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1190,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
        customPaging: () => (
            <div className='custom-border w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 border-none rounded-full flex items-center justify-center'>
                <GoDotFill className={`custom-dots ${inactiveButton} text-lg md:text-2xl`} />
            </div>
        )
    }


    return (
        <>
            <style className='hidden'>
                {`
                .slick-arrow::before {
                    content: none;
                }
                .slick-dots {
                    position: static;
                }
                .slick-active > .custom-border {
                    border: 2px solid #101827;
                }
                .slick-active > .custom-border > .custom-dots {
                    color: #101827;
                }
                `}
            </style>

            <Slider {...settings} className={`${className} relative`}>
                {contents.map((content, index) => (
                    <Fragment key={index}>
                        {content}
                    </Fragment>
                ))}

            </Slider>
        </>
    );
};

export default MainCarousel;