import { useState } from 'react';
import { LiaPlusCircleSolid, LiaMinusCircleSolid } from "react-icons/lia";


const Accordion = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState({});

    const toggleAccordion = (index) => {
        setActiveIndex({
            ...activeIndex,
            [index]: !activeIndex[index]
        });
    };

    return (
        <>

            {items.map((item, index) => (
                <div
                    key={index}
                    className="border overflow-hidden border-primary rounded"
                >
                    <div
                        className={`${activeIndex[index] ? "bg-[#ECEDEE]" : "bg-white"}  transition-height transform ease-in-out duration-300 px-4 py-3 flex justify-between items-center cursor-pointer`}
                        onClick={() => toggleAccordion(index)}
                    >
                        <h3 className="px-2 text-sm font-medium">{item.title}</h3>

                        {activeIndex[index] ?
                            <LiaMinusCircleSolid className='text-xl md:text-2xl' />
                            : <LiaPlusCircleSolid className='text-xl md:text-2xl' />
                        }

                    </div>

                    <div className={` ${activeIndex[index] ? "h-fit py-3 mt-2 shadow-md opacity-100" : "opacity-0 h-0"} text-xs md:text-sm px-6 overflow-hidden rounded-md transition-height transform ease-in-out duration-300`}>
                        <div>{item.content}</div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Accordion;
