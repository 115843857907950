import React from 'react'

import {
    Button,
    TextField,
    Accordion,
    Input,
    RewardSchemeCard
} from '../../components/index'

import {
    becomeSellerMain,
    cardTick,
    ranking,
    ship,
    shop,
    trustedShop,
    quoteGuy,
    bgSeller
} from '../../assets/index'

import { BsArrowRight } from "react-icons/bs";
import { RiPlayCircleFill } from "react-icons/ri";
import { BiSolidQuoteAltLeft } from "react-icons/bi";


const BecomeASeller = () => {


    const sellBenefits = [
        { id: 1, image: shop, title: "Free Registration", description: "Account registration & listing items for sale is free" },
        { id: 2, image: ship, title: "Reliable Shipping", description: "Fast, reliable and hassle free delivery through Classicommerce logistic network" },
        { id: 3, image: cardTick, title: "Timely Payments", description: "Funds are safely deposited directly to your bank account on a weekly basis" },
        { id: 4, image: ranking, title: "Marketing Tools", description: "Find new customers & grow more with advertising and our whole range of marketing tools" },
    ]

    const accordionItems = [
        {
            id: 1,
            title: 'What Categories can I Sell?',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 2,
            title: 'What is Classicommerce Commission?',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 3,
            title: "What documents do i need to provide during sign up?",
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        },
        {
            id: 4,
            title: 'What if i want to correct the information provided?',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus harum et cum saepe iste quis impedit quod quisquam eius hic!',
        }
    ];

    const sectionPad = "px-2 md:px-16 lg:px-20 xl:px-28"

    return (
        <div>

            <div className='bg-white flex border-b border-primary'>

                <div className={`w-full lg:w-[60%] px-2 md:px-16 lg:px-24 xl:px-36 py-16 md:py-10 xl:py-16 bg-white flex flex-col items-start justify-center gap-y-6 lg:gap-y-4 xl:gap-y-7`}>

                    <div className='mx-auto text-center lg:text-left lg:mx-0 font-medium py-2 px-4 rounded-sm bg-primary text-xs lg:text-sm text-white'>Become a Seller</div>

                    <div className='mx-auto text-center lg:text-left lg:mx-0 font-bold text-2xl md:text-3xl xl:text-4xl w-fit text-black leading-normal'>
                        Become a seller on Classicommerce
                    </div>

                    <p className='mx-auto text-center lg:text-left lg:mx-0 font-medium leading-relaxed lg:leading-normal text-black text-sm xl:text-base'>Sell to crores of customers with 50% off on Selling Fee* on classicommerce</p>


                    <div className='mx-auto lg:mx-0 flex gap-x-4 items-center justify-center'>
                        <Button
                            className={'rounded-md bg-primary text-white flex justify-center items-center gap-2 xl:gap-4 py-3 px-5 xl:px-6 xl:py-4 '}
                        >
                            <p className='whitespace-nowrap text-sm lg:text-base'>Start Selling</p>
                            <BsArrowRight className='text-sm lg:text-base xl:text-xl' />
                        </Button>

                        <p className='font-medium text-black text-base'>*T&C Apply</p>

                    </div>
                </div>

                <div className='w-[40%] hidden lg:block'>
                    <img src={becomeSellerMain} className='w-full h-full' alt="about" />
                </div>
            </div>




            <section className={`${sectionPad}`}>

                <div className='my-10 text-center font-bold text-xl md:text-3xl w-full text-black leading-normal'>
                    Why Sell on Classicommerce?
                </div>

                <div className='grid w-full gap-6 grid-cols-1 lg:grid-cols-2'>
                    {sellBenefits?.map((card) => (
                        <div
                            key={card.id}
                            className='bg-white rounded flex gap-6 p-6'
                        >

                            <div>
                                <div className=' bg-[#F3F2FF] aspect-square rounded-full w-12 md:w-16 xl:w-20'>
                                    <img src={card.image} className='w-full h-full p-3 md:p-4 xl:p-6' alt="" />
                                </div>
                            </div>

                            <div className=' space-y-1 md:space-y-3'>
                                <div className='font-semibold text-base md:text-xl xl:text-2xl text-black'>
                                    {card.title}
                                </div>

                                <div className='text-sm md:text-base xl:text-lg text-[#70747D]'>
                                    {card.description}
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </section>


            {/* trusted online shop */}
            <div
                style={{
                    backgroundImage: `url("${trustedShop}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                className='relative my-10 lg:my-16'
            >

                <div className='z-[5] w-full h-full absolute bg-gradient-to-t from-customBlack from from-10% to-transparent'></div>

                <div className='w-full px-2 md:px-0 mx-auto md:w-3/5 flex flex-col items-center justify-center py-32 lg:py-40 gap-4 lg:gap-5 xl:gap-7'>
                    <div className='z-10 text-white text-center font-semibold text-2xl md:text-3xl xl:text-4xl '>Your trusted online shop</div>
                    <div className='z-10 text-white text-center text-sm xl:text-lg'>Praesent sed semper metus. Nunc aliquet dolor mauris, et fringilla elit gravida eget. Nunc consequat auctor urna a placerat.</div>

                    <Button
                        // onClick={ }
                        className={'z-10 rounded-full bg-white flex justify-center items-center gap-2 py-2 pl-2 pr-4 '}
                    >
                        <RiPlayCircleFill className='text-secondary text-3xl lg:text-4xl' />
                        <div className='font-medium text-secondary text-sm lg:text-base'>Watch the Video</div>
                    </Button>
                </div>
            </div>



            <section className={`${sectionPad}`}>
                <RewardSchemeCard />
            </section>



            {/* Quotes */}
            <div className='mt-10 lg:mt-16 overflow-hidden relative'>
                <div
                    style={{
                        backgroundImage: `url("${bgSeller}")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    className='z-0 absolute inset-0 blur'
                />

                <div className='z-[5] h-full w-full bg-gradient-to-t opacity-75 absolute from-customBlack from from-0% to-100% to-customBlack'></div>


                <div className='relative z-10 w-full flex items-center justify-center px-2 md:px-0 mx-auto gap-4'>

                    <div className='w-full py-12 md:py-20 lg:py-0 lg:w-[60%]'>

                        <div className='px-2 md:px-32 lg:py-6'>

                            <BiSolidQuoteAltLeft className='text-white text-5xl md:text-6xl' />

                            <div className='mt-6 text-white text-justify md:text-left font-light leading-normal md:text-lg xl:text-xl'>
                                Marcus has been able to expand his business to the entire local market through Classicommerce. He started selling last year and his business has grown more than 5 time , now becoming a successful seller on Classicommerce.
                            </div>

                            <div className='mt-16'>
                                <div className='text-white text-xl xl:text-2xl font-semibold'>Marcus Schleifer</div>
                                <div className='text-white text-base xl:text-lg'>Tied Ribbons (Seller)</div>
                            </div>

                        </div>

                    </div>

                    <div className='w-full h-full hidden lg:block lg:w-[40%]'>
                        <div>
                            <img src={quoteGuy} className='w-full h-full' alt="" />
                        </div>
                    </div>

                </div>

            </div>


            {/* Frequently Asked questions */}
            <section className={`${sectionPad} bg-white py-10 lg:py-16`}>

                <h3 className='my-10 text-center font-bold text-lg md:text-2xl w-full text-black leading-normal'>
                    Frequently Asked Questions
                </h3>


                <div className='w-full mx-auto lg:w-[70%] space-y-3 md:space-y-4'>
                    <Accordion
                        items={accordionItems}
                    />
                </div>

            </section>



            <div className='bg-[#4318BF] py-10 '>

                <div className={`${sectionPad} flex flex-wrap justify-around md:justify-around items-center gap-y-5`}>
                    <h3 className='xl:mr-auto font-semibold text-center text-lg md:text-2xl text-white'>
                        What are you waiting for? Start Selling today!
                    </h3>

                    <Button
                        label={"Get Started"}
                        className={'xl:ml-auto rounded-md border border-white whitespace-nowrap bg-primary text-white py-3 px-6 xl:px-8 xl:py-4 '}
                    />

                </div>

            </div>


        </div>
    )
}

export default BecomeASeller