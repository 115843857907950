import React from "react";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";

export const getStarsFromRating = (
    rating,
    hollowStar = <IoIosStarOutline className='text-[#D4CDC5]' />,
    fullStar = <IoIosStar className='text-[#F2C94C]' />
) => {
    const ratingNum = Math.floor(parseInt(rating, 10));
    return Array.from({ length: 5 }, (_, index) => {
        return index < ratingNum ? (
            React.cloneElement(fullStar, { key: index }) // Fix for the key issue
        ) : (
            React.cloneElement(hollowStar, { key: index })
        );
    });
};