import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../index'
import { Logo } from '../../assets/index'


const AuthHeader = () => {

    const navigate = useNavigate()

    return (
        <header className='bg-white w-full flex justify-center items-center p-8 gap-3'>

            <Button
                onClick={() => navigate("/")}
                className={"w-40 md:w-44"}
            >
                <img src={Logo} className='w-full h-full' alt="logo" />
            </Button>
        </header>
    )
}

export default AuthHeader