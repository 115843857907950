import React, { useState } from 'react'
import { Button } from '../index'
import { BsArrowRight } from "react-icons/bs";



const NewArrivalCard = ({
    image,
    title,
    description,
    className,
    titleCss,
    descCss
}) => {

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={`${className} relative rounded-lg overflow-hidden`}>
            <div className='z-[5] w-full h-full absolute bg-gradient-to-t from-customBlack from from-10% to-transparent'></div>

            {!imageLoaded ? (
                <>
                    <img
                        className='hidden'
                        onLoad={() => setImageLoaded(true)}
                        src={image}
                        alt='items'
                    />
                    <img className='px-2 object-contain object-center w-full h-full' src={'skeleton'} alt='loading' />
                </>
            ) : (
                <img src={image} className='w-full h-full' alt="" />
            )}

            <div className='absolute z-[7] max-w-[70%] bottom-0 left-0 translate-x-5 -translate-y-5'>
                <h2 className={`${titleCss} text-[#FAFAFB] font-semibold`}>{title}</h2>
                <div className={`${descCss} text-[#FAFAFB] mt-1 line-clamp-2`}>{description}</div>

                <Button
                    className={'mt-3 lg:mt-5 rounded-md bg-white flex justify-center items-center gap-2 py-2 px-3 lg:px-4 xl:px-6 xl:py-3 '}
                >
                    <p className='text-[#191C1F] whitespace-nowrap text-sm xl:text-base'>Shop Now</p>
                    <BsArrowRight className='text-[#191C1F] text-base xl:text-lg' />
                </Button>
            </div>
        </div>)
}

export default NewArrivalCard