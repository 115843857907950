import React, { useState } from 'react'
import CartLoggedIn from './CartLoggedIn'
import CartLoggedOut from './CartLoggedOut'

const Cart = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(true)

    return (
        <div>
            {isLoggedIn ?
                <CartLoggedIn />
                :
                <CartLoggedOut />
            }
        </div>
    )
}

export default Cart