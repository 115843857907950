import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../index'
import { facebookIcon } from '../../../../assets/index'


const AccLogInCon = () => {

    const navigate = useNavigate()

    const userInfo = { name: "John Snow", email: "example@email.com", image: facebookIcon }

    const logOptionsList = [
        { id: 1, label: "Manage My Account", navLink: "/myaccounts" },
        { id: 2, label: "My Order", navLink: "/orders" },
        { id: 3, label: "My Wishlist", navLink: "/wishlist" },
        { id: 4, label: "My reviews", navLink: "/reviews" },
        { id: 5, label: "My Returns & Cancellations", navLink: "/cancel" }
    ]

    return (
        <div className='md:gap-5 md:min-w-[360px]'>

            <div className='w-full flex justify-start items-center gap-3 p-4 md:px-6 md:py-5 border-b border-primary'>

                <div className='w-8 h-8 md:w-12 md:h-12 rounded-full overflow-hidden'>
                    {/* SKELETON IMAGE? */}
                    <img src={userInfo.image} className='w-full h-full' alt="user" />
                </div>

                <div className='text-left'>
                    <p className='font-medium text-base'>{userInfo.name}</p>
                    <p className='text-sm text-[#80838B]'>{userInfo.email}</p>
                </div>

            </div>

            <div className='w-full p-4 md:px-6 flex flex-col items-start justify-center gap-1'>
                {logOptionsList.map((items) => (

                    <Button
                        onClick={() => navigate(`${items.navLink}`)}
                        key={items.id}
                        className='rounded-lg text-left text-black text-sm whitespace-nowrap md:text-base w-full px-4 py-2 md:py-4 bg-white hover:bg-[#F5F6F6]'
                        label={items.label}
                    />
                ))}
            </div>

            <div className='w-full p-4 md:px-6 md:py-5 border-t border-primary'>
                <Button
                    className='rounded-lg text-white text-sm w-full px-5 py-2 md:py-4 bg-primary'
                    label={'Logout'}
                />
            </div>
        </div>
    )
}

export default AccLogInCon