import React from 'react'

const Timer = ({ className }) => {
    return (
        <div className='bg-primary rounded-md'>
            <div className={`${className}  text-white px-2 py-1 md:px-4 md:py-2`}>
                16d : 21h : 57m : 23s
                {/* get the logic */}
            </div>
        </div>
    )
}

export default Timer